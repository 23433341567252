import {
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    RepeatWrapping,
    Texture,
    TextureLoader,
} from "three";
import { stageSizeType } from "./BaseWebGL";
import config from "./config";
import { gui, gui_vec3 } from "./GUI";

export default class Floor {
    mesh: Mesh;
    texture: Texture;
    itemSize: any = {
        width: 100,
        height: 100,
    };
    params = {
        opacity: 1,
    };
    isReady: boolean = false;
    constructor() {}

    setup(stageSize: stageSizeType) {
        /*
            floor
        */
        return new Promise<void>((resolve) => {
            //@ts-ignore
            this.texture = config.entrance.textures.floor;
            this.texture.wrapS = RepeatWrapping;
            this.texture.wrapT = RepeatWrapping;
            this.itemSize.width = this.texture.image.width;
            this.itemSize.height = this.texture.image.height;
            let floorMaterial = new MeshBasicMaterial({
                map: this.texture,
                transparent: true,
                depthWrite: false,
                depthTest: false,
                opacity: this.params.opacity,
            });
            this.mesh = new Mesh(
                new PlaneGeometry(stageSize.width, stageSize.height, 1, 1),
                floorMaterial
            );

            this.isReady = true;
            resolve();
        });
    }

    setGui() {
        if (!this.isReady) return;
        let g = gui.addFolder("🀫floor").close();
        gui_vec3(g, this.mesh.position, {});
        g.add(this.mesh.material, "depthTest");
        g.add(this.mesh.material, "depthWrite");
        g.add(this.mesh, "renderOrder", 0, 100);

        g.add(this.params, "opacity", 0, 1, 0.1).onChange((value) => {
            (this.mesh.material as MeshBasicMaterial).opacity = value;
        });
    }

    resize(stageSize: stageSizeType) {
        if (!this.isReady) return;
        this.mesh.geometry.dispose();
        this.mesh.geometry = new PlaneGeometry(stageSize.width, stageSize.height, 1, 1);
    }

    update(textureScrollX) {
        this.texture.offset.x = textureScrollX;
        // this.mesh.material.
    }
}
