import { Color, DoubleSide, Texture, Vector2, Vector3 } from "three";

export default function () {
    return {
        uniforms: {
            uTime: { type: "f", value: 0 },
            tDiffuse: { type: "t", value: new Texture() },
            uFreq: { type: "f", value: 0 },
            uAmp: { type: "f", value: 0 },
            uScale: { type: "f", value: 1 },
            uRotation: { type: "f", value: 1 },
            uPosition: { type: "f", value: new Vector2() },
            uResolution: { type: "v2", value: new Vector2() },
            uFrameResolution: { type: "v2", value: new Vector2() },
            uOpacity: { type: "f", value: 1.0 },
        },

        vertexShader: `
            varying vec2 vUv;

            mat4 rotationMatrix(vec3 axis, float angle)
            {
                axis = normalize(axis);
                float s = sin(angle);
                float c = cos(angle);
                float oc = 1.0 - c;
                
                return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                            oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                            oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                            0.0,                                0.0,                                0.0,                                1.0);
            }
        
            void main() {
                vUv = uv;

                // mat4 rota = rotationMatrix(vec3(0.0,1.0,0.0), 2.0);
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,

        fragmentShader: `
            #define PI 3.1415926535897932384626433832795
            uniform sampler2D tDiffuse;
            uniform vec2 uResolution;
            uniform vec2 uFrameResolution;
            uniform float uTime;
            uniform float uOpacity;
            uniform float uScale;
            uniform float uFreq;
            uniform float uRotation;
            uniform float uAmp;
            
            uniform vec2 uPosition;
            varying vec2 vUv;
            varying float depth;
        
            void main() {
                /*
                    texture fit to cover
                */

                vec2 ratio = vec2(
                    min((uFrameResolution.x / uFrameResolution.y) / (uResolution.x / uResolution.y), 1.0),
                    min((uFrameResolution.y / uFrameResolution.x) / (uResolution.y / uResolution.x), 1.0));

                vec2 tUV = vec2(
                    vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
                    vUv.y * ratio.y + (1.0 - ratio.y) * 0.5);

                tUV -= 0.5;
                tUV /= uScale;
                tUV.y += (1.0 - uScale) * 0.5 * ratio.y;
                tUV.x += uPosition.x;
                tUV.y += uPosition.y;
                tUV += 0.5;


                // tUV = vUv;
                /*
                    wave
                */
                float powerY = 1.0 - clamp(0.0,1.0,1.0 - vUv.y / 0.5);

                tUV.x += sin(uRotation) * powerY + sin((uTime / PI) * uAmp + (2.0 - tUV.y)) * uAmp * powerY * powerY;
                tUV.y += cos((uTime * 0.1 / PI)) * uFreq * powerY;

                vec4 tColor = texture2D(tDiffuse,tUV);

                gl_FragColor = vec4(tColor);
                gl_FragColor.a *= uOpacity;
            }
        `,
        side: DoubleSide,
        transparent: true,
    };
}
