function getIntersectionPoints(points: Array<number> = []) {
    return new Promise((resolve) => {
        let i,
            j,
            ip,
            t,
            l = points.length;

        let intersected: Array<any> = [];
        let count = 0;
        // compare each segments
        for (i = 0; i < points.length; i += 2) {
            ((i) => {
                setTimeout(() => {
                    for (j = i + 4; j < points.length - 2; j += 2) {
                        ip = checkIntersect(
                            points[i],
                            points[i + 1],
                            points[i + 2],
                            points[i + 3],
                            points[j],
                            points[j + 1],
                            points[j + 2],
                            points[j + 3]
                        );

                        if (ip) {
                            let data = {
                                points: [ip.x, ip.y],
                                from: i,
                                to: j,
                                _from: i / 2,
                                _to: j / 2,
                                length: points.length,
                                id: count,
                                positions: [{ x: ip.x, y: ip.y }],
                            };

                            intersected.push(Object.assign(ip, data));
                            count++;
                        }
                    }
                    if (i == points.length - 2 && j == points.length + 2) {
                        resolve(intersected.reverse());
                    }
                }, i * 0.1);
            })(i);
        }
        // return intersected.reverse();
    });
}

function checkIntersect(x1, y1, x2, y2, x3, y3, x4, y4) {
    var d1x = x2 - x1,
        d1y = y2 - y1,
        d2x = x4 - x3,
        d2y = y4 - y3,
        d = d1x * d2y - d2x * d1y,
        px,
        py,
        s,
        t;

    if (d) {
        px = x1 - x3;
        py = y1 - y3;
        s = (d1x * py - d1y * px) / d;
        if (s >= 0 && s <= 1) {
            t = (d2x * py - d2y * px) / d;
            if (t > 0 && t < 1) return { x: x1 + t * d1x, y: y1 + t * d1y };
        }
    }
    return null;
}

export { getIntersectionPoints };
