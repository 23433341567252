import * as r from "three";
function c(n, t, e, i, s) {
  let a;
  if (n = n.subarray || n.slice ? n : n.buffer, e = e.subarray || e.slice ? e : e.buffer, n = t ? n.subarray ? n.subarray(t, s && t + s) : n.slice(t, s && t + s) : n, e.set)
    e.set(n, i);
  else
    for (a = 0; a < n.length; a++)
      e[a + i] = n[a];
  return e;
}
class S extends r.BufferGeometry {
  constructor() {
    super(), this.type = "MeshLine", this.isMeshLine = !0, this.positions = [], this.previous = [], this.next = [], this.side = [], this.width = [], this.indices_array = [], this.uvs = [], this.counters = [], this._points = [], this._geom = null, this.widthCallback = null, this.matrixWorld = new r.Matrix4(), Object.defineProperties(this, {
      geometry: {
        enumerable: !0,
        get() {
          return this._geom;
        },
        set(t) {
          this.setGeometry(t, this.widthCallback);
        }
      },
      points: {
        enumerable: !0,
        get() {
          return this._points;
        },
        set(t) {
          this.setPoints(t, this.widthCallback);
        }
      }
    });
  }
  setMatrixWorld(t) {
    this.matrixWorld = t;
  }
  setGeometry(t, e) {
    t instanceof r.BufferGeometry && (this._geometry = t, this.setPoints(t.getAttribute("position").array, e));
  }
  setPoints(t, e) {
    if (!(t instanceof Float32Array) && !(t instanceof Array)) {
      console.error("ERROR: The BufferArray of points is not instancied correctly.");
      return;
    }
    if (this._points = t, this.widthCallback = e, this.positions = [], this.counters = [], t.length && t[0] instanceof r.Vector3)
      for (var i = 0; i < t.length; i++) {
        const a = t[i];
        var s = i / t.length;
        this.positions.push(a.x, a.y, a.z), this.positions.push(a.x, a.y, a.z), this.counters.push(s), this.counters.push(s);
      }
    else
      for (var i = 0; i < t.length; i += 3) {
        var s = i / t.length;
        this.positions.push(t[i], t[i + 1], t[i + 2]), this.positions.push(t[i], t[i + 1], t[i + 2]), this.counters.push(s), this.counters.push(s);
      }
    this.process();
  }
  compareV3(t, e) {
    const i = t * 6, s = e * 6;
    return this.positions[i] === this.positions[s] && this.positions[i + 1] === this.positions[s + 1] && this.positions[i + 2] === this.positions[s + 2];
  }
  copyV3(t) {
    const e = t * 6;
    return [this.positions[e], this.positions[e + 1], this.positions[e + 2]];
  }
  process() {
    const t = this.positions.length / 6;
    this.previous = [], this.next = [], this.side = [], this.width = [], this.indices_array = [], this.uvs = [];
    let e, i;
    this.compareV3(0, t - 1) ? i = this.copyV3(t - 2) : i = this.copyV3(0), this.previous.push(i[0], i[1], i[2]), this.previous.push(i[0], i[1], i[2]);
    for (let s = 0; s < t; s++) {
      if (this.side.push(1), this.side.push(-1), this.widthCallback ? e = this.widthCallback(s / (t - 1)) : e = 1, this.width.push(e), this.width.push(e), this.uvs.push(s / (t - 1), 0), this.uvs.push(s / (t - 1), 1), s < t - 1) {
        i = this.copyV3(s), this.previous.push(i[0], i[1], i[2]), this.previous.push(i[0], i[1], i[2]);
        const a = s * 2;
        this.indices_array.push(a, a + 1, a + 2), this.indices_array.push(a + 2, a + 1, a + 3);
      }
      s > 0 && (i = this.copyV3(s), this.next.push(i[0], i[1], i[2]), this.next.push(i[0], i[1], i[2]));
    }
    this.compareV3(t - 1, 0) ? i = this.copyV3(1) : i = this.copyV3(t - 1), this.next.push(i[0], i[1], i[2]), this.next.push(i[0], i[1], i[2]), !this._attributes || this._attributes.position.count !== this.positions.length ? this._attributes = {
      position: new r.BufferAttribute(new Float32Array(this.positions), 3),
      previous: new r.BufferAttribute(new Float32Array(this.previous), 3),
      next: new r.BufferAttribute(new Float32Array(this.next), 3),
      side: new r.BufferAttribute(new Float32Array(this.side), 1),
      width: new r.BufferAttribute(new Float32Array(this.width), 1),
      uv: new r.BufferAttribute(new Float32Array(this.uvs), 2),
      index: new r.BufferAttribute(new Uint16Array(this.indices_array), 1),
      counters: new r.BufferAttribute(new Float32Array(this.counters), 1)
    } : (this._attributes.position.copyArray(new Float32Array(this.positions)), this._attributes.position.needsUpdate = !0, this._attributes.previous.copyArray(new Float32Array(this.previous)), this._attributes.previous.needsUpdate = !0, this._attributes.next.copyArray(new Float32Array(this.next)), this._attributes.next.needsUpdate = !0, this._attributes.side.copyArray(new Float32Array(this.side)), this._attributes.side.needsUpdate = !0, this._attributes.width.copyArray(new Float32Array(this.width)), this._attributes.width.needsUpdate = !0, this._attributes.uv.copyArray(new Float32Array(this.uvs)), this._attributes.uv.needsUpdate = !0, this._attributes.index.copyArray(new Uint16Array(this.indices_array)), this._attributes.index.needsUpdate = !0), this.setAttribute("position", this._attributes.position), this.setAttribute("previous", this._attributes.previous), this.setAttribute("next", this._attributes.next), this.setAttribute("side", this._attributes.side), this.setAttribute("width", this._attributes.width), this.setAttribute("uv", this._attributes.uv), this.setAttribute("counters", this._attributes.counters), this.setIndex(this._attributes.index), this.computeBoundingSphere(), this.computeBoundingBox();
  }
  advance({ x: t, y: e, z: i }) {
    const s = this._attributes.position.array, a = this._attributes.previous.array, u = this._attributes.next.array, o = s.length;
    c(s, 0, a, 0, o), c(s, 6, s, 0, o - 6), s[o - 6] = t, s[o - 5] = e, s[o - 4] = i, s[o - 3] = t, s[o - 2] = e, s[o - 1] = i, c(s, 6, u, 0, o - 6), u[o - 6] = t, u[o - 5] = e, u[o - 4] = i, u[o - 3] = t, u[o - 2] = e, u[o - 1] = i, this._attributes.position.needsUpdate = !0, this._attributes.previous.needsUpdate = !0, this._attributes.next.needsUpdate = !0;
  }
}
r.ShaderChunk.meshline_vert = `
  #include <common>
  ${r.ShaderChunk.logdepthbuf_pars_vertex}
  ${r.ShaderChunk.fog_pars_vertex}

  attribute vec3 previous;
  attribute vec3 next;
  attribute float side;
  attribute float width;
  attribute float counters;
  
  uniform vec2 resolution;
  uniform float lineWidth;
  uniform vec3 color;
  uniform float opacity;
  uniform float sizeAttenuation;
  
  varying vec2 vUV;
  varying vec4 vColor;
  varying float vCounters;
  
  vec2 fix(vec4 i, float aspect) {
    vec2 res = i.xy / i.w;
    res.x *= aspect;
  	vCounters = counters;
    return res;
  }
  
  void main() {
    float aspect = resolution.x / resolution.y;
    vColor = vec4(color, opacity);
    vUV = uv;
  
    mat4 m = projectionMatrix * modelViewMatrix;
    vec4 finalPosition = m * vec4(position, 1.0);
    vec4 prevPos = m * vec4(previous, 1.0);
    vec4 nextPos = m * vec4(next, 1.0);
  
    vec2 currentP = fix(finalPosition, aspect);
    vec2 prevP = fix(prevPos, aspect);
    vec2 nextP = fix(nextPos, aspect);
  
    float w = lineWidth * width;
  
    vec2 dir;
    if (nextP == currentP) dir = normalize(currentP - prevP);
    else if (prevP == currentP) dir = normalize(nextP - currentP);
    else {
      vec2 dir1 = normalize(currentP - prevP);
      vec2 dir2 = normalize(nextP - currentP);
      dir = normalize(dir1 + dir2);
  
      vec2 perp = vec2(-dir1.y, dir1.x);
      vec2 miter = vec2(-dir.y, dir.x);
      //w = clamp(w / dot(miter, perp), 0., 4. * lineWidth * width);
    }
  
    //vec2 normal = (cross(vec3(dir, 0.), vec3(0., 0., 1.))).xy;
    vec4 normal = vec4(-dir.y, dir.x, 0., 1.);
    normal.xy *= .5 * w;
    normal *= projectionMatrix;
    if (sizeAttenuation == 0.) {
      normal.xy *= finalPosition.w;
      normal.xy /= (vec4(resolution, 0., 1.) * projectionMatrix).xy;
    }
  
    finalPosition.xy += normal.xy * side;
    gl_Position = finalPosition;
    ${r.ShaderChunk.logdepthbuf_vertex}
    ${r.ShaderChunk.fog_vertex}
    vec4 mvPosition = modelViewMatrix * vec4(position, 1.0);
    ${r.ShaderChunk.fog_vertex}
  }`;
r.ShaderChunk.meshline_frag = `
  
  ${r.ShaderChunk.fog_pars_fragment}
  ${r.ShaderChunk.logdepthbuf_pars_fragment}
  
  uniform sampler2D map;
  uniform sampler2D alphaMap;
  uniform float useMap;
  uniform float useAlphaMap;
  uniform float useDash;
  uniform float dashArray;
  uniform float dashOffset;
  uniform float dashRatio;
  uniform float visibility;
  uniform float alphaTest;
  uniform vec2 repeat;
  
  varying vec2 vUV;
  varying vec4 vColor;
  varying float vCounters;
  
  void main() {
    ${r.ShaderChunk.logdepthbuf_fragment}
    vec4 c = vColor;
    if (useMap == 1.) c *= texture2D(map, vUV * repeat);
    if (useAlphaMap == 1.) c.a *= texture2D(alphaMap, vUV * repeat).a;
    if (c.a < alphaTest) discard;
    if (useDash == 1.) {
      c.a *= ceil(mod(vCounters + dashOffset, dashArray) - (dashArray * dashRatio));
    }
    gl_FragColor = c;
    gl_FragColor.a *= step(vCounters, visibility);
    ${r.ShaderChunk.fog_fragment}
    #include <tonemapping_fragment>
    #include <encodings_fragment>
  }`;
class V extends r.ShaderMaterial {
  constructor(t) {
    super({
      uniforms: Object.assign({}, r.UniformsLib.fog, {
        lineWidth: { value: 1 },
        map: { value: null },
        useMap: { value: 0 },
        alphaMap: { value: null },
        useAlphaMap: { value: 0 },
        color: { value: new r.Color(16777215) },
        opacity: { value: 1 },
        resolution: { value: new r.Vector2(1, 1) },
        sizeAttenuation: { value: 1 },
        dashArray: { value: 0 },
        dashOffset: { value: 0 },
        dashRatio: { value: 0.5 },
        useDash: { value: 0 },
        visibility: { value: 1 },
        alphaTest: { value: 0 },
        repeat: { value: new r.Vector2(1, 1) }
      }),
      vertexShader: r.ShaderChunk.meshline_vert,
      fragmentShader: r.ShaderChunk.meshline_frag
    }), this.type = "MeshLineMaterial", Object.defineProperties(this, {
      lineWidth: {
        enumerable: !0,
        get() {
          return this.uniforms.lineWidth.value;
        },
        set(e) {
          this.uniforms.lineWidth.value = e;
        }
      },
      map: {
        enumerable: !0,
        get() {
          return this.uniforms.map.value;
        },
        set(e) {
          this.uniforms.map.value = e;
        }
      },
      useMap: {
        enumerable: !0,
        get() {
          return this.uniforms.useMap.value;
        },
        set(e) {
          this.uniforms.useMap.value = e;
        }
      },
      alphaMap: {
        enumerable: !0,
        get() {
          return this.uniforms.alphaMap.value;
        },
        set(e) {
          this.uniforms.alphaMap.value = e;
        }
      },
      useAlphaMap: {
        enumerable: !0,
        get() {
          return this.uniforms.useAlphaMap.value;
        },
        set(e) {
          this.uniforms.useAlphaMap.value = e;
        }
      },
      color: {
        enumerable: !0,
        get() {
          return this.uniforms.color.value;
        },
        set(e) {
          this.uniforms.color.value = e;
        }
      },
      opacity: {
        enumerable: !0,
        get() {
          return this.uniforms.opacity.value;
        },
        set(e) {
          this.uniforms.opacity.value = e;
        }
      },
      resolution: {
        enumerable: !0,
        get() {
          return this.uniforms.resolution.value;
        },
        set(e) {
          this.uniforms.resolution.value.copy(e);
        }
      },
      sizeAttenuation: {
        enumerable: !0,
        get() {
          return this.uniforms.sizeAttenuation.value;
        },
        set(e) {
          this.uniforms.sizeAttenuation.value = e;
        }
      },
      dashArray: {
        enumerable: !0,
        get() {
          return this.uniforms.dashArray.value;
        },
        set(e) {
          this.uniforms.dashArray.value = e, this.useDash = e !== 0 ? 1 : 0;
        }
      },
      dashOffset: {
        enumerable: !0,
        get() {
          return this.uniforms.dashOffset.value;
        },
        set(e) {
          this.uniforms.dashOffset.value = e;
        }
      },
      dashRatio: {
        enumerable: !0,
        get() {
          return this.uniforms.dashRatio.value;
        },
        set(e) {
          this.uniforms.dashRatio.value = e;
        }
      },
      useDash: {
        enumerable: !0,
        get() {
          return this.uniforms.useDash.value;
        },
        set(e) {
          this.uniforms.useDash.value = e;
        }
      },
      visibility: {
        enumerable: !0,
        get() {
          return this.uniforms.visibility.value;
        },
        set(e) {
          this.uniforms.visibility.value = e;
        }
      },
      alphaTest: {
        enumerable: !0,
        get() {
          return this.uniforms.alphaTest.value;
        },
        set(e) {
          this.uniforms.alphaTest.value = e;
        }
      },
      repeat: {
        enumerable: !0,
        get() {
          return this.uniforms.repeat.value;
        },
        set(e) {
          this.uniforms.repeat.value.copy(e);
        }
      }
    }), this.setValues(t);
  }
  copy(t) {
    return super.copy(t), this.lineWidth = t.lineWidth, this.map = t.map, this.useMap = t.useMap, this.alphaMap = t.alphaMap, this.useAlphaMap = t.useAlphaMap, this.color.copy(t.color), this.opacity = t.opacity, this.resolution.copy(t.resolution), this.sizeAttenuation = t.sizeAttenuation, this.dashArray.copy(t.dashArray), this.dashOffset.copy(t.dashOffset), this.dashRatio.copy(t.dashRatio), this.useDash = t.useDash, this.visibility = t.visibility, this.alphaTest = t.alphaTest, this.repeat.copy(t.repeat), this;
  }
}
function k(n, t) {
  const e = new r.Matrix4(), i = new r.Ray(), s = new r.Sphere(), a = new r.Vector3(), u = this.geometry;
  if (s.copy(u.boundingSphere), s.applyMatrix4(this.matrixWorld), n.ray.intersectSphere(s, a) === !1)
    return;
  e.copy(this.matrixWorld).invert(), i.copy(n.ray).applyMatrix4(e);
  const o = new r.Vector3(), f = new r.Vector3(), d = new r.Vector3(), A = this instanceof r.LineSegments ? 2 : 1, v = u.index, m = u.attributes;
  if (v !== null) {
    const l = v.array, y = m.position.array, b = m.width.array;
    for (let h = 0, x = l.length - 1; h < x; h += A) {
      const w = l[h], _ = l[h + 1];
      o.fromArray(y, w * 3), f.fromArray(y, _ * 3);
      const M = b[Math.floor(h / 3)] != null ? b[Math.floor(h / 3)] : 1, g = n.params.Line.threshold + this.material.lineWidth * M / 2, C = g * g;
      if (i.distanceSqToSegment(o, f, a, d) > C)
        continue;
      a.applyMatrix4(this.matrixWorld);
      const p = n.ray.origin.distanceTo(a);
      p < n.near || p > n.far || (t.push({
        distance: p,
        point: d.clone().applyMatrix4(this.matrixWorld),
        index: h,
        face: null,
        faceIndex: null,
        object: this
      }), h = x);
    }
  }
}
export {
  S as MeshLineGeometry,
  V as MeshLineMaterial,
  k as raycast
};
