import { Color, Fog, PerspectiveCamera, Scene, Vector2, WebGLRenderer } from "three";
import UA from "./lib/ua";

import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass";
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer";
import { ShaderPass } from "three/examples/jsm/postprocessing/ShaderPass";
import { FXAAShader } from "three/examples/jsm/shaders/FXAAShader";
import { LastScreenShader } from "./shaders/lastScreenShader";
import { gui } from "./GUI";
import statsMin from "stats-js";
import Flower from "./Flower";
import config from "./config";
import FlowerCard from "./FlowerCard";

interface stageSizeType {
    width: number;
    height: number;
    aspect: number;
    marginHeight: number;
}

export { stageSizeType };

export default class BaseWebGL {
    renderList: any;
    resizeList: any;
    containerEl: HTMLElement;
    config: any;
    mouse: any;
    isRender: boolean = false;
    renderId: number;

    subScene: Scene = new Scene();
    scene: Scene;
    stage: stageSizeType;

    flowers: Array<Flower> = [];
    flowerCards: Array<Flower> = [];
    isRenderSceneMain: boolean = true;

    currentDrawScene: string = "main";

    camera: PerspectiveCamera;
    renderer: WebGLRenderer;

    screenZ: number;
    cardFitZ: number;

    isEditMode: boolean = false;

    dummyEl: HTMLElement;

    composer: EffectComposer;
    lastScreenShaderPass: ShaderPass;
    fxaaPass: ShaderPass;

    stats: any;

    lastDrawSceneId: number = 0;

    effectParams = {
        timeSpeed: 0.01,
        uTransition: 0,
        uAmp: 0,
        uFreq: 0,
        uOpacity: 1.0,
        noiseBrightness: 1,
        noiseIntensity: 0.1,
    };

    constructor(containerEl, option = {}) {
        this.renderList = {};
        this.resizeList = {};

        this.containerEl = containerEl;

        this.stage = {
            width: 1920,
            height: 1080,
            aspect: 4 / 3,
            marginHeight: 1080,
        };

        this.mouse = { x: 0, y: 0, cx: 0, cy: 0, _cx: 0, _cy: 0, rx: 0, ry: 0, _rx: 0, _ry: 0 };

        this.defineSize();

        this.config = {
            camera_fov: 45,
            camera_near: 1,
            camera_far: 10000,

            render_antialias: true,
            render_alpha: false,
            render_pixelRatio: 2, //window.devicePixelRatio,

            render_clearColor: 0x000000,
            render_clearColorAlpha: 1,
            render_autoClearColor: true,
        };

        this.config = Object.assign(this.config, option);

        this.isRender = false;

        /*
			Scene, Camera, Renderer
		*/
        this.scene = new Scene();
        this.scene.fog = new Fog(new Color(config.colors.gray), 1, 3500);

        this.camera = new PerspectiveCamera(
            this.config.camera_fov,
            this.stage.aspect,
            this.config.camera_near,
            this.config.camera_far
        );

        this.renderer = new WebGLRenderer({
            antialias: this.config.render_antialias,
            alpha: this.config.render_alpha,
            // autoClearColor: this.config.render_autoClearColor,
            // stencil: false,
            // depth: false,
        });

        this.screenZ = this.computeZ(0);
        this.cardFitZ = this.computeZ(0, (this.stage.width - config.cardOption.size.width) * 0.5);

        this.renderer.setClearColor(this.config.render_clearColor);
        this.renderer.setPixelRatio(this.config.render_pixelRatio);
        this.renderer.setSize(this.stage.width, this.stage.height);
        this.render = this.render.bind(this);

        if (this.containerEl) this.containerEl.prepend(this.renderer.domElement);

        this.setPostProcessing();

        /*
			Event , Stats, Helpers
		*/
        this.resize = this.resize.bind(this);
        window.addEventListener("resize", () => {
            this.resize();
        });

        this.stats = statsMin();
        document.body.append(this.stats.domElement);

        if (UA.isPC) {
            window.addEventListener("mousemove", (e) => {
                let _x = this.mouse.x;
                let _y = this.mouse.y;

                this.mouse.x = e.clientX;
                this.mouse.y = e.clientY;
                let widthHalf = window.innerWidth * 0.5;
                let heightHalf = window.innerHeight * 0.5;
                this.mouse.cx = this.mouse.x - widthHalf;
                this.mouse.cy = -(this.mouse.y - heightHalf);
                this.mouse.rx = (this.mouse.x - widthHalf) / widthHalf;
                this.mouse.ry = (this.mouse.y - heightHalf) / heightHalf;

                let dx = _x - this.mouse.x;
                let dy = _y - this.mouse.y;
                this.mouse.acc = Math.min(100, Math.sqrt(dx * dx + dy * dy)) / 100;
            });
        }
    }

    setPostProcessing() {
        /*
            postprocessing
        */

        this.lastScreenShaderPass = new ShaderPass(LastScreenShader);
        this.lastScreenShaderPass.uniforms.noiseBrightness.value =
            this.effectParams.noiseBrightness;
        this.lastScreenShaderPass.uniforms.uResolution.value = new Vector2(
            this.stage.width,
            this.stage.height
        );

        this.fxaaPass = new ShaderPass(FXAAShader);

        this.fxaaPass.material.uniforms["resolution"].value.x =
            1 / (this.renderer.domElement.offsetWidth * window.devicePixelRatio);
        this.fxaaPass.material.uniforms["resolution"].value.y =
            1 / (this.renderer.domElement.offsetHeight * window.devicePixelRatio);

        const renderScene = new RenderPass(this.scene, this.camera);

        this.composer = new EffectComposer(this.renderer);
        this.composer.addPass(renderScene);
        this.composer.addPass(this.lastScreenShaderPass);
        this.composer.addPass(this.fxaaPass);
    }

    setEffGui() {
        this.lastScreenShaderPass.uniforms.uOpacity.value = this.effectParams.uOpacity;
        this.lastScreenShaderPass.uniforms.uAmp.value = this.effectParams.uAmp;
        this.lastScreenShaderPass.uniforms.uFreq.value = this.effectParams.uFreq;

        let g = gui.addFolder("effect").close();
        g.add(this.effectParams, "uOpacity", 0, 1, 0.01).onChange((value) => {
            this.lastScreenShaderPass.uniforms.uOpacity.value = value;
        });

        g.add(this.effectParams, "uTransition", 0, 1, 0.01).onChange((value) => {
            this.lastScreenShaderPass.uniforms.uTransition.value = value;
        });
        g.add(this.effectParams, "uAmp", -100, 100, 0.1).onChange((value) => {
            this.lastScreenShaderPass.uniforms.uAmp.value = value;
        });
        g.add(this.effectParams, "uFreq", -100, 100, 0.01).onChange((value) => {
            this.lastScreenShaderPass.uniforms.uFreq.value = value;
        });

        g.add(this.effectParams, "noiseBrightness", 0, 1, 0.01).onChange((value) => {
            this.lastScreenShaderPass.uniforms.noiseBrightness.value = value;
        });

        g.add(this.effectParams, "noiseIntensity", 0, 1, 0.01).onChange((value) => {
            this.lastScreenShaderPass.uniforms.noiseIntensity.value = value;
        });
    }

    defineSize() {
        this.stage.width = this.containerEl.clientWidth;
        this.stage.height = this.containerEl.clientHeight;
        this.stage.aspect = this.stage.width / this.stage.height;
        this.stage.marginHeight =
            this.containerEl.clientHeight - document.documentElement.clientHeight;
    }

    resize(force: boolean = false) {
        if (!force) {
            if (UA.isIOS && this.containerEl.clientWidth == this.stage.width) return;
        }

        this.defineSize();
        this.screenZ = this.computeZ(0);
        let targetH = this.stage.height - config.cardOption.size.height;
        if (this.stage.width > this.stage.height) {
            targetH *= config.cardOption.size.aspect;
        }
        this.cardFitZ = this.computeZ(0, targetH);

        this.camera.position.z = this.screenZ;

        this.camera.aspect = this.stage.aspect;
        this.camera.updateProjectionMatrix();

        this.fxaaPass.material.uniforms["resolution"].value.x =
            1 / (this.renderer.domElement.offsetWidth * window.devicePixelRatio);
        this.fxaaPass.material.uniforms["resolution"].value.y =
            1 / (this.renderer.domElement.offsetHeight * window.devicePixelRatio);

        this.renderer.setSize(this.stage.width, this.stage.height);
        this.composer.setSize(this.stage.width, this.stage.height);

        if (this.lastScreenShaderPass)
            this.lastScreenShaderPass.uniforms.uResolution.value = new Vector2(
                this.stage.width,
                this.stage.height
            );

        this.updateResizeList(this.stage);
    }

    computeZ(targetZ, targetHeight1 = this.stage.height, targetHeight2 = this.stage.height) {
        let vFOV = (this.camera.fov * Math.PI) / 180;
        let vHeightPartial = 2 * Math.tan(vFOV / 2);
        var p1 = targetHeight1 * this.stage.height;
        var p2 = targetZ * vHeightPartial;
        var p3 = targetHeight2 * vHeightPartial;
        var p4 = targetHeight2 * p2;
        var p5 = p1 + p4;
        var z = p5 / p3;
        return z;
    }

    /*
        render list, resize list
    */

    renderStart() {
        if (!this.isRender) {
            requestAnimationFrame(this.render);
        }
        this.isRender = true;
    }

    renderStop() {
        this.isRender = false;
        cancelAnimationFrame(this.renderId);
    }

    addResizeList(key, target) {
        this.resizeList[key] = target;
    }

    removeRenderList(key) {
        delete this.renderList[key];
    }

    removeResizeList(key) {
        delete this.resizeList[key];
    }

    updateResizeList(...arg: any) {
        for (let key in this.resizeList) {
            this.resizeList[key](...arg);
        }
    }

    updateDrawTexture(texture, id) {
        this.lastScreenShaderPass.uniforms.tDiffuse2.value = texture;
        this.lastDrawSceneId = id;
    }

    render() {
        this.renderId = requestAnimationFrame(this.render);
        this.stats.begin();
        const pixelRatio = this.renderer.getPixelRatio();

        this.update();

        if (this.flowers) {
            this.flowers.forEach((flower: Flower, i) => {
                let flowerCard = this.flowerCards[i];

                if (this.effectParams.uTransition < 1) {
                    if (flower.isOnScreen && this.effectParams.uTransition < 1) {
                        if (0) {
                            flower.rtt.scissorTest = false;
                            this.renderer.setClearColor(config.colors.gray);
                            this.renderer.setRenderTarget(flower.rtt);
                            this.renderer.clear();

                            flower.rtt.scissor.x =
                                this.stage.width * 0.5 +
                                flower.viewPort.x -
                                flower.viewPort.width * 0.5;
                            flower.rtt.scissor.y =
                                this.stage.height * 0.5 +
                                flower.viewPort.y -
                                flower.viewPort.height * 0.5;
                            flower.rtt.scissor.width = flower.viewPort.width;
                            flower.rtt.scissor.height = flower.viewPort.height;
                            flower.rtt.scissorTest = true;
                        }

                        this.renderer.setRenderTarget(flower.rtt);
                        this.renderer.clear();

                        this.renderer.render(flower.scene, flower.camera);

                        this.renderer.setRenderTarget(flowerCard.rtt);
                        this.renderer.clear();
                        this.renderer.render(flowerCard.scene, flowerCard.camera);

                        this.renderer.setRenderTarget(null);
                    }
                } else {
                    if (this.lastDrawSceneId == i && this.effectParams.uTransition > 0) {
                        this.renderer.setRenderTarget(flower.rtt);
                        this.renderer.clear();
                        this.renderer.render(flower.scene, flower.camera);
                        this.renderer.setRenderTarget(null);
                    }
                }
            });

            // this.renderer.setViewport(0, 0, this.stage.width, this.stage.height);
            // this.renderer.setScissorTest(false);
            // this.renderer.setRenderTarget(null);

            this.lastScreenShaderPass.uniforms.uTransition.value = this.effectParams.uTransition;
            this.renderer.render(this.scene, this.camera);
            this.renderer.clear();

            if (this.isRenderSceneMain) {
                // this.renderer.render(this.scene, this.camera);
            } else {
                // this.renderer.render(flower.scene, flower.camera);
            }

            // this.camera.lookAt(new Vector3(0, 0, this.screenZ - 500));
            // this.camera.rotation.x += this.mouse._ry * 0.21;
            // this.camera.rotation.y += this.mouse._rx * 0.21;
            // this.camera.rotation.x *= 0.8;
            // this.camera.rotation.y *= 0.8;
        }

        this.composer.render();
        this.stats.end();

        if (this.lastScreenShaderPass)
            this.lastScreenShaderPass.uniforms.uTime.value += this.effectParams.timeSpeed;

        this.mouse._rx += (this.mouse.rx - this.mouse._rx) * 0.1;
        this.mouse._ry += (this.mouse.ry - this.mouse._ry) * 0.1;
        // this.camera.position.x = this.mouse.rx;
        // this.camera.position.y = this.mouse.ry;
    }

    update() {}
}
