import {
    Mesh,
    MeshBasicMaterial,
    PlaneGeometry,
    RepeatWrapping,
    Texture,
    TextureLoader,
} from "three";
import { stageSizeType } from "./BaseWebGL";
import config from "./config";
import { gui, gui_vec3 } from "./GUI";

export default class FloorShadow {
    mesh: Mesh;
    material: MeshBasicMaterial;
    stageSize: stageSizeType;
    itemSize: any = {
        width: 100,
        height: 100,
    };
    params = {
        opacity: 1,
    };
    isReady: boolean = false;
    constructor() {}

    setup(stageSize: stageSizeType) {
        this.stageSize = Object.assign(stageSize, stageSize);
        return new Promise<void>((resolve) => {
            //@ts-ignore
            let texture: Texture = config.entrance.textures.shadow;
            texture.wrapS = RepeatWrapping;
            texture.wrapT = RepeatWrapping;

            this.itemSize.width = texture.image.width;
            this.itemSize.height = texture.image.height;

            texture.repeat.x = (this.itemSize.height / this.itemSize.width) * 2;

            this.material = new MeshBasicMaterial({
                map: texture,
                transparent: true,
                depthTest: false,
                depthWrite: false,
                opacity: this.params.opacity,
            });
            this.mesh = new Mesh(
                new PlaneGeometry(stageSize.width, stageSize.height, 1, 1),
                this.material
            );
            this.isReady = true;
            resolve();
        });
    }

    update(scrollX, scrollProgress) {
        if (!this.isReady) return;
        // let baseX =
        //     (this.itemSize.width * (1 + (this.mesh.scale.x - 1) * 0.5) - this.stageSize.width) *
        //     0.5;
        // let tgX = baseX * 2 * scrollProgress;
        // this.mesh.position.x = baseX - tgX;

        let shadowTexture = this.material.map as Texture;
        shadowTexture.offset.x = (scrollX / this.itemSize.width / this.mesh.scale.x) * 2;
    }

    setGui() {
        let g = gui.addFolder("floorShadow").close();
        g.add(this.params, "opacity", 0, 1, 0.1).onChange((value) => {
            (this.mesh.material as MeshBasicMaterial).opacity = value;
        });

        g.add(this.material, "depthTest");
        g.add(this.material, "depthWrite");
        g.add(this.mesh, "renderOrder", 0, 100);
        gui_vec3(g, this.mesh.position, {});
    }

    resize(stageSize: stageSizeType) {
        if (!this.isReady) return;
        this.stageSize = Object.assign(stageSize, stageSize);
        this.mesh.geometry.dispose();
        this.mesh.geometry = new PlaneGeometry(stageSize.width, stageSize.height, 1, 1);
    }
}
