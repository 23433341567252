import { Color, DoubleSide, FrontSide, Vector2, Vector3 } from "three";

export default function () {
    return {
        uniforms: {
            uTransform: { type: "f", value: 0 },
            uTime: { type: "f", value: 0 },
            uFreq: { type: "f", value: 0 },
            uStrength: { type: "f", value: 0 },
            uViewportSize: { type: "v2", value: new Vector2(0, 0) },
            uColor: { type: "v3", value: new Color(0xffffff) },
            tDiffuse: { type: "t", value: null },
            opacity: { type: "f", value: 1.0 },
        },

        side: FrontSide,

        vertexShader: `
            #define PI 3.1415926535897932384626433832795

            varying vec2 vUv;
            varying float depth;

            uniform vec2 uViewportSize;
            uniform float uStrength;
            uniform float uFreq;
            uniform float uTime;
            uniform float uTransform;

        
            void main() {
                vUv = uv;

                vec4 newPosition = modelViewMatrix * vec4(position, 1.0);

                // newPosition.z += sin(newPosition.y / uViewportSize.y * PI + PI / 2.0) * -uStrength;
                float z = sin(position.y / uViewportSize.y * PI * 2.0 * uFreq + uTime) * uStrength;
                float x = cos(position.x / uViewportSize.x * PI * uFreq + uTime) * uStrength;
                float y = sin(position.y / uViewportSize.y * PI * uFreq + uTime) * uStrength;
                
                float ratioY = 1.0;//1.0 - position.y / (uViewportSize.y - 1000.);
                if(position.y < uViewportSize.y * 0.000000001) {
                    // ratioY = 0.0;
                }

                if(uTransform == 1.0) {
                    newPosition.x += x * ratioY;
                    newPosition.y += y * ratioY;
                    newPosition.z += z * ratioY;
                }

                depth = z + x + y;
                // depth = sin(newPosition.y * PI * uFreq + uTime) * uStrength;

                gl_Position = projectionMatrix * newPosition;
                //gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,

        fragmentShader: `
    
        uniform sampler2D tDiffuse;
        uniform float opacity;
        uniform vec3 uColor;
    
        varying vec2 vUv;
        varying float depth;
    
        void main() {
            gl_FragColor = vec4(uColor + clamp((depth * 0.01),-0.1,0.1),opacity);
            // gl_FregColor.rgb += depth * 0.01;
          }
        `,
    };
}
