import gsap from "gsap";
import { Group, Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from "three";

export default class FlowerCardTitle {
    mesh: Mesh;
    texture: Texture;
    material: MeshBasicMaterial;
    group: Group = new Group();
    size: any = {
        width: 159,
        height: 78,
        scale: 0.25,
    };
    svgPath: string = "/assets/title1.svg";
    isReady: boolean = false;
    gsap: GSAPAnimation;
    constructor() {}
    async setup(texture) {
        return new Promise<this>((resolve) => {
            //@ts-ignore
            let t: Texture = texture;
            this.size.width = t.image.width;
            this.size.height = t.image.height;
            this.texture = t;
            let geometry = new PlaneGeometry(t.image.width, t.image.height, 1);
            this.material = new MeshBasicMaterial({
                map: t,
                transparent: true,
                depthTest: true,
                depthWrite: false,
            });

            this.mesh = new Mesh(geometry, this.material);
            let scale = this.size.scale;
            this.mesh.scale.set(scale, scale, scale);

            this.isReady = true;
            resolve(this);
        });
    }

    fadeIn(duration = 1, delay = 0) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this.mesh.material, {
            duration: duration,
            delay: delay,
            opacity: 1,
            ease: "Power2.easeInOut",
        });
    }

    fadeOut(duration = 1, delay = 0) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this.mesh.material, {
            duration: duration,
            delay: delay,
            opacity: 0,
            ease: "Power2.easeInOut",
        });
    }
    resize(cardSize, borderBottom) {
        let y = -(cardSize.height * 0.5) + borderBottom * 0.5;
        let x = -(cardSize.width - this.size.width) * 0.5;
        if (this.mesh) {
            this.mesh.position.y = y;
            if (this.size.height * this.size.scale > borderBottom) {
                let scale = (borderBottom / this.size.height) * this.size.scale;
                this.mesh.scale.set(scale, scale, scale);
            }
        }

        this.group.position.x = -this.size.width * 0.5;
        this.group.position.y = y + this.size.height * 0.5;
        // this.group.position.z = 100;
    }
}
