import { BoxGeometry, DoubleSide, Mesh, ShaderMaterial, TextureLoader } from "three";
import config from "./scripts/flowerWorld/config";
import FlowerWorld from "./scripts/flowerWorld/FlowerWorld";

let flowerWorld;
async function setup() {
    let FlowerType = document.body.getAttribute("data-type");
    if (FlowerType == "flower2") {
        config.flowers.pop();
    } else if (FlowerType == "flower3") {
        // config.flowers.pop();
        // config.flowers = config.flowers.concat(config.flowers);
    }
    let webgGlContainer = document.querySelector("#webglContainer");
    if (webgGlContainer) {
        flowerWorld = new FlowerWorld(webgGlContainer);
        flowerWorld.renderStart();

        await flowerWorld.loadForEntrance((progress) => {
            console.log(`loadForEntrance load = ${progress}`);
        });

        await flowerWorld.setupEntrance();
        await flowerWorld.load((progress) => {
            // console.log(`load = ${progress}`);
        });
        await flowerWorld.lazyLoading();
        await flowerWorld.setup();
        await flowerWorld.showIntro();
        flowerWorld.setGui();
    }
}

window.addEventListener("load", () => {
    setup();
});
