export default {
    points: [
        46.19999694824219, -233.89999389648438, 0, 44.19999694824219, -233.89999389648438, 0,
        42.19999694824219, -233.89999389648438, 0, 40.19999694824219, -233.89999389648438, 0,
        38.19999694824219, -233.89999389648438, 0, 36.19999694824219, -233.89999389648438, 0,
        34.19999694824219, -233.89999389648438, 0, 32.19999694824219, -233.89999389648438, 0,
        30.199996948242188, -233.89999389648438, 0, 28.199996948242188, -233.89999389648438, 0,
        26.199996948242188, -233.89999389648438, 0, 24.199996948242188, -233.89999389648438, 0,
        22.80279541015625, -233.29998779296875, 0, 22.812118530273438, -231.30001831054688, 0,
        22.821441650390625, -229.300048828125, 0, 22.830764770507812, -227.300048828125, 0,
        22.840087890625, -225.30007934570312, 0, 22.849411010742188, -223.30010986328125, 0,
        22.858734130859375, -221.30014038085938, 0, 22.868057250976562, -219.30014038085938, 0,
        22.87738037109375, -217.3001708984375, 0, 22.886703491210938, -215.30020141601562, 0,
        22.896026611328125, -213.30020141601562, 0, 22.905349731445312, -211.30023193359375, 0,
        22.914688110351562, -209.30026245117188, 0, 22.92401123046875, -207.30029296875, 0,
        22.9327392578125, -205.30029296875, 0, 22.941390991210938, -203.30032348632812, 0,
        22.950057983398438, -201.30032348632812, 0, 22.958724975585938, -199.30035400390625, 0,
        22.967376708984375, -197.30038452148438, 0, 22.976043701171875, -195.30038452148438, 0,
        22.984710693359375, -193.3004150390625, 0, 22.993362426757812, -191.3004150390625, 0,
        23.002029418945312, -189.30044555664062, 0, 23.01068115234375, -187.30047607421875, 0,
        23.01934814453125, -185.30047607421875, 0, 23.02801513671875, -183.30050659179688, 0,
        23.036666870117188, -181.30050659179688, 0, 23.045333862304688, -179.300537109375, 0,
        23.054000854492188, -177.30056762695312, 0, 23.062652587890625, -175.30056762695312, 0,
        23.071319580078125, -173.30059814453125, 0, 23.079971313476562, -171.30062866210938, 0,
        23.088638305664062, -169.30062866210938, 0, 23.097305297851562, -167.3006591796875, 0,
        23.10595703125, -165.3006591796875, 0, 23.1146240234375, -163.30068969726562, 0,
        23.123291015625, -161.30072021484375, 0, 23.131942749023438, -159.30072021484375, 0,
        23.140609741210938, -157.30075073242188, 0, 23.149276733398438, -155.30075073242188, 0,
        23.157928466796875, -153.30078125, 0, 23.166595458984375, -151.30081176757812, 0,
        23.175247192382812, -149.30081176757812, 0, 23.183914184570312, -147.30084228515625, 0,
        23.192581176757812, -145.30084228515625, 0, 23.201126098632812, -143.30087280273438, 0,
        23.208938598632812, -141.3009033203125, 0, 23.216766357421875, -139.3009033203125, 0,
        23.224578857421875, -137.30093383789062, 0, 23.232406616210938, -135.30093383789062, 0,
        23.240219116210938, -133.30096435546875, 0, 23.248046875, -131.30096435546875, 0,
        23.255859375, -129.30099487304688, 0, 23.263687133789062, -127.30099487304688, 0,
        23.271499633789062, -125.301025390625, 0, 23.279327392578125, -123.301025390625, 0,
        23.287139892578125, -121.30105590820312, 0, 23.294967651367188, -119.30105590820312, 0,
        23.302780151367188, -117.30108642578125, 0, 23.31060791015625, -115.30108642578125, 0,
        23.31842041015625, -113.30111694335938, 0, 23.326248168945312, -111.30111694335938, 0,
        23.334060668945312, -109.3011474609375, 0, 23.341888427734375, -107.3011474609375, 0,
        23.349365234375, -105.30117797851562, 0, 23.35626220703125, -103.30117797851562, 0,
        23.363143920898438, -101.30120849609375, 0, 23.370025634765625, -99.30120849609375, 0,
        23.376922607421875, -97.30123901367188, 0, 23.383804321289062, -95.30123901367188, 0,
        23.390701293945312, -93.30123901367188, 0, 23.3975830078125, -91.30126953125, 0,
        23.404464721679688, -89.30126953125, 0, 23.411361694335938, -87.30126953125, 0,
        23.418243408203125, -85.30130004882812, 0, 23.425140380859375, -83.30130004882812, 0,
        23.432022094726562, -81.30133056640625, 0, 23.43890380859375, -79.30133056640625, 0,
        23.44580078125, -77.30133056640625, 0, 23.452682495117188, -75.30136108398438, 0,
        23.459579467773438, -73.30136108398438, 0, 23.466461181640625, -71.3013916015625, 0,
        23.473342895507812, -69.3013916015625, 0, 23.479095458984375, -67.3013916015625, 0,
        23.484481811523438, -65.3013916015625, 0, 23.4898681640625, -63.301422119140625, 0,
        23.4952392578125, -61.301422119140625, 0, 23.500625610351562, -59.301422119140625, 0,
        23.506011962890625, -57.301422119140625, 0, 23.511398315429688, -55.30145263671875, 0,
        23.51678466796875, -53.30145263671875, 0, 23.52215576171875, -51.30145263671875, 0,
        23.527542114257812, -49.30145263671875, 0, 23.532928466796875, -47.301483154296875, 0,
        23.538314819335938, -45.301483154296875, 0, 23.543701171875, -43.301483154296875, 0,
        23.549072265625, -41.301483154296875, 0, 23.554458618164062, -39.301483154296875, 0,
        23.559844970703125, -37.301513671875, 0, 23.565231323242188, -35.301513671875, 0,
        23.568862915039062, -33.301513671875, 0, 23.572372436523438, -31.301513671875, 0,
        23.57586669921875, -29.301544189453125, 0, 23.579360961914062, -27.301544189453125, 0,
        23.582855224609375, -25.301544189453125, 0, 23.58636474609375, -23.301544189453125, 0,
        23.589859008789062, -21.301544189453125, 0, 23.59197998046875, -19.301544189453125, 0,
        23.593429565429688, -17.301544189453125, 0, 23.594879150390625, -15.301544189453125, 0,
        23.5963134765625, -13.301544189453125, 0, 23.597763061523438, -11.301544189453125, 0,
        23.599212646484375, -9.301544189453125, 0, 23.591964721679688, -7.3015899658203125, 0,
        23.516464233398438, -5.3031463623046875, 0, 23.361663818359375, -3.309295654296875, 0,
        23.1279296875, -1.3231353759765625, 0, 22.81622314453125, 0.6522979736328125, 0,
        22.427871704101562, 2.614105224609375, 0, 21.964645385742188, 4.5595855712890625, 0,
        21.428619384765625, 6.4862823486328125, 0, 20.821990966796875, 8.391952514648438, 0,
        20.147430419921875, 10.274658203125, 0, 19.407760620117188, 12.132705688476562, 0,
        18.6055908203125, 13.9647216796875, 0, 17.743988037109375, 15.769485473632812, 0,
        16.82574462890625, 17.546173095703125, 0, 15.853790283203125, 19.293991088867188, 0,
        14.83074951171875, 21.012435913085938, 0, 13.759765625, 22.701461791992188, 0,
        12.642898559570312, 24.360488891601562, 0, 11.483001708984375, 25.989715576171875, 0,
        10.282470703125, 27.5892333984375, 0, 9.043502807617188, 29.159194946289062, 0,
        7.7682342529296875, 30.699798583984375, 0, 6.4586181640625, 32.211334228515625, 0,
        5.1165618896484375, 33.69413757324219, 0, 3.7437896728515625, 35.14854431152344, 0,
        2.341949462890625, 36.57499694824219, 0, 0.9124908447265625, 37.973724365234375, 0,
        -0.54327392578125, 39.34507751464844, 0, -2.0238800048828125, 40.68959045410156, 0,
        -3.5283966064453125, 42.007293701171875, 0, -5.0555419921875, 43.2987060546875, 0,
        -6.604461669921875, 44.563873291015625, 0, -8.174331665039062, 45.803009033203125, 0,
        -9.764373779296875, 47.016143798828125, 0, -11.374069213867188, 48.20307922363281, 0,
        -13.00250244140625, 49.36412048339844, 0, -14.649810791015625, 50.49827575683594, 0,
        -16.315582275390625, 51.60514831542969, 0, -18.000137329101562, 52.683135986328125, 0,
        -19.704010009765625, 53.73040771484375, 0, -21.42919921875, 54.7420654296875, 0,
        -23.185150146484375, 55.69905090332031, 0, -24.97313690185547, 56.595184326171875, 0,
        -26.778549194335938, 57.45561218261719, 0, -28.59923553466797, 58.283203125, 0,
        -30.433547973632812, 59.08024597167969, 0, -32.27967834472656, 59.84942626953125, 0,
        -34.13610076904297, 60.59352111816406, 0, -36.001129150390625, 61.31578063964844, 0,
        -37.87323760986328, 62.01939392089844, 0, -39.751075744628906, 62.70768737792969, 0,
        -41.63330078125, 63.3839111328125, 0, -43.51844787597656, 64.05183410644531, 0,
        -45.40538787841797, 64.71481323242188, 0, -47.292640686035156, 65.37678527832031, 0,
        -49.17913818359375, 66.041015625, 0, -51.06340789794922, 66.71142578125, 0,
        -52.94422912597656, 67.3914794921875, 0, -54.81996154785156, 68.08543395996094, 0,
        -56.68916320800781, 68.79685974121094, 0, -58.55010223388672, 69.52951049804688, 0,
        -60.401039123535156, 70.28712463378906, 0, -62.24000549316406, 71.07331848144531, 0,
        -64.06488800048828, 71.89166259765625, 0, -65.87349700927734, 72.74534606933594, 0,
        -67.66321563720703, 73.63789367675781, 0, -69.43157958984375, 74.57209777832031, 0,
        -71.1758041381836, 75.55055236816406, 0, -72.89311218261719, 76.57554626464844, 0,
        -74.58062744140625, 77.64888000488281, 0, -76.23541259765625, 78.77197265625, 0,
        -77.8547134399414, 79.94564819335938, 0, -79.43566131591797, 81.17047119140625, 0,
        -80.97589874267578, 82.44612121582031, 0, -82.53379821777344, 83.69903564453125, 0,
        -84.0654296875, 84.98492431640625, 0, -85.54943466186523, 86.32557678222656, 0,
        -86.98912811279297, 87.71371459960938, 0, -88.38473510742188, 89.14617919921875, 0,
        -89.73547744750977, 90.62103271484375, 0, -91.03973007202148, 92.13713073730469, 0,
        -92.2955322265625, 93.69358825683594, 0, -93.5002555847168, 95.28994750976562, 0,
        -94.65119934082031, 96.92544555664062, 0, -95.74519348144531, 98.59959411621094, 0,
        -96.77886962890625, 100.31163024902344, 0, -97.74835586547852, 102.060791015625, 0,
        -98.65022277832031, 103.84574890136719, 0, -99.48015594482422, 105.66526794433594, 0,
        -100.23434829711914, 107.51744842529297, 0, -100.9088020324707, 109.4001235961914, 0,
        -101.49971771240234, 111.31068420410156, 0, -102.0035629272461, 113.24597930908203, 0,
        -102.41702270507812, 115.20259094238281, 0, -102.7375259399414, 117.1765365600586, 0,
        -102.96297836303711, 119.16361999511719, 0, -103.0920295715332, 121.15925598144531, 0,
        -103.12390899658203, 123.15876770019531, 0, -103.05900192260742, 125.15753173828125, 0,
        -102.89823150634766, 127.15086364746094, 0, -102.64337921142578, 129.13436889648438, 0,
        -102.29690551757812, 131.10394287109375, 0, -101.85187149047852, 133.0531005859375, 0,
        -101.15023422241211, 134.92544555664062, 0, -100.3334732055664, 136.75081634521484, 0,
        -99.42440032958984, 138.53204345703125, 0, -98.43036651611328, 140.26728057861328, 0,
        -97.35517501831055, 141.9534912109375, 0, -96.20086288452148, 143.58650970458984, 0,
        -94.96910095214844, 145.16193389892578, 0, -93.66140747070312, 146.67488861083984, 0,
        -92.2792739868164, 148.12018585205078, 0, -90.82475662231445, 149.49256896972656, 0,
        -89.3004264831543, 150.78699493408203, 0, -87.70946502685547, 151.99851989746094, 0,
        -86.05561447143555, 153.12273406982422, 0, -84.34335327148438, 154.15573120117188, 0,
        -82.5776596069336, 155.09455108642578, 0, -80.76400756835938, 155.93695831298828, 0,
        -78.90809631347656, 156.68177795410156, 0, -77.01589965820312, 157.32876586914062, 0,
        -75.09319305419922, 157.87865447998047, 0, -73.14567565917969, 158.33301544189453, 0,
        -71.17877960205078, 158.69426727294922, 0, -69.1973648071289, 158.9652099609375, 0,
        -67.20603942871094, 159.14940643310547, 0, -65.208740234375, 159.25051879882812, 0,
        -63.20899200439453, 159.27261352539062, 0, -61.20978546142578, 159.21971893310547, 0,
        -59.215065002441406, 159.0790557861328, 0, -57.22911834716797, 158.8436279296875, 0,
        -55.25238800048828, 158.5399169921875, 0, -53.28594970703125, 158.17549896240234, 0,
        -51.33088684082031, 157.75435638427734, 0, -49.38838195800781, 157.27831268310547, 0,
        -47.45989990234375, 156.74854278564453, 0, -45.54679870605469, 156.16567993164062, 0,
        -43.650657653808594, 155.52975463867188, 0, -41.77306365966797, 154.84104919433594, 0,
        -39.915740966796875, 154.09937286376953, 0, -38.080528259277344, 153.30462646484375, 0,
        -36.269317626953125, 152.45645904541016, 0, -34.484153747558594, 151.55496978759766, 0,
        -32.727134704589844, 150.59963989257812, 0, -31.000343322753906, 149.59075927734375, 0,
        -29.305870056152344, 148.52845764160156, 0, -27.646148681640625, 147.41268920898438, 0,
        -26.023345947265625, 146.2439422607422, 0, -24.43962860107422, 145.02266693115234, 0,
        -22.897216796875, 143.74960327148438, 0, -21.39826202392578, 142.4257354736328, 0,
        -19.9447021484375, 141.05211639404297, 0, -18.53857421875, 139.62999725341797, 0,
        -17.181472778320312, 138.16100311279297, 0, -15.875152587890625, 136.64669799804688, 0,
        -14.620849609375, 135.08900451660156, 0, -13.419815063476562, 133.48993682861328, 0,
        -12.272979736328125, 131.8515625, 0, -11.180999755859375, 130.176025390625, 0,
        -10.144393920898438, 128.4657745361328, 0, -9.163314819335938, 126.7230224609375, 0,
        -8.23785400390625, 124.95012664794922, 0, -7.367828369140625, 123.14937591552734, 0,
        -6.5527191162109375, 121.3230972290039, 0, -5.8441619873046875, 119.4534912109375, 0,
        -5.2813568115234375, 117.5346908569336, 0, -4.8441162109375, 115.5833969116211, 0,
        -4.51507568359375, 113.6108627319336, 0, -4.279632568359375, 111.62493896484375, 0,
        -4.12542724609375, 109.63102722167969, 0, -4.0424652099609375, 107.63284301757812, 0,
        -4.0222320556640625, 105.63301086425781, 0, -4.0576324462890625, 103.6334228515625, 0,
        -4.1427154541015625, 101.63526916503906, 0, -4.2725067138671875, 99.63951110839844, 0,
        -4.4427642822265625, 97.64682006835938, 0, -4.64984130859375, 95.65760803222656, 0,
        -4.8905487060546875, 93.67216491699219, 0, -5.162078857421875, 91.69073486328125, 0,
        -5.46209716796875, 89.71337890625, 0, -5.7887115478515625, 87.74024963378906, 0,
        -6.1399078369140625, 85.77130126953125, 0, -6.51397705078125, 83.80659484863281, 0,
        -6.909515380859375, 81.84611511230469, 0, -7.3254241943359375, 79.88987731933594, 0,
        -7.7605743408203125, 77.93780517578125, 0, -8.213531494140625, 75.98977661132812, 0,
        -8.683837890625, 74.04583740234375, 0, -9.170013427734375, 72.10589599609375, 0,
        -9.672378540039062, 70.16999816894531, 0, -10.189132690429688, 68.2379150390625, 0,
        -10.720779418945312, 66.30987548828125, 0, -11.265884399414062, 64.38558959960938, 0,
        -11.824539184570312, 62.46519470214844, 0, -12.396240234375, 60.548675537109375, 0,
        -12.980560302734375, 58.63594055175781, 0, -13.577285766601562, 56.72703552246094, 0,
        -14.186309814453125, 54.82200622558594, 0, -14.80743408203125, 52.920928955078125, 0,
        -15.440643310546875, 51.02384948730469, 0, -16.086410522460938, 49.13093566894531, 0,
        -16.744644165039062, 47.24235534667969, 0, -17.416717529296875, 45.35868835449219, 0,
        -18.103729248046875, 43.480377197265625, 0, -18.82196044921875, 41.61408996582031, 0,
        -19.598098754882812, 39.77082824707031, 0, -20.353805541992188, 37.91908264160156, 0,
        -21.0892333984375, 36.05926513671875, 0, -21.802734375, 34.190887451171875, 0,
        -22.493560791015625, 32.31401062011719, 0, -23.160995483398438, 30.428619384765625, 0,
        -23.803817749023438, 28.5347900390625, 0, -24.42083740234375, 26.632415771484375, 0,
        -25.010818481445312, 24.721405029296875, 0, -25.57244110107422, 22.801895141601562, 0,
        -26.104393005371094, 20.873947143554688, 0, -26.605300903320312, 18.937728881835938, 0,
        -27.073944091796875, 16.99346923828125, 0, -27.508071899414062, 15.04119873046875, 0,
        -27.906204223632812, 13.081222534179688, 0, -28.26642608642578, 11.113998413085938, 0,
        -28.586830139160156, 9.139862060546875, 0, -28.86542510986328, 7.15936279296875, 0,
        -29.099769592285156, 5.1732177734375, 0, -29.28771209716797, 3.18212890625, 0,
        -29.426780700683594, 1.1869964599609375, 0, -29.51427459716797, -0.8109893798828125, 0,
        -29.547637939453125, -2.8106842041015625, 0, -29.523880004882812, -4.810455322265625, 0,
        -29.440101623535156, -6.8086395263671875, 0, -29.293441772460938, -8.803131103515625, 0,
        -29.080650329589844, -10.79168701171875, 0, -28.79864501953125, -12.771621704101562, 0,
        -28.44445037841797, -14.739837646484375, 0, -28.014724731445312, -16.693038940429688, 0,
        -27.507041931152344, -18.627334594726562, 0, -26.918190002441406, -20.538543701171875, 0,
        -26.245948791503906, -22.421966552734375, 0, -25.487815856933594, -24.272491455078125, 0,
        -24.642059326171875, -26.084625244140625, 0, -23.707199096679688, -27.852447509765625, 0,
        -22.681991577148438, -29.569427490234375, 0, -21.565841674804688, -31.2286376953125, 0,
        -20.358245849609375, -32.822540283203125, 0, -19.058761596679688, -34.34246826171875, 0,
        -17.66668701171875, -35.777984619140625, 0, -16.179397583007812, -37.11444091796875, 0,
        -14.587600708007812, -38.324005126953125, 0, -12.954742431640625, -39.4766845703125, 0,
        -11.308883666992188, -40.61260986328125, 0, -9.61627197265625, -41.677764892578125, 0,
        -7.88018798828125, -42.67041015625, 0, -6.104034423828125, -43.589385986328125, 0,
        -4.2913055419921875, -44.43408203125, 0, -2.44573974609375, -45.20428466796875, 0,
        -0.5708770751953125, -45.9002685546875, 0, 1.329681396484375, -46.522613525390625, 0,
        3.2524871826171875, -47.0723876953125, 0, 5.19427490234375, -47.55084228515625, 0,
        7.152008056640625, -47.95941162109375, 0, 9.122711181640625, -48.299835205078125, 0,
        11.103759765625, -48.574005126953125, 0, 13.092575073242188, -48.78387451171875, 0,
        15.087051391601562, -48.9315185546875, 0, 17.085098266601562, -49.01885986328125, 0,
        19.08477783203125, -49.0478515625, 0, 21.08453369140625, -49.0206298828125, 0,
        23.08282470703125, -48.938934326171875, 0, 25.07818603515625, -48.804595947265625, 0,
        27.069564819335938, -48.61944580078125, 0, 29.055740356445312, -48.385040283203125, 0,
        31.035751342773438, -48.10302734375, 0, 33.008544921875, -47.774658203125, 0,
        34.97331237792969, -47.401397705078125, 0, 36.929351806640625, -46.984344482421875, 0,
        38.87579345703125, -46.524810791015625, 0, 40.8118896484375, -46.02362060546875, 0,
        42.73704528808594, -45.481719970703125, 0, 44.65040588378906, -44.8995361328125, 0,
        46.55131530761719, -44.277862548828125, 0, 48.438995361328125, -43.6173095703125, 0,
        50.3126220703125, -42.917724609375, 0, 52.17143249511719, -42.17974853515625, 0,
        54.014373779296875, -41.40283203125, 0, 55.84039306640625, -40.587127685546875, 0,
        57.64837646484375, -39.732086181640625, 0, 59.436767578125, -38.836944580078125, 0,
        61.2039794921875, -37.900604248046875, 0, 62.94805908203125, -36.92181396484375, 0,
        64.66510009765625, -35.896453857421875, 0, 66.34394836425781, -34.8096923828125, 0,
        67.98454284667969, -33.665985107421875, 0, 69.58821105957031, -32.470977783203125, 0,
        71.15655517578125, -31.2301025390625, 0, 72.69087219238281, -29.947265625, 0,
        74.19279479980469, -28.62664794921875, 0, 75.66371154785156, -27.27154541015625, 0,
        77.1048583984375, -25.884857177734375, 0, 78.51737976074219, -24.46905517578125, 0,
        79.90214538574219, -23.025970458984375, 0, 81.26033020019531, -21.557952880859375, 0,
        82.59288024902344, -20.06658935546875, 0, 83.90010070800781, -18.55291748046875, 0,
        85.18301391601562, -17.018630981445312, 0, 86.44158935546875, -15.464324951171875, 0,
        87.67643737792969, -13.891128540039062, 0, 88.88748168945312, -12.299468994140625, 0,
        90.074462890625, -10.689804077148438, 0, 91.23686218261719, -9.062301635742188, 0,
        92.37319946289062, -7.4165191650390625, 0, 93.48136901855469, -5.751617431640625, 0,
        94.5572509765625, -4.065704345703125, 0, 95.59140014648438, -2.3539581298828125, 0,
        96.54966735839844, -0.5989532470703125, 0, 97.42684936523438, 1.1984405517578125, 0,
        98.29084777832031, 3.00213623046875, 0, 99.14132690429688, 4.81231689453125, 0,
        99.97793579101562, 6.628936767578125, 0, 100.80021667480469, 8.452056884765625, 0,
        101.60772705078125, 10.281768798828125, 0, 102.40010070800781, 12.118148803710938, 0,
        103.17684936523438, 13.961135864257812, 0, 103.93754577636719, 15.810821533203125, 0,
        104.68173217773438, 17.667144775390625, 0, 105.40866088867188, 19.530380249023438, 0,
        106.11793518066406, 21.400375366210938, 0, 106.80921936035156, 23.277084350585938, 0,
        107.48139953613281, 25.1607666015625, 0, 108.13444519042969, 27.05108642578125, 0,
        108.76722717285156, 28.948348999023438, 0, 109.37962341308594, 30.852310180664062, 0,
        109.97026062011719, 32.763031005859375, 0, 110.53892517089844, 34.68046569824219, 0,
        111.08485412597656, 36.604522705078125, 0, 111.60716247558594, 38.53509521484375, 0,
        112.10487365722656, 40.47216796875, 0, 112.57737731933594, 42.41554260253906, 0,
        113.02375793457031, 44.3651123046875, 0, 113.44316101074219, 46.32057189941406, 0,
        113.83448791503906, 48.281890869140625, 0, 114.19679260253906, 50.248779296875, 0,
        114.52922058105469, 52.22093200683594, 0, 114.83082580566406, 54.198089599609375, 0,
        115.10005187988281, 56.1798095703125, 0, 115.33641052246094, 58.165802001953125, 0,
        115.53819274902344, 60.155548095703125, 0, 115.70475769042969, 62.14857482910156, 0,
        115.83473205566406, 64.14433288574219, 0, 115.92668151855469, 66.14215087890625, 0,
        115.97962951660156, 68.14138793945312, 0, 115.99232482910156, 70.141357421875, 0,
        115.96339416503906, 72.14111328125, 0, 115.89155578613281, 74.13975524902344, 0,
        115.77586364746094, 76.13636779785156, 0, 115.61491394042969, 78.12983703613281, 0,
        115.40748596191406, 80.11898803710938, 0, 115.15235900878906, 82.10261535644531, 0,
        114.84858703613281, 84.07933044433594, 0, 114.49501037597656, 86.04774475097656, 0,
        114.09056091308594, 88.00636291503906, 0, 113.63441467285156, 89.95358276367188, 0,
        113.12583923339844, 91.88778686523438, 0, 112.56404113769531, 93.80717468261719, 0,
        111.94844055175781, 95.70997619628906, 0, 111.27851867675781, 97.59442138671875, 0,
        110.55412292480469, 99.45849609375, 0, 109.77503967285156, 101.30043029785156, 0,
        108.94120788574219, 103.11824035644531, 0, 108.05293273925781, 104.91004943847656, 0,
        107.11042785644531, 106.6739501953125, 0, 106.11415100097656, 108.4081039428711, 0,
        105.06478881835938, 110.11054992675781, 0, 103.96311950683594, 111.77967834472656, 0,
        102.81004333496094, 113.41374969482422, 0, 101.60671997070312, 115.0111083984375, 0,
        100.35395812988281, 116.57010650634766, 0, 99.05332946777344, 118.08927917480469, 0,
        97.70600891113281, 119.56721496582031, 0, 96.3131103515625, 121.00234985351562, 0,
        94.87644958496094, 122.39362335205078, 0, 93.39712524414062, 123.73944854736328, 0,
        91.8765869140625, 125.03853607177734, 0, 90.31594848632812, 126.28907012939453, 0,
        88.7164306640625, 127.48958587646484, 0, 87.07904052734375, 128.63790130615234, 0,
        85.40447998046875, 129.7311782836914, 0, 83.69282531738281, 130.7655792236328, 0,
        81.94346618652344, 131.73458862304688, 0, 80.15267944335938, 132.62471771240234, 0,
        78.29783630371094, 133.37005615234375, 0, 76.41099548339844, 134.03326416015625, 0,
        74.51022338867188, 134.65515899658203, 0, 72.59559631347656, 135.2331771850586, 0,
        70.66741943359375, 135.76412963867188, 0, 68.72607421875, 136.2447967529297, 0,
        66.77223205566406, 136.67141723632812, 0, 64.80656433105469, 137.04042053222656, 0,
        62.83049011230469, 137.3474884033203, 0, 60.845123291015625, 137.58836364746094, 0,
        58.85240173339844, 137.75860595703125, 0, 56.85484313964844, 137.85333251953125, 0,
        54.85498046875, 137.8675765991211, 0, 52.85646057128906, 137.79666137695312, 0,
        50.86311340332031, 137.63574981689453, 0, 48.87971496582031, 137.38037872314453, 0,
        46.9114990234375, 137.02638244628906, 0, 44.9644775390625, 136.5704345703125, 0,
        43.04486083984375, 136.00979614257812, 0, 41.15956115722656, 135.34317016601562, 0,
        39.3153076171875, 134.56993865966797, 0, 37.51911926269531, 133.69122314453125, 0,
        35.77716064453125, 132.70909881591797, 0, 34.09538269042969, 131.62706756591797, 0,
        32.47898864746094, 130.44973754882812, 0, 30.932235717773438, 129.18226623535156, 0,
        29.458404541015625, 127.8307876586914, 0, 28.059722900390625, 126.40142822265625, 0,
        26.7381591796875, 124.90069580078125, 0, 25.494293212890625, 123.33484649658203, 0,
        24.328506469726562, 121.70999908447266, 0, 23.240875244140625, 120.03173828125, 0,
        22.231719970703125, 118.30522918701172, 0, 21.30108642578125, 116.53512573242188, 0,
        20.450363159179688, 114.72534942626953, 0, 19.682144165039062, 112.87890625, 0,
        19.004043579101562, 110.99766540527344, 0, 18.455978393554688, 109.07552337646484, 0,
        18.109664916992188, 107.10582733154297, 0, 17.836776733398438, 105.12460327148438, 0,
        17.643112182617188, 103.13426208496094, 0, 17.534454345703125, 101.13726806640625, 0,
        17.51702880859375, 99.13755798339844, 0, 17.59661865234375, 97.13943481445312, 0,
        17.779281616210938, 95.14799499511719, 0, 18.070175170898438, 93.16947937011719, 0,
        18.473770141601562, 91.21095275878906, 0, 18.993499755859375, 89.27999877929688, 0,
        19.631393432617188, 87.38478088378906, 0, 20.387924194335938, 85.53373718261719, 0,
        21.261489868164062, 83.735107421875, 0, 22.249252319335938, 81.99639892578125, 0,
        23.34625244140625, 80.32452392578125, 0, 24.54669189453125, 78.72517395019531, 0,
        25.843429565429688, 77.20301818847656, 0, 27.228836059570312, 75.76092529296875, 0,
        28.695053100585938, 74.40116882324219, 0, 30.234405517578125, 73.12467956542969, 0,
        31.839431762695312, 71.93173217773438, 0, 33.50323486328125, 70.8223876953125, 0,
        35.219635009765625, 69.7960205078125, 0, 36.983001708984375, 68.85281372070312, 0,
        38.788787841796875, 67.99356079101562, 0, 40.63325500488281, 67.22080993652344, 0,
        42.51432800292969, 66.54220581054688, 0, 44.433502197265625, 65.98091125488281, 0,
        46.368804931640625, 65.47657775878906, 0, 48.31492614746094, 65.0155029296875, 0,
        50.27105712890625, 64.59918212890625, 0, 52.23638916015625, 64.22894287109375, 0,
        54.21012878417969, 63.9061279296875, 0, 56.19134521484375, 63.63264465332031, 0,
        58.17869567871094, 63.40910339355469, 0, 60.17131042480469, 63.237548828125, 0,
        62.16764831542969, 63.11854553222656, 0, 64.16653442382812, 63.053314208984375, 0,
        66.16641235351562, 63.04316711425781, 0, 68.16593933105469, 63.08866882324219, 0,
        70.16323852539062, 63.19056701660156, 0, 72.15682983398438, 63.349395751953125, 0,
        74.14508056640625, 63.56562805175781, 0, 76.12612915039062, 63.839599609375, 0,
        78.09831237792969, 64.17111206054688, 0, 80.06008911132812, 64.56001281738281, 0,
        82.00953674316406, 65.00627136230469, 0, 83.94520568847656, 65.50912475585938, 0,
        85.86544799804688, 66.06808471679688, 0, 87.76884460449219, 66.68209838867188, 0,
        89.65371704101562, 67.35060119628906, 0, 91.51895141601562, 68.07212829589844, 0,
        93.36328125, 68.84553527832031, 0, 95.18562316894531, 69.66958618164062, 0,
        96.98475646972656, 70.54293823242188, 0, 98.75993347167969, 71.46394348144531, 0,
        100.51040649414062, 72.43121337890625, 0, 102.23551940917969, 73.44313049316406, 0,
        103.93460083007812, 74.49812316894531, 0, 105.60713195800781, 75.59452819824219, 0,
        107.25288391113281, 76.73098754882812, 0, 108.87141418457031, 77.90580749511719, 0,
        110.46263122558594, 79.117431640625, 0, 112.02629089355469, 80.36421203613281, 0,
        113.56217956542969, 81.64523315429688, 0, 115.07057189941406, 82.95843505859375, 0,
        116.55143737792969, 84.30258178710938, 0, 118.00489807128906, 85.67648315429688, 0,
        119.43064880371094, 87.07899475097656, 0, 120.82902526855469, 88.5087890625, 0,
        122.20021057128906, 89.96467590332031, 0, 123.54438781738281, 91.44557189941406, 0,
        124.86158752441406, 92.95048522949219, 0, 126.15190124511719, 94.47871398925781, 0,
        127.41542053222656, 96.02896118164062, 0, 128.6525421142578, 97.60040283203125, 0,
        129.86293029785156, 99.1925048828125, 0, 131.04698181152344, 100.80429077148438, 0,
        132.20423889160156, 102.43537902832031, 0, 133.33497619628906, 104.08514404296875, 0,
        134.43870544433594, 105.75286865234375, 0, 135.51512145996094, 107.4384765625, 0,
        136.56382751464844, 109.14154815673828, 0, 137.58384704589844, 110.86186981201172, 0,
        138.5735626220703, 112.59970092773438, 0, 139.5315399169922, 114.35535430908203, 0,
        140.45347595214844, 116.1301498413086, 0, 141.32957458496094, 117.92793273925781, 0,
        142.11317443847656, 119.76770782470703, 0, 142.8550567626953, 121.6249771118164, 0,
        143.5620574951172, 123.49579620361328, 0, 144.2323760986328, 125.38008880615234, 0,
        144.8631134033203, 127.27798461914062, 0, 145.45179748535156, 129.18939971923828, 0,
        145.9953155517578, 131.11400604248047, 0, 146.49070739746094, 133.05170440673828, 0,
        146.9340057373047, 135.0018081665039, 0, 147.3219757080078, 136.96376037597656, 0,
        147.65025329589844, 138.93653106689453, 0, 147.91481018066406, 140.9188995361328, 0,
        148.1106414794922, 142.90921020507812, 0, 148.23316955566406, 144.90523529052734, 0,
        148.27745056152344, 146.90471649169922, 0, 148.2378692626953, 148.904052734375, 0,
        148.10972595214844, 150.89977264404297, 0, 147.88771057128906, 152.88732147216797, 0,
        147.56724548339844, 154.8611831665039, 0, 147.14390563964844, 156.81568145751953, 0,
        146.61448669433594, 158.7440414428711, 0, 145.97633361816406, 160.63916778564453, 0,
        145.2281951904297, 162.49359130859375, 0, 144.37013244628906, 164.2998504638672, 0,
        143.4036407470703, 166.05043029785156, 0, 142.3316192626953, 167.73854064941406, 0,
        141.1584014892578, 169.3578109741211, 0, 139.88902282714844, 170.90300750732422, 0,
        138.52964782714844, 172.36966705322266, 0, 137.0870819091797, 173.75437927246094, 0,
        135.5679168701172, 175.05492401123047, 0, 133.9789581298828, 176.26893615722656, 0,
        132.3266143798828, 177.39546585083008, 0, 130.6169891357422, 178.43281173706055, 0,
        128.85523986816406, 179.37902069091797, 0, 127.04576110839844, 180.23026657104492, 0,
        125.19090270996094, 180.9773826599121, 0, 123.29289245605469, 181.6066017150879, 0,
        121.38517761230469, 182.20635223388672, 0, 119.44972229003906, 182.70935440063477, 0,
        117.49241638183594, 183.11948013305664, 0, 115.51841735839844, 183.44043731689453, 0,
        113.53263854980469, 183.6765251159668, 0, 111.53883361816406, 183.83232879638672, 0,
        109.54060363769531, 183.9125862121582, 0, 107.54072570800781, 183.92142868041992, 0,
        105.54161071777344, 183.86359405517578, 0, 103.54527282714844, 183.74325561523438, 0,
        101.55337524414062, 183.56437301635742, 0, 99.56719970703125, 183.3306999206543, 0,
        97.58761596679688, 183.0457305908203, 0, 95.61558532714844, 182.71276473999023, 0,
        93.65162658691406, 182.33481979370117, 0, 91.69633483886719, 181.91448593139648, 0,
        89.75003051757812, 181.4546127319336, 0, 87.81285095214844, 180.95751190185547, 0,
        85.885009765625, 180.42516708374023, 0, 83.96649169921875, 179.8598976135254, 0,
        82.05767822265625, 179.26327514648438, 0, 80.15818786621094, 178.63725662231445, 0,
        78.26820373535156, 177.98308181762695, 0, 76.38758850097656, 177.30248641967773, 0,
        74.51620483398438, 176.59685516357422, 0, 72.6539306640625, 175.86738967895508, 0,
        70.80079650878906, 175.11528396606445, 0, 68.95663452148438, 174.3413848876953, 0,
        67.12135314941406, 173.54668426513672, 0, 65.29464721679688, 172.73224258422852, 0,
        63.476470947265625, 171.89894485473633, 0, 61.66682434082031, 171.0476303100586, 0,
        59.86524963378906, 170.17888641357422, 0, 58.07206726074219, 169.29335021972656, 0,
        56.28688049316406, 168.3917236328125, 0, 54.50947570800781, 167.4746551513672, 0,
        52.73985290527344, 166.5427474975586, 0, 50.97798156738281, 165.596435546875, 0,
        49.223663330078125, 164.63603973388672, 0, 47.47674560546875, 163.66226959228516, 0,
        45.73712158203125, 162.67550659179688, 0, 44.00486755371094, 161.67584228515625, 0,
        42.2796630859375, 160.66400146484375, 0, 40.56163024902344, 159.6400375366211, 0,
        38.850616455078125, 158.60453033447266, 0, 37.14662170410156, 157.5574722290039, 0,
        35.44944763183594, 156.49927520751953, 0, 33.75926208496094, 155.4301300048828, 0,
        32.075897216796875, 154.35012817382812, 0, 30.39935302734375, 153.25962829589844, 0,
        28.729644775390625, 152.1587677001953, 0, 27.066757202148438, 151.0476303100586, 0,
        25.41064453125, 149.9261703491211, 0, 23.761764526367188, 148.79430389404297, 0,
        22.119857788085938, 147.65237426757812, 0, 20.485122680664062, 146.50023651123047, 0,
        18.85784912109375, 145.33750915527344, 0, 17.238311767578125, 144.16397094726562, 0,
        15.626861572265625, 142.97930908203125, 0, 14.02459716796875, 141.782470703125, 0,
        12.432540893554688, 140.57181549072266, 0, 10.854354858398438, 139.34329223632812, 0,
        9.289718627929688, 138.09767150878906, 0, 7.7142181396484375, 136.8656463623047, 0,
        6.1277618408203125, 135.64791107177734, 0, 4.5302734375, 134.4446029663086, 0,
        2.9217376708984375, 133.25594329833984, 0, 1.30230712890625, 132.0822982788086, 0,
        -0.327911376953125, 130.9238510131836, 0, -1.9691314697265625, 129.78077697753906, 0,
        -3.621124267578125, 128.65342712402344, 0, -5.283843994140625, 127.54197692871094, 0,
        -6.957183837890625, 126.44670867919922, 0, -8.641189575195312, 125.36776733398438, 0,
        -10.335784912109375, 124.30558013916016, 0, -12.040908813476562, 123.26026916503906, 0,
        -13.756378173828125, 122.23209381103516, 0, -15.482223510742188, 121.22142028808594, 0,
        -17.218353271484375, 120.22862243652344, 0, -18.964614868164062, 119.25352478027344, 0,
        -20.720794677734375, 118.29663848876953, 0, -22.486923217773438, 117.35819244384766, 0,
        -24.262916564941406, 116.43846893310547, 0, -26.04865264892578, 115.53775787353516, 0,
        -27.843902587890625, 114.65640258789062, 0, -29.648696899414062, 113.7945556640625, 0,
        -31.46277618408203, 112.95256805419922, 0, -33.28601837158203, 112.13065338134766, 0,
        -35.11834716796875, 111.32902526855469, 0, -36.95951843261719, 110.54794311523438, 0,
        -38.80950927734375, 109.78779602050781, 0, -40.668006896972656, 109.04913330078125, 0,
        -42.534942626953125, 108.3316650390625, 0, -44.41001892089844, 107.63616943359375, 0,
        -46.293296813964844, 106.96267700195312, 0, -48.184288024902344, 106.31166076660156, 0,
        -50.08301544189453, 105.68316650390625, 0, -51.98920440673828, 105.07806396484375, 0,
        -53.90271759033203, 104.49613952636719, 0, -55.82316589355469, 103.93797302246094, 0,
        -57.750579833984375, 103.40386962890625, 0, -59.68445587158203, 102.89431762695312, 0,
        -61.62488555908203, 102.40962219238281, 0, -63.57136535644531, 101.95022583007812, 0,
        -65.52375793457031, 101.51644897460938, 0, -67.48188781738281, 101.10919189453125, 0,
        -69.44529724121094, 100.72848510742188, 0, -71.41385650634766, 100.37525939941406, 0,
        -73.38715362548828, 100.050048828125, 0, -75.36498260498047, 99.75349426269531, 0,
        -77.34709930419922, 99.48666381835938, 0, -79.33305358886719, 99.25021362304688, 0,
        -81.32257080078125, 99.04548645019531, 0, -83.31510925292969, 98.87384033203125, 0,
        -85.31046676635742, 98.73690795898438, 0, -87.30791854858398, 98.636962890625, 0,
        -89.3070182800293, 98.57711791992188, 0, -91.30683898925781, 98.56211853027344, 0,
        -93.30643081665039, 98.60040283203125, 0, -95.30324935913086, 98.71124267578125, 0,
        -97.29854583740234, 98.8460693359375, 0, -99.29010391235352, 99.02915954589844, 0,
        -101.27635192871094, 99.26264953613281, 0, -103.25575256347656, 99.54905700683594, 0,
        -105.22631454467773, 99.89031982421875, 0, -107.18610000610352, 100.28907775878906, 0,
        -109.13283920288086, 100.747314453125, 0, -111.06395721435547, 101.267333984375, 0,
        -112.97674179077148, 101.85115051269531, 0, -114.86811447143555, 102.50082397460938, 0,
        -116.73494338989258, 103.21803283691406, 0, -118.5738639831543, 104.00428771972656, 0,
        -120.3810863494873, 104.86054992675781, 0, -122.153076171875, 105.7877197265625, 0,
        -123.88590621948242, 106.78594970703125, 0, -125.57588195800781, 107.85526275634766, 0,
        -127.21930694580078, 108.99481964111328, 0, -128.81245613098145, 110.2035903930664, 0,
        -130.3521957397461, 111.4797592163086, 0, -131.83544540405273, 112.82115936279297, 0,
        -133.25955200195312, 114.2251205444336, 0, -134.62229442596436, 115.68885803222656, 0,
        -135.92187690734863, 117.20890808105469, 0, -137.1570053100586, 118.78176879882812, 0,
        -138.32687854766846, 120.40375518798828, 0, -139.43089866638184, 122.0711669921875, 0,
        -140.46928596496582, 123.78044891357422, 0, -141.44222450256348, 125.52767944335938, 0,
        -142.35034322738647, 127.30948638916016, 0, -143.1946063041687, 129.12242126464844, 0,
        -143.9761471748352, 130.96329498291016, 0, -144.6961212158203, 132.82913970947266, 0,
        -145.35581398010254, 134.71705627441406, 0, -145.95671272277832, 136.62460327148438, 0,
        -146.50000715255737, 138.54924774169922, 0, -146.98723459243774, 140.48902130126953, 0,
        -147.4194277524948, 142.44164276123047, 0, -147.7977364063263, 144.4054412841797, 0,
        -148.1230742931366, 146.3788070678711, 0, -148.39618927240372, 148.36001586914062, 0,
        -148.6172491312027, 150.34769439697266, 0, -148.78599804639816, 152.34040069580078, 0,
        -148.90131011605263, 154.33702087402344, 0, -148.9604774415493, 156.3360824584961, 0,
        -148.95654794573784, 158.3360137939453, 0, -148.83045449852943, 160.3302459716797, 0,
        -148.54535990953445, 162.30978393554688, 0, -148.2197985649109, 164.2830810546875, 0,
        -147.8527604341507, 166.24905395507812, 0, -147.44328701496124, 168.20664978027344, 0,
        -146.99047303199768, 170.15467834472656, 0, -146.49335384368896, 172.0917854309082, 0,
        -145.95115327835083, 174.01688766479492, 0, -145.36313366889954, 175.92841720581055, 0,
        -144.72855710983276, 177.82506561279297, 0, -144.04688358306885, 179.7052459716797, 0,
        -143.31764316558838, 181.56751251220703, 0, -142.54033994674683, 183.41022109985352, 0,
        -141.7148470878601, 185.23184204101562, 0, -140.84101104736328, 187.03072357177734, 0,
        -139.91876411437988, 188.80537033081055, 0, -138.94834327697754, 190.55401229858398, 0,
        -137.92995071411133, 192.27534103393555, 0, -136.86415576934814, 193.96753692626953, 0,
        -135.75138664245605, 195.62942504882812, 0, -134.59247970581055, 197.25933837890625, 0,
        -133.38828372955322, 198.85607528686523, 0, -132.13978385925293, 200.41839218139648, 0,
        -130.84808349609375, 201.9451789855957, 0, -129.5142936706543, 203.43548202514648, 0,
        -128.13980293273926, 204.88830947875977, 0, -126.72612190246582, 206.30281257629395, 0,
        -125.27444839477539, 207.67853927612305, 0, -123.78643226623535, 209.01478576660156, 0,
        -122.26358795166016, 210.31113624572754, 0, -120.70739364624023, 211.56748390197754, 0,
        -119.1196460723877, 212.78340530395508, 0, -117.50162315368652, 213.9589328765869, 0,
        -115.8549690246582, 215.09402084350586, 0, -114.18126678466797, 216.18884086608887, 0,
        -112.48210906982422, 217.24355125427246, 0, -110.75864791870117, 218.25828552246094, 0,
        -109.01253509521484, 219.23353958129883, 0, -107.24508285522461, 220.16943454742432, 0,
        -105.45766830444336, 221.06657123565674, 0, -103.65147018432617, 221.92522811889648, 0,
        -101.82759475708008, 222.74592781066895, 0, -99.9873161315918, 223.52912616729736, 0,
        -98.13178634643555, 224.27526092529297, 0, -96.26195526123047, 224.9848108291626, 0,
        -94.37876892089844, 225.65816020965576, 0, -92.48325729370117, 226.29589080810547, 0,
        -90.57615280151367, 226.89823627471924, 0, -88.6583251953125, 227.46567153930664, 0,
        -86.73062133789062, 227.99849319458008, 0, -84.79373168945312, 228.49683380126953, 0,
        -82.84835815429688, 228.96098518371582, 0, -80.8951416015625, 229.3908805847168, 0,
        -78.93467712402344, 229.78658199310303, 0, -76.96756744384766, 230.1477665901184, 0,
        -74.99431610107422, 230.47389221191406, 0, -73.0155258178711, 230.76391077041626, 0,
        -71.0316162109375, 231.01658058166504, 0, -69.04296112060547, 231.22898125648499, 0,
        -67.05005645751953, 231.39608907699585, 0, -65.0529556274414, 231.50254607200623, 0,
        -63.055267333984375, 231.5992352962494, 0, -61.05657196044922, 231.66792631149292, 0,
        -59.05689239501953, 231.708172082901, 0, -57.05693054199219, 231.71952533721924, 0,
        -55.057044982910156, 231.70156741142273, 0, -53.057708740234375, 231.6536693572998, 0,
        -51.059181213378906, 231.57536458969116, 0, -49.06219482421875, 231.46654868125916, 0,
        -47.06714630126953, 231.32642769813538, 0, -45.07451629638672, 231.1548662185669, 0,
        -43.084938049316406, 230.95136213302612, 0, -41.098907470703125, 230.71570205688477, 0,
        -39.117027282714844, 230.44728708267212, 0, -37.139854431152344, 230.1461787223816, 0,
        -35.16802215576172, 229.8118553161621, 0, -33.20219421386719, 229.444016456604, 0,
        -31.242889404296875, 229.04262733459473, 0, -29.290878295898438, 228.60749197006226, 0,
        -27.34668731689453, 228.1384015083313, 0, -25.411033630371094, 227.63524103164673, 0,
        -23.48461151123047, 227.09793949127197, 0, -21.567955017089844, 226.52641773223877, 0,
        -19.661956787109375, 225.92075729370117, 0, -17.767105102539062, 225.2809829711914, 0,
        -15.884048461914062, 224.6071834564209, 0, -14.013442993164062, 223.89936447143555, 0,
        -12.156204223632812, 223.15770149230957, 0, -10.312469482421875, 222.38247966766357, 0,
        -8.483367919921875, 221.57388019561768, 0, -6.6691436767578125, 220.73211669921875, 0,
        -4.87054443359375, 219.8575258255005, 0, -3.088104248046875, 218.9504737854004, 0,
        -1.322418212890625, 218.011137008667, 0, 0.426055908203125, 217.04015731811523, 0,
        2.156768798828125, 216.03782653808594, 0, 3.869110107421875, 215.0045051574707, 0,
        5.56268310546875, 213.9408016204834, 0, 7.237152099609375, 212.84711456298828, 0,
        8.891998291015625, 211.7239761352539, 0, 10.526779174804688, 210.57196426391602, 0,
        12.1412353515625, 209.3915138244629, 0, 13.735015869140625, 208.1832275390625, 0,
        15.30755615234375, 206.94757270812988, 0, 16.8587646484375, 205.68519020080566, 0,
        18.388381958007812, 204.39671325683594, 0, 19.89599609375, 203.08243942260742, 0,
        21.381439208984375, 201.7433090209961, 0, 22.84429931640625, 200.3796501159668, 0,
        24.284637451171875, 198.9919776916504, 0, 25.702072143554688, 197.58098602294922, 0,
        27.096359252929688, 196.1472396850586, 0, 28.467422485351562, 194.69111251831055, 0,
        29.81494140625, 193.21336364746094, 0, 31.138839721679688, 191.71414184570312, 0,
        32.438720703125, 190.19436264038086, 0, 33.714569091796875, 188.65409088134766, 0,
        34.966094970703125, 187.0940933227539, 0, 36.19306945800781, 185.51476287841797, 0,
        37.39527893066406, 183.91639709472656, 0, 38.572052001953125, 182.2992401123047, 0,
        39.72343444824219, 180.66395568847656, 0, 40.848724365234375, 179.0105323791504, 0,
        41.94743347167969, 177.33952713012695, 0, 43.01893615722656, 175.65077590942383, 0,
        44.062408447265625, 173.9444923400879, 0, 45.07646179199219, 172.22074508666992, 0,
        46.05964660644531, 170.47905731201172, 0, 47.00971984863281, 168.7192153930664, 0,
        47.922576904296875, 166.93968200683594, 0, 48.79127502441406, 165.13829040527344, 0,
        49.61572265625, 163.3161849975586, 0, 50.39915466308594, 161.47618103027344, 0,
        51.11030578613281, 159.60706329345703, 0, 51.75743103027344, 157.71475219726562, 0,
        52.34716796875, 155.80367279052734, 0, 52.88592529296875, 153.8776397705078, 0,
        53.378570556640625, 151.9394302368164, 0, 53.82939147949219, 149.99092864990234, 0,
        54.24211120605469, 148.03392791748047, 0, 54.620147705078125, 146.07003784179688, 0,
        54.96629333496094, 144.1001968383789, 0, 55.28273010253906, 142.12550354003906, 0,
        55.5718994140625, 140.1464614868164, 0, 55.835693359375, 138.1639633178711, 0,
        56.07582092285156, 136.17850494384766, 0, 56.293853759765625, 134.19042205810547, 0,
        56.49110412597656, 132.20021057128906, 0, 56.668853759765625, 130.2080535888672, 0,
        56.828094482421875, 128.21446228027344, 0, 56.96990966796875, 126.21952056884766, 0,
        57.09521484375, 124.22337341308594, 0, 57.204864501953125, 122.22647094726562, 0,
        57.29927062988281, 120.2286605834961, 0, 57.379730224609375, 118.23030853271484, 0,
        57.44657897949219, 116.23136901855469, 0, 57.50038146972656, 114.23216247558594, 0,
        57.54168701171875, 112.23255920410156, 0, 57.57096862792969, 110.23278045654297, 0,
        57.58868408203125, 108.23290252685547, 0, 57.59521484375, 106.23292541503906, 0,
        57.5909423828125, 104.23295593261719, 0, 57.57618713378906, 102.23297119140625, 0,
        57.551300048828125, 100.23307800292969, 0, 57.51655578613281, 98.2333984375, 0,
        57.47221374511719, 96.23390197753906, 0, 57.41853332519531, 94.234619140625, 0,
        57.35577392578125, 92.23565673828125, 0, 57.28387451171875, 90.23689270019531, 0, 57.203125,
        88.23858642578125, 0, 57.11381530761719, 86.24055480957031, 0, 57.01585388183594,
        84.24301147460938, 0, 56.90934753417969, 82.24578857421875, 0, 56.79412841796875,
        80.24917602539062, 0, 56.670440673828125, 78.25300598144531, 0, 56.538787841796875,
        76.25733947753906, 0, 56.39756774902344, 74.26234436035156, 0, 56.24774169921875,
        72.26792907714844, 0, 56.08842468261719, 70.27430725097656, 0, 55.918670654296875,
        68.28152465820312, 0, 55.73695373535156, 66.28974914550781, 0, 55.5794677734375,
        64.29685974121094, 0, 55.54866027832031, 62.29704284667969, 0, 55.575897216796875,
        60.29753112792969, 0, 55.661529541015625, 58.299285888671875, 0, 55.80523681640625,
        56.30462646484375, 0, 56.007110595703125, 54.31498718261719, 0, 56.266754150390625,
        52.33186340332031, 0, 56.58406066894531, 50.357208251953125, 0, 56.958251953125,
        48.39259338378906, 0, 57.38871765136719, 46.43971252441406, 0, 57.87486267089844,
        44.499725341796875, 0, 58.416107177734375, 42.57429504394531, 0, 59.011199951171875,
        40.665130615234375, 0, 59.65971374511719, 38.77325439453125, 0, 60.360565185546875,
        36.90000915527344, 0, 61.11285400390625, 35.04707336425781, 0, 61.91603088378906,
        33.21531677246094, 0, 62.76921081542969, 31.40667724609375, 0, 63.67138671875,
        29.62188720703125, 0, 64.62271118164062, 27.862625122070312, 0, 65.6229248046875,
        26.130783081054688, 0, 66.67185974121094, 24.427886962890625, 0, 67.77066040039062,
        22.756927490234375, 0, 68.92112731933594, 21.12103271484375, 0, 70.12837219238281,
        19.526580810546875, 0, 71.40802001953125, 17.99029541015625, 0, 72.70509338378906,
        16.468063354492188, 0, 74.05528259277344, 14.992645263671875, 0, 75.46038818359375,
        13.569580078125, 0, 76.92218017578125, 12.204757690429688, 0, 78.44172668457031,
        10.904556274414062, 0, 80.01948547363281, 9.675918579101562, 0, 81.65542602539062,
        8.52581787109375, 0, 83.34846496582031, 7.4613800048828125, 0, 85.09619140625,
        6.4897918701171875, 0, 86.89569091796875, 5.61724853515625, 0, 88.74220275878906,
        4.84967041015625, 0, 90.63044738769531, 4.1916656494140625, 0, 92.55436706542969,
        3.64605712890625, 0, 94.50682067871094, 3.2146148681640625, 0, 96.48126220703125,
        2.89678955078125, 0, 98.47018432617188, 2.6908111572265625, 0, 100.46762084960938,
        2.5932159423828125, 0, 102.467529296875, 2.5994873046875, 0, 104.46438598632812,
        2.7041473388671875, 0, 106.45457458496094, 2.9012603759765625, 0, 108.43437194824219,
        3.184326171875, 0, 110.40101623535156, 3.546600341796875, 0, 112.35292053222656,
        3.9818572998046875, 0, 114.28889465332031, 4.4835205078125, 0, 116.20817565917969,
        5.046173095703125, 0, 118.10734558105469, 5.672637939453125, 0, 119.97645568847656,
        6.3839569091796875, 0, 121.81297302246094, 7.17498779296875, 0, 123.61561584472656,
        8.041290283203125, 0, 125.38221740722656, 8.978713989257812, 0, 127.11076354980469,
        9.984207153320312, 0, 128.7990264892578, 11.056198120117188, 0, 130.44480895996094,
        12.192672729492188, 0, 132.0445098876953, 13.392684936523438, 0, 133.59532165527344,
        14.655548095703125, 0, 135.09315490722656, 15.980453491210938, 0, 136.5335235595703,
        17.367721557617188, 0, 137.9117889404297, 18.816757202148438, 0, 139.2223358154297,
        20.327499389648438, 0, 140.4584197998047, 21.899459838867188, 0, 141.61366271972656,
        23.531784057617188, 0, 142.67991638183594, 25.223602294921875, 0, 143.6650848388672,
        26.963882446289062, 0, 144.5459747314453, 28.7591552734375, 0, 145.29917907714844,
        30.611190795898438, 0, 145.90260314941406, 32.51747131347656, 0, 146.33180236816406,
        34.47003173828125, 0, 146.5623321533203, 36.45567321777344, 0, 146.5729217529297,
        38.45454406738281, 0, 146.3498992919922, 40.44085693359375, 0, 145.8904571533203,
        42.386260986328125, 0, 145.20423889160156, 44.263580322265625, 0, 144.31077575683594,
        46.05192565917969, 0, 143.23536682128906, 47.73710632324219, 0, 142.0048370361328,
        49.31291198730469, 0, 140.6443328857422, 50.778045654296875, 0, 139.17552185058594,
        52.13470458984375, 0, 137.61622619628906, 53.38642883300781, 0, 135.98023986816406,
        54.5364990234375, 0, 134.2781524658203, 55.5863037109375, 0, 132.51536560058594,
        56.53033447265625, 0, 130.6669158935547, 57.28668212890625, 0, 128.7616729736328,
        57.89500427246094, 0, 126.83808898925781, 58.4420166015625, 0, 124.89796447753906,
        58.92681884765625, 0, 122.94261169433594, 59.34767150878906, 0, 120.97486877441406,
        59.70379638671875, 0, 118.99607849121094, 59.99444580078125, 0, 117.00880432128906,
        60.21903991699219, 0, 115.01533508300781, 60.3770751953125, 0, 113.01731872558594,
        60.46832275390625, 0, 111.01753234863281, 60.4932861328125, 0, 109.01808166503906,
        60.451629638671875, 0, 107.02113342285156, 60.344268798828125, 0, 105.02882385253906,
        60.17169189453125, 0, 103.04292297363281, 59.93461608886719, 0, 101.06573486328125,
        59.63398742675781, 0, 99.09906005859375, 59.270751953125, 0, 97.14482116699219,
        58.84588623046875, 0, 95.20481872558594, 58.36042785644531, 0, 93.28041076660156,
        57.8153076171875, 0, 91.37379455566406, 57.2115478515625, 0, 89.48646545410156,
        56.54997253417969, 0, 87.62049865722656, 55.8309326171875, 0, 85.77714538574219,
        55.055023193359375, 0, 83.95925903320312, 54.2215576171875, 0, 82.16929626464844,
        53.329681396484375, 0, 80.41139221191406, 52.37591552734375, 0, 78.69303894042969,
        51.352691650390625, 0, 77.02775573730469, 50.24565124511719, 0, 75.41116333007812,
        49.06831359863281, 0, 73.85420227050781, 47.81343078613281, 0, 72.3580322265625,
        46.48631286621094, 0, 70.92323303222656, 45.09339904785156, 0, 69.54931640625,
        43.64013671875, 0, 68.23597717285156, 42.13197326660156, 0, 66.9815673828125, 40.57421875,
        0, 65.78517150878906, 38.97187805175781, 0, 64.64437866210938, 37.32929992675781, 0,
        63.557586669921875, 35.65022277832031, 0, 62.522735595703125, 33.93879699707031, 0,
        61.53779602050781, 32.198486328125, 0, 60.60047912597656, 30.431625366210938, 0,
        59.709136962890625, 28.641433715820312, 0, 58.86131286621094, 26.829986572265625, 0,
        58.055328369140625, 24.999710083007812, 0, 57.28919982910156, 23.15228271484375, 0,
        56.56123352050781, 21.28948974609375, 0, 55.86970520019531, 19.412750244140625, 0,
        55.213165283203125, 17.523727416992188, 0, 54.590057373046875, 15.623367309570312, 0,
        53.998870849609375, 13.712677001953125, 0, 53.43846130371094, 11.792984008789062, 0,
        52.90742492675781, 9.864593505859375, 0, 52.40478515625, 7.9289398193359375, 0,
        51.92933654785156, 5.986358642578125, 0, 51.48005676269531, 4.0373077392578125, 0,
        51.05610656738281, 2.08294677734375, 0, 50.656494140625, 0.1230926513671875, 0,
        50.28047180175781, -1.84112548828125, 0, 49.92723083496094, -3.8096923828125, 0,
        49.59629821777344, -5.7820281982421875, 0, 49.28684997558594, -7.757904052734375, 0,
        48.99818420410156, -9.736923217773438, 0, 48.73036193847656, -11.718994140625, 0,
        48.48176574707031, -13.703506469726562, 0, 48.25303649902344, -15.690383911132812, 0,
        48.04347229003906, -17.679306030273438, 0, 47.852203369140625, -19.670196533203125, 0,
        47.68019104003906, -21.662811279296875, 0, 47.52607727050781, -23.656707763671875, 0,
        47.39039611816406, -25.652099609375, 0, 47.273162841796875, -27.648681640625, 0,
        47.17430114746094, -29.646392822265625, 0, 47.09429931640625, -31.644775390625, 0,
        47.03382873535156, -33.643707275390625, 0, 46.99385070800781, -35.643341064453125, 0,
        46.97705078125, -37.643341064453125, 0, 46.987457275390625, -39.643218994140625, 0,
        46.99446105957031, -41.643096923828125, 0, 46.9862060546875, -43.64306640625, 0,
        46.977935791015625, -45.643035888671875, 0, 46.96968078613281, -47.643035888671875, 0,
        46.96141052246094, -49.643035888671875, 0, 46.95314025878906, -51.64300537109375, 0,
        46.94488525390625, -53.642974853515625, 0, 46.936614990234375, -55.642974853515625, 0,
        46.9283447265625, -57.6429443359375, 0, 46.92008972167969, -59.642913818359375, 0,
        46.91181945800781, -61.642913818359375, 0, 46.90354919433594, -63.642913818359375, 0,
        46.895294189453125, -65.64288330078125, 0, 46.88702392578125, -67.64285278320312, 0,
        46.87876892089844, -69.64285278320312, 0, 46.87049865722656, -71.64285278320312, 0,
        46.86222839355469, -73.642822265625, 0, 46.853973388671875, -75.64279174804688, 0,
        46.845703125, -77.64279174804688, 0, 46.837432861328125, -79.64276123046875, 0,
        46.82917785644531, -81.64273071289062, 0, 46.82090759277344, -83.64273071289062, 0,
        46.812652587890625, -85.64273071289062, 0, 46.80438232421875, -87.6427001953125, 0,
        46.796112060546875, -89.64266967773438, 0, 46.78785705566406, -91.64266967773438, 0,
        46.77958679199219, -93.64263916015625, 0, 46.77131652832031, -95.64260864257812, 0,
        46.7630615234375, -97.64260864257812, 0, 46.754791259765625, -99.64260864257812, 0,
        46.74653625488281, -101.642578125, 0, 46.73826599121094, -103.64254760742188, 0,
        46.72999572753906, -105.64254760742188, 0, 46.72174072265625, -107.64251708984375, 0,
        46.713470458984375, -109.64251708984375, 0, 46.7052001953125, -111.64248657226562, 0,
        46.69694519042969, -113.64248657226562, 0, 46.68867492675781, -115.6424560546875, 0,
        46.680419921875, -117.64242553710938, 0, 46.672149658203125, -119.64242553710938, 0,
        46.66387939453125, -121.64239501953125, 0, 46.65562438964844, -123.64239501953125, 0,
        46.64735412597656, -125.64236450195312, 0, 46.63908386230469, -127.64236450195312, 0,
        46.630828857421875, -129.642333984375, 0, 46.62255859375, -131.64230346679688, 0,
        46.614288330078125, -133.64230346679688, 0, 46.60603332519531, -135.64230346679688, 0,
        46.59776306152344, -137.64227294921875, 0, 46.589508056640625, -139.64224243164062, 0,
        46.58123779296875, -141.64224243164062, 0, 46.572967529296875, -143.6422119140625, 0,
        46.56471252441406, -145.6422119140625, 0, 46.55644226074219, -147.64218139648438, 0,
        46.54817199707031, -149.64218139648438, 0, 46.5399169921875, -151.64215087890625, 0,
        46.531646728515625, -153.64212036132812, 0, 46.52339172363281, -155.64212036132812, 0,
        46.51512145996094, -157.64208984375, 0, 46.50685119628906, -159.64208984375, 0,
        46.49859619140625, -161.64205932617188, 0, 46.490325927734375, -163.64205932617188, 0,
        46.4820556640625, -165.64202880859375, 0, 46.47380065917969, -167.64199829101562, 0,
        46.46553039550781, -169.64199829101562, 0, 46.457275390625, -171.6419677734375, 0,
        46.449005126953125, -173.6419677734375, 0, 46.44073486328125, -175.64193725585938, 0,
        46.43247985839844, -177.64193725585938, 0, 46.42420959472656, -179.64190673828125, 0,
        46.41593933105469, -181.64190673828125, 0, 46.407684326171875, -183.64187622070312, 0,
        46.3994140625, -185.64187622070312, 0, 46.391143798828125, -187.641845703125, 0,
        46.38288879394531, -189.64181518554688, 0, 46.37461853027344, -191.64181518554688, 0,
        46.366363525390625, -193.64178466796875, 0, 46.35809326171875, -195.64178466796875, 0,
        46.349822998046875, -197.64175415039062, 0, 46.34156799316406, -199.64175415039062, 0,
        46.33329772949219, -201.6417236328125, 0, 46.32502746582031, -203.64169311523438, 0,
        46.3167724609375, -205.64169311523438, 0, 46.308502197265625, -207.64166259765625, 0,
        46.30024719238281, -209.64166259765625, 0, 46.29197692871094, -211.64163208007812, 0,
        46.28370666503906, -213.64163208007812, 0, 46.27545166015625, -215.6416015625, 0,
        46.267181396484375, -217.6416015625, 0, 46.2589111328125, -219.64157104492188, 0,
        46.25065612792969, -221.64154052734375, 0, 46.24238586425781, -223.64154052734375, 0,
        46.234130859375, -225.64151000976562, 0, 46.225860595703125, -227.64151000976562, 0,
        46.21759033203125, -229.6414794921875, 0, 46.20933532714844, -231.6414794921875, 0,
        46.20106506347656, -233.64144897460938, 0,
    ],
    intersectied: [
        {
            x: 62.00569823624817,
            y: 33.02523269773313,
            points: [62.00569823624817, 33.02523269773313],
            from: 2080,
            to: 2300,
            _from: 1040,
            _to: 1150,
            length: 2572,
            id: 11,
            positions: [
                {
                    x: 62.00569823624817,
                    y: 33.02523269773313,
                },
            ],
        },
        {
            x: 49.059845773733834,
            y: 164.54472424558926,
            points: [49.059845773733834, 164.54472424558926],
            from: 1432,
            to: 1946,
            _from: 716,
            _to: 973,
            length: 2572,
            id: 10,
            positions: [
                {
                    x: 49.059845773733834,
                    y: 164.54472424558926,
                },
            ],
        },
        {
            x: 55.57055554318648,
            y: 63.71833725646657,
            points: [55.57055554318648, 63.71833725646657],
            from: 1168,
            to: 2048,
            _from: 584,
            _to: 1024,
            length: 2572,
            id: 9,
            positions: [
                {
                    x: 55.57055554318648,
                    y: 63.71833725646657,
                },
            ],
        },
        {
            x: 55.872415738895995,
            y: 137.86032988704613,
            points: [55.872415738895995, 137.86032988704613],
            from: 1050,
            to: 1974,
            _from: 525,
            _to: 987,
            length: 2572,
            id: 8,
            positions: [
                {
                    x: 55.872415738895995,
                    y: 137.86032988704613,
                },
            ],
        },
        {
            x: 115.0267091526589,
            y: 82.92024828101022,
            points: [115.0267091526589, 82.92024828101022],
            from: 962,
            to: 1232,
            _from: 481,
            _to: 616,
            length: 2572,
            id: 7,
            positions: [
                {
                    x: 115.0267091526589,
                    y: 82.92024828101022,
                },
            ],
        },
        {
            x: 115.5531434054436,
            y: 60.33443962999402,
            points: [115.5531434054436, 60.33443962999402],
            from: 940,
            to: 2236,
            _from: 470,
            _to: 1118,
            length: 2572,
            id: 6,
            positions: [
                {
                    x: 115.5531434054436,
                    y: 60.33443962999402,
                },
            ],
        },
        {
            x: 98.15724267912566,
            y: 2.723220278284953,
            points: [98.15724267912566, 2.723220278284953],
            from: 878,
            to: 2128,
            _from: 439,
            _to: 1064,
            length: 2572,
            id: 5,
            positions: [
                {
                    x: 98.15724267912566,
                    y: 2.723220278284953,
                },
            ],
        },
        {
            x: 46.984207453528484,
            y: -44.12638126586622,
            points: [46.984207453528484, -44.12638126586622],
            from: 808,
            to: 2380,
            _from: 404,
            _to: 1190,
            length: 2572,
            id: 4,
            positions: [
                {
                    x: 46.984207453528484,
                    y: -44.12638126586622,
                },
            ],
        },
        {
            x: -8.502311991610696,
            y: 125.45674610854175,
            points: [-8.502311991610696, 125.45674610854175],
            from: 570,
            to: 1500,
            _from: 285,
            _to: 750,
            length: 2572,
            id: 3,
            positions: [
                {
                    x: -8.502311991610696,
                    y: 125.45674610854175,
                },
            ],
        },
        {
            x: -95.83426809215212,
            y: 98.74712480309081,
            points: [-95.83426809215212, 98.74712480309081],
            from: 418,
            to: 1594,
            _from: 209,
            _to: 797,
            length: 2572,
            id: 2,
            positions: [
                {
                    x: -95.83426809215212,
                    y: 98.74712480309081,
                },
            ],
        },
        {
            x: -15.440665835734759,
            y: 51.02378346004499,
            points: [-15.440665835734759, 51.02378346004499],
            from: 322,
            to: 648,
            _from: 161,
            _to: 324,
            length: 2572,
            id: 1,
            positions: [
                {
                    x: -15.440665835734759,
                    y: 51.02378346004499,
                },
            ],
        },
        {
            x: 23.528599283503617,
            y: -48.90892240145587,
            points: [23.528599283503617, -48.90892240145587],
            from: 208,
            to: 784,
            _from: 104,
            _to: 392,
            length: 2572,
            id: 0,
            positions: [
                {
                    x: 23.528599283503617,
                    y: -48.90892240145587,
                },
            ],
        },
    ],
};
