import { Color, DoubleSide, Texture, Vector2, Vector3 } from "three";

export default function () {
    return {
        uniforms: {
            uTime: { type: "f", value: 0 },
            tDiffuse: { type: "t", value: new Texture() },
            tDiffuseMask: { type: "t", value: new Texture() },
            uFreq: { type: "f", value: 0 },
            uBorder: { type: "f", value: 0 },
            uBorderBottom: { type: "f", value: 0 },
            uAmp: { type: "f", value: 0 },
            uSize: { type: "v2", value: new Vector2(0, 0) },
            uResolution: { type: "v2", value: new Vector2() },
            uFrameResolution: { type: "v2", value: new Vector2() },
            uCardResolution: { type: "v2", value: new Vector2() },
            uOpacity: { type: "f", value: 1.0 },
            uColor1: { type: "v3", value: new Color(0xffffff) },
            uColor2: { type: "v3", value: new Color(0x000000) },
            uRotY: { type: "f", value: 0 },
        },

        vertexShader: `
            varying vec2 vUv;
            uniform float uRotY;


            mat4 rotationMatrix(vec3 axis, float angle)
            {
                axis = normalize(axis);
                float s = sin(angle);
                float c = cos(angle);
                float oc = 1.0 - c;
                
                return mat4(oc * axis.x * axis.x + c,           oc * axis.x * axis.y - axis.z * s,  oc * axis.z * axis.x + axis.y * s,  0.0,
                            oc * axis.x * axis.y + axis.z * s,  oc * axis.y * axis.y + c,           oc * axis.y * axis.z - axis.x * s,  0.0,
                            oc * axis.z * axis.x - axis.y * s,  oc * axis.y * axis.z + axis.x * s,  oc * axis.z * axis.z + c,           0.0,
                            0.0,                                0.0,                                0.0,                                1.0);
            }
        
            void main() {
                vUv = uv;

                mat4 rota = rotationMatrix(vec3(0.0,1.0,0.0), uRotY);
                vec3 transformed = position;
                gl_Position = projectionMatrix * modelViewMatrix * rota * vec4( transformed, 1.0 );
            }
        `,

        fragmentShader: `
    
        uniform sampler2D tDiffuse;
        uniform sampler2D tDiffuseMask;
        uniform vec2 uResolution;
        uniform vec2 uFrameResolution;
        uniform vec2 uCardResolution;
        uniform vec2 uSize;
        uniform float uOpacity;
        uniform float uTime;
        uniform float uFreq;
        uniform float uAmp;
        uniform float uBorder;
        uniform float uBorderBottom;
        uniform vec3 uColor1;
        uniform vec3 uColor2;
    
        varying vec2 vUv;
        varying float depth;
    
        void main() {

            /*
                texture fit to cover
            */
            vec2 ratio = vec2(
                min((uFrameResolution.x / uFrameResolution.y) / (uResolution.x / uResolution.y), 1.0),
                min((uFrameResolution.y / uFrameResolution.x) / (uResolution.y / uResolution.x), 1.0));

            vec2 tUv = vec2(
                vUv.x * ratio.x + (1.0 - ratio.x) * 0.5,
                vUv.y * ratio.y + (1.0 - ratio.y) * 0.5);

            vec2 maskUv = tUv;
            /*
                wave
            */
            float powerY = 1.0 - clamp(0.0,1.0,1.0 - vUv.y / 0.5);

            tUv.x += cos((uTime * 0.1 + tUv.y) * uFreq) * sin((uTime * 0.1 + tUv.x) * uFreq) * uAmp * 0.01 * powerY;
            tUv.y += sin((uTime * 0.1 + tUv.x) * uFreq) * cos((uTime * 0.1 + tUv.y) * uFreq) * uAmp * 0.01 * powerY;

            vec4 tColor = texture2D(tDiffuse,tUv);
            vec4 tColorMask = texture2D(tDiffuseMask,maskUv);
            vec4 destColor = tColor;

            /*
                border
            */
            float borderX = (0.5 - uCardResolution.x * 0.5 / uFrameResolution.x) + uBorder / uFrameResolution.x;
            float borderTop =  (0.5 - uCardResolution.y * 0.5 / uFrameResolution.y) + uBorder / uFrameResolution.y;
            float borderBottom = (0.5 - uCardResolution.y * 0.5 / uFrameResolution.y) + uBorderBottom / uFrameResolution.y;

            float uSizeX = uSize.x * 0.5;
            float uSizeY = uSize.y * 0.5;

            if( vUv.x < borderX || vUv.x > 1.0 - borderX) destColor.rgb = vec3(1.0);
            if( vUv.y < borderBottom || vUv.y > 1.0 - borderTop) destColor.rgb = vec3(1.0);

            float opacity = 1.0;

            if(tColorMask.r < 1.0) discard;
            gl_FragColor = vec4(destColor.rgb,opacity);
            
            // gl_FragColor = vec4(destColor.rgb,opacity * uOpacity * (1.0 - tColorMask.r));
            // if (!gl_FrontFacing) gl_FragColor = vec4(1.0,1.0,1.0,1.0);
          }
        `,

        side: DoubleSide,
        transparent: true,
    };
}
