import { Object3D, Raycaster, TextureLoader } from "three";
import BaseWebGL from "./BaseWebGL";
import config, { FlowerDataType } from "./config";
import Flower from "./Flower";
import { gui, gui_vec3 } from "./GUI";
import { SVGLoader } from "./lib/SVGLoader";
import { OrbitControls } from "./lib/OrbitControls";
import DragAndDrop from "./lib/DragAndDrop";
import gsap from "gsap";
import FlowerCard from "./FlowerCard";
import Floor from "./Floor";
import FloorShadow from "./FloorShadow";
import Entrance from "./Entrance";

interface FlowerWorldParams {
    progress: number;
    rotation: number;
    floorOpacity: number;
    floorScrolling: boolean;
}

interface ScrollParams {
    x: number;
    y: number;
    _x: number;
    _y: number;
    oldX: number;
    oldY: number;
    max: number;
    min: number;
    addX: number;
}

export default class FlowerWorld extends BaseWebGL {
    flowers: Array<Flower> = [];
    flowerCards: Array<FlowerCard> = [];
    orbitCtrl: OrbitControls;
    container: Object3D;
    containerInner: Object3D;

    scroll: ScrollParams = {
        x: 0,
        y: 0,
        _x: -1,
        _y: -1,
        oldX: 0,
        oldY: 0,
        max: 0,
        min: 0,
        addX: 2000,
    };

    margin: any = {
        x: 180,
    };

    params: FlowerWorldParams = {
        progress: 0,
        floorOpacity: 1,
        rotation: 10,
        floorScrolling: true,
    };

    loadStatus: any = { progress: 0, _progress: 0, speed: 0.1, isDone: false };

    currentMode: string = "navi";
    currentFlower: Flower;

    entrance: Entrance;
    floor: Floor;
    floorShadow: FloorShadow;

    intersectionObjects: Array<mesh> = [];
    intersected = null;
    raycaster: Raycaster;

    gsap: GSAPAnimation;
    gsapParams: GSAPAnimation;
    isMoving: boolean = true;
    isIntroDone: Boolean = false;

    constructor(containerEl, operationMode: boolean = false) {
        super(containerEl, { render_clearColor: config.colors.gray });
        this.container = new Object3D();
        this.containerInner = new Object3D();
        this.container.add(this.containerInner);
        this.scene.add(this.container);

        this.setEffGui();
        this.resize();

        //@ts-ignore
        config.baseWebgl = this;

        if (0) {
            this.orbitCtrl = new OrbitControls(this.camera, this.renderer.domElement);
            this.orbitCtrl.enabled = false;
            this.orbitCtrl.enableDamping = true;
            this.orbitCtrl.target.set(0, 0.35, 0);
            this.orbitCtrl.update();
        }

        this.raycaster = new Raycaster();

        this.addResizeList("flower", (e) => {
            this.flowerWorldResize();
        });

        this.flowerWorldResize();
        this.scroll.x = this.scroll.max + this.scroll.addX;
        this.scroll._x = this.scroll.x;
        this.updateCardPosition();

        let dragAndDrop = new DragAndDrop(document.body, {
            draggable: false,
            wheelable: true,
            wheelPower: 1,
        });

        dragAndDrop.on(DragAndDrop.EVENT_DRAG_START, (e) => {
            if (this.isMoving || this.currentMode != "navi") return;
            this.scroll.oldX = this.scroll._x;
        });
        dragAndDrop.on(DragAndDrop.EVENT_DRAG_MOVE, (e) => {
            if (this.isMoving || this.currentMode != "navi") return;
            this.scroll.x = this.scroll.oldX + e.distance.x * 2;
        });

        dragAndDrop.on(DragAndDrop.EVENT_MOUSE_WHEEL, (e) => {
            if (this.isMoving || this.currentMode != "navi") return;
            this.scroll.x += -e.wheel.deltaY;
        });
    }

    async setupEntrance() {
        /*
            entrance
        */
        this.entrance = new Entrance();
        await this.entrance.setup(this.stage);
        this.containerInner.add(this.entrance.container);
        this.entrance.container.position.x = this.scroll.max + this.scroll.addX;
        this.entrance.resize(this.stage);
        this.entrance.setGui();

        /*
            floor
        */
        this.floor = new Floor();
        await this.floor.setup(this.stage);
        let floorScale = 1.5;
        this.floor.mesh.renderOrder = -1;
        this.floor.mesh.scale.set(floorScale, floorScale, floorScale);
        this.container.add(this.floor.mesh);
        this.floor.setGui();

        /*
            shadow
        */
        this.floorShadow = new FloorShadow();
        await this.floorShadow.setup(this.stage);
        this.floorShadow.setGui();
        let floorShadowScale = 1.5;
        this.floorShadow.mesh.renderOrder = 100;
        this.floorShadow.mesh.position.z = 0;
        this.floorShadow.mesh.scale.set(floorShadowScale, floorShadowScale, floorShadowScale);
        this.container.add(this.floorShadow.mesh);
    }

    async setup() {
        /*
            flower and cards
        */
        await new Promise<void>((resolve) => {
            config.flowers.forEach(async (item: FlowerDataType, i) => {
                let flower = new Flower(item, i, this.stage, this);
                flower.setup(this.stage);
                this.flowers.push(flower);
                flower.drawIn();

                let flowerCard = new FlowerCard(item, i, flower.rtt.texture, this);
                flowerCard.group.renderOrder = i + 1;
                flowerCard.group.position.z = i;
                this.containerInner.add(flowerCard.group);
                this.intersectionObjects.push(flowerCard.cardHitBox);
                this.flowerCards.push(flowerCard);

                flowerCard.group.position.x = i * this.stage.width;
                if (i == config.flowers.length - 1) resolve();
            });
        });

        this.currentFlower = this.flowers[0];
        this.updateDrawTexture(this.currentFlower.rtt.texture, 0);

        this.resize();
    }

    async loadForEntrance(progress_cb) {
        const loaders: Array<Promise<void>> = [];

        /*
            textures
        */
        const textures = config.entrance.textures;
        for (let key in textures) {
            let promise = new Promise<void>((resolve) => {
                new TextureLoader().load(textures[key], (t) => {
                    textures[key] = t;
                    resolve();
                });
            });
            loaders.push(promise);
        }

        /*
            texture load Counting
        */
        let count = 0;
        return new Promise<void>((resolve) => {
            loaders.forEach((promise) => {
                promise.then(() => {
                    count++;
                    let p = count / loaders.length;
                    progress_cb(p);
                    if (p == 1) {
                        resolve();
                    }
                });
            });
        });
    }

    async load(progress_cb) {
        const loaders: Array<Promise<void>> = [];
        const loadList = config.flowers;
        const svgLoader = new SVGLoader();

        /*
            flower assets , textures
        */
        loadList.forEach((item, i) => {
            let promise = new Promise<void>((resolve) => {
                svgLoader.load(item.url, (data) => {
                    data.paths.forEach((path) => {
                        let id = path.userData.node.id;
                    });

                    loadList[i].paths = data.paths;
                    loadList[i].svg = data.xml;

                    resolve();
                });
            });
            loaders.push(promise);

            for (let key in item.textures) {
                let promise = new Promise<void>((resolve) => {
                    new TextureLoader().load(item.textures[key], (t) => {
                        item.textures[key] = t;
                        resolve();
                    });
                });
                loaders.push(promise);
            }
        });

        /*
            textures
        for (let key in config.textures) {
            let promise = new Promise<void>((resolve) => {
                new TextureLoader().load(config.textures[key], (t) => {
                    config.textures[key] = t;
                    resolve();
                });
            });
            loaders.push(promise);
        }
        */
        /*
            texture load Counting
        */
        let count = 0;
        return new Promise<void>((resolve) => {
            loaders.forEach((promise) => {
                promise.then(() => {
                    count++;
                    let p = count / loaders.length;
                    progress_cb(p);
                    this.loadStatus.progress = p;
                    if (p == 1) {
                        resolve();
                    }
                });
            });
        });
    }

    async lazyLoading() {
        /*
            check loading
        */
        return new Promise<void>((resolve) => {
            const checking = () => {
                if (!this.loadStatus.isDone) {
                    this.loadStatus._progress +=
                        (this.loadStatus.progress - this.loadStatus._progress) *
                        this.loadStatus.speed;
                    if (
                        this.loadStatus.progress == 1 &&
                        this.loadStatus.progress - this.loadStatus._progress <= 0.02
                    ) {
                        this.loadStatus._progress = this.loadStatus.progress;
                        this.loadStatus.isDone = true;
                    }
                    if (this.entrance) this.entrance.loading(this.loadStatus._progress);
                    requestAnimationFrame(checking.bind(this));
                } else {
                    resolve();
                }
            };
            requestAnimationFrame(checking.bind(this));
        });
    }

    async showIntro() {
        await this.entrance.fadeIn();
        await this.moveTo(0);
        this.isIntroDone = true;
    }

    moveTo(to = 0, duration = 2) {
        return new Promise<void>((resolve) => {
            if (this.gsap) this.gsap.kill();
            this.isMoving = true;
            this.gsap = gsap.to(this.scroll, {
                x: to,
                duration: duration,
                ease: "Power4.easeInOut",
                onComplete: () => {
                    this.isMoving = false;
                    resolve();
                },
            });
        });
    }

    getNaviPosition(id) {
        return id * (config.cardOption.size.width + this.margin.x);
        // return (this.scroll.max) * (id / this.flowerCards.length);
    }

    flowerWorldResize() {
        this.flowers.forEach((flower: Flower) => {
            flower.resize(this.stage);
            flower.camera.position.z = this.screenZ;
            flower.camera.aspect = this.stage.aspect;
            flower.camera.updateProjectionMatrix();
        });

        this.flowerCards.forEach((flowerCard: FlowerCard) => {
            flowerCard.resize(this.stage);
            flowerCard.camera.position.z = this.screenZ;
            flowerCard.camera.aspect = this.stage.aspect;
            flowerCard.camera.updateProjectionMatrix();
        });

        this.flowerCards.forEach((flowerCard: FlowerCard, i) => {
            flowerCard.resize(this.stage);
            flowerCard.group.position.x = (flowerCard.size.width + this.margin.x) * i;
        });

        this.scroll.max =
            (config.cardOption.size.width + this.margin.x) * (config.flowers.length - 1);
        if (this.flowerCards.length) {
        }

        if (this.floor) {
            this.floor.resize(this.stage);
        }

        if (this.floorShadow) {
            this.floorShadow.resize(this.stage);
        }

        if (this.entrance) {
            this.entrance.container.position.x = this.scroll.max + this.scroll.addX;
            this.entrance.resize(this.stage);
        }
    }

    setGui() {
        gui_vec3(gui, this.camera.position, {
            folder: "📷camera",
            range: 10000,
            threshold: 0.1,
        }).close();

        let g = gui.addFolder("🥡container").close();

        gui_vec3(g, this.container.position, {
            folder: "position",
            range: 5000,
            threshold: 0.1,
        });

        gui_vec3(g, this.container.rotation, {
            folder: "rotation",
            range: Math.PI * 2,
            threshold: Math.PI / 180,
        }).close();

        const flowerGui = gui.addFolder("💐flowers").close();
        const cardGui = gui.addFolder("🃏cards").close();

        this.flowerCards.forEach((FlowerCard) => {
            FlowerCard.setGui(cardGui);
        });

        this.flowers.forEach((flower) => {
            flower.setGui(flowerGui);
        });

        // gui.add({ cardDepthTest: false }, "cardDepthTest").onChange((value) => {
        //     this.flowerCards.forEach((flowerCard: FlowerCard) => {
        //         //@ts-ignore
        //         flowerCard.mesh.material.depthTest = value;
        //     });
        // });
        // gui.add({ cardDepthWrite: false }, "cardDepthWrite").onChange((value) => {
        //     this.flowerCards.forEach((flowerCard: FlowerCard) => {
        //         //@ts-ignore
        //         flowerCard.mesh.material.depthWrite = value;
        //     });
        // });

        gui.add(this, "isRenderSceneMain");
        let list: Array<number> = [];
        this.flowers.forEach((flower, i) => {
            list.push(i);
        });
        gui.add({ currentFlower: 0 }, "currentFlower", list).onChange((value) => {
            this.currentFlower = this.flowers[value];
            this.updateDrawTexture(this.currentFlower.rtt.texture, value);
        });
        gui.add(this.params, "rotation", 0, 100).listen();
        gui.add(
            {
                naviMode: async () => {
                    if (this.currentMode == "navi") return;
                    this.currentMode = "navi";
                    let id = this.currentFlower.id;
                    let card = this.flowerCards[id];
                    let flower: Flower = this.flowers[id];

                    gsap.to(flower.group.position, {
                        z: 0,
                        duration: 2,
                        ease: "Power4.easeInOut",
                    });

                    // gsap.to(this.effectParams, {
                    //     uTransition: 0,
                    //     duration: 2,
                    //     ease: "Power4.easeInOut",
                    // });

                    // gsap.to(card.group.position, {
                    //     z: 0,
                    //     duration: 3,
                    //     ease: "Power4.easeInOut",
                    // });

                    // gsap.to(this.container.position, {
                    //     z: 0,
                    //     duration: 1,
                    //     ease: "Power4.easeInOut",
                    // });

                    flower.changeExtend(0, 1);
                    await card.cardOn(1, 0);

                    this.flowerCards.forEach((card: FlowerCard, i) => {
                        if (i == id) {
                        } else {
                            let dist = Math.abs(id - i);
                            card.cardShow(0.8, dist * 0.07);
                        }
                    });

                    // card.group.renderOrder = id;
                    if (this.gsapParams) this.gsapParams.kill();
                    this.gsapParams = gsap.to(this.params, { rotation: 10, duration: 1 });
                },
            },
            "naviMode"
        );
        gui.add(
            {
                topMode: async () => {
                    if (this.currentMode == "top") return;
                    this.currentMode = "top";
                    let id = this.currentFlower.id;
                    let card = this.flowerCards[id];
                    let flower: Flower = this.flowers[id];
                    // card.group.renderOrder = 1;
                    if (this.gsapParams) this.gsapParams.kill();
                    this.gsapParams = gsap.to(this.params, { rotation: 0, duration: 1 });

                    let naviX = this.getNaviPosition(id);
                    if (naviX != this.scroll.x) {
                        await this.moveTo(this.getNaviPosition(id), 0.8);
                    }

                    this.flowerCards.forEach((card: FlowerCard, i) => {
                        if (i == id) {
                        } else {
                            let dist = Math.abs(id - i);
                            card.cardHide(1, dist * 0.1);
                        }
                    });

                    await new Promise<void>((resolve) => {
                        setTimeout(() => {
                            resolve();
                        }, 500);
                    });

                    card.cardOff(1, 0);
                    flower.changeExtend(1, 1);
                },
            },
            "topMode"
        );

        // let gFloor = gui.addFolder("floor");
        // gFloor.add(this.params, "floorScrolling");
        // gFloor.add(this.params, "floorOpacity", 0, 1, 0.01).onChange((value) => {
        //     (this.floorMesh.material as MeshBasicMaterial).opacity = value;
        // });

        // gFloor.add(this, "bgTexture", config.floor.textures).onChange((value) => {
        //     let texture = new TextureLoader().load(value, (t) => {
        //         t.wrapS = RepeatWrapping;
        //         t.wrapT = RepeatWrapping;
        //         let mat = this.floorMesh.material as MeshBasicMaterial;
        //         mat.map = t;
        //         mat.needsUpdate = true;
        //     });
        // });

        // this.entrance.setGui();
    }

    update() {
        if (this.orbitCtrl) this.orbitCtrl.update();

        if (this.flowerCards.length) {
            this.flowers.forEach((flower: Flower, i) => {
                let flowerCard = this.flowerCards[i];
                flowerCard.update(this.mouse);
                flower.update(
                    this.mouse,
                    1,
                    // 1 - this.effectParams.uTransition,
                    this.container,
                    flowerCard.group.position,
                    flowerCard.group.rotation
                );
            });
        }

        this.updateCardPosition();

        // this.container.position.z = this.effectParams.uTransition * this.cardFitZ;

        /*

        */

        if (this.currentMode == "navi") {
        }

        this.container.rotation.x +=
            ((this.mouse._ry * this.params.rotation * Math.PI) / 180 - this.container.rotation.x) *
            0.1;
        this.container.rotation.y +=
            ((this.mouse._rx * this.params.rotation * Math.PI) / 180 - this.container.rotation.y) *
            0.1;

        if (this.entrance) {
            this.entrance.update();
        }
        // this.detectingIntersection();
    }

    updateCardPosition() {
        let scrollMax = this.scroll.max;
        if (!this.isIntroDone) {
            scrollMax += this.scroll.addX;
        }
        scrollMax += this.scroll.addX;

        if (this.scroll.x > scrollMax) {
            this.scroll.x = scrollMax;
        }

        if (this.scroll.x < this.scroll.min) {
            this.scroll.x = this.scroll.min;
        }

        this.scroll._x += (this.scroll.x - this.scroll._x) * 0.1;
        let dist = Math.abs(this.scroll._x - this.scroll.x);
        if (dist < 0.01) {
            this.scroll._x = this.scroll.x;
        }

        if (this.floor) {
            let textureX = this.scroll._x / this.stage.width / this.floor.mesh.scale.x;
            this.floor.update(textureX);
        }

        this.containerInner.position.x = -this.scroll._x;
        this.flowerCards.forEach((card: FlowerCard) => {
            card.update(this.scroll._x);
        });

        let scrollProgress = this.scroll._x / this.scroll.max;

        if (this.floorShadow) {
            this.floorShadow.update(this.scroll._x, scrollProgress);
        }
    }

    detectingIntersection() {
        this.raycaster.setFromCamera({ x: this.mouse.rx, y: this.mouse.ry }, this.camera);

        const intersects = this.raycaster.intersectObjects(this.intersectionObjects, false);

        if (intersects.length > 0) {
            if (this.intersected != intersects[0].object) {
                // if (this.intersected) INTERSECTED.material.emissive.setHex(this.intersected.currentHex);

                //@ts-ignore
                this.intersected = intersects[0].object;

                //@ts-ignore
                // this.intersected.material.color = new Color(0, 1, 0);

                //@ts-ignore
                this.activeFlowerCard(this.intersected._id);
                // INTERSECTED.currentHex = INTERSECTED.material.emissive.getHex();
                // INTERSECTED.material.emissive.setHex(0xff0000);
            }
        } else {
            if (this.intersected) {
                // this.intersected.material.opacity = 1;
                //@ts-ignore
                // this.intersected.material.color = new Color(1, 0, 0);

                //@ts-ignore
                this.inActiveFlowerCard(this.intersected._id);
            }

            this.intersected = null;
        }
    }
    activeFlowerCard(id: number) {
        let tg = this.flowers[id];
        if (tg.gsap) tg.gsap.kill();
        gsap.to(tg.cardGroup.position, {
            z: 50,
            duration: 0.8,
            ease: "Power3.easeInOut",
        });
    }
    inActiveFlowerCard(id: number) {
        let tg = this.flowers[id];
        if (tg.gsap) tg.gsap.kill();
        tg.gsap = gsap.to(tg.cardGroup.position, {
            z: 0,
            duration: 1,
            ease: "Power4.easeInOut",
        });
    }
}
