import gsap from "gsap";
import {
    DoubleSide,
    Group,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PlaneGeometry,
    TextureLoader,
} from "three";
import { stageSizeType } from "./BaseWebGL";
import config from "./config";
import FlowerShadow from "./FlowerShadow";
import { gui } from "./GUI";
import PathLine from "./lib/PathLine";
import { SVGLoader } from "./lib/SVGLoader";

export default class Entrance {
    container: Object3D;
    stageSize: stageSizeType;
    itemSize: any = {
        logo: {
            width: 134,
            height: 58,
        },
        title: {
            width: 458,
            height: 184,
        },
        flowerLine: {
            width: 100,
            height: 100,
        },
    };
    logo: Mesh;
    title: Mesh;
    flowerShadow: FlowerShadow;
    isReady: Boolean = false;
    config: any = config.entrance; //Object.assign({}, config.entrance);
    params: any = {};
    flowerPathLine: PathLine;
    progress: number = 0;
    constructor() {
        this.container = new Object3D();
    }

    async setup(stageSize) {
        this.stageSize = Object.assign(stageSize, stageSize);
        // return new Promise<void>(async (resolve) => {
        // let url = "./assets/images/lionsgoodnews2023.svg";
        // let meshGroup = await svgShapeToMesh(url);
        // this.container.add(meshGroup);

        let geometry = new PlaneGeometry(this.itemSize.logo.width, this.itemSize.logo.height, 1, 1);
        this.logo = new Mesh(
            geometry,
            new MeshBasicMaterial({
                //@ts-ignore
                map: config.entrance.textures.cannesLogo,
                transparent: true,
            })
        );
        this.container.add(this.logo);

        /*
            flower shadow
        */
        //@ts-ignore
        let flowerT: Texture = config.entrance.textures.flowerShadow;
        this.flowerShadow = new FlowerShadow();
        this.flowerShadow.setup(flowerT, stageSize.width, stageSize.height);
        this.flowerShadow.mesh.renderOrder = 0;
        this.flowerShadow.mesh.scale.set(1.5, 1.5, 1.5);
        this.container.add(this.flowerShadow.mesh);

        /*
            title
        */
        this.title = new Mesh(
            new PlaneGeometry(this.itemSize.title.width, this.itemSize.title.height, 1, 1),
            new MeshBasicMaterial({
                //@ts-ignore
                map: config.entrance.textures.lionsgoodnews,
                transparent: true,
                opacity: 0,
            })
        );

        this.title.renderOrder = 1;
        this.container.add(this.title);

        /*
            flower Line
        */

        await this.makeFlowerLine();

        this.isReady = true;
        this.resize();
    }

    async makeFlowerLine() {
        return new Promise<void>((resolve) => {
            const svgLoader = new SVGLoader();
            svgLoader.load(config.flowers[0].url, (data) => {
                let flowerSize = data.xml.viewBox.animVal;
                this.itemSize.flowerLine = flowerSize;
                data.paths.forEach((path: any) => {
                    let subPath = path.subPaths[0];
                    let positions: Array<Vector3> = [];
                    let points: Array<number> = [];
                    if (path.userData.node.id == "line") {
                        let pahNode = path.userData.node;
                        let length = pahNode.getTotalLength();
                        for (let i = 0, n = length; i < n; i += 2) {
                            let ratio = i / (n - 1);
                            let pointId = ratio * length;

                            let v2 = pahNode.getPointAtLength(i);
                            let x = v2.x - Number(flowerSize.width * 0.5);
                            let y = v2.y * -1 + flowerSize.height * 0.5;
                            points.push(x, y, 0);
                        }

                        this.flowerPathLine = new PathLine("flowerLine", points, {
                            lineWidth: 3,
                            color: 0x000000,
                            opacity: 0.2,
                        });
                        this.flowerPathLine.mesh.renderOrder = 1.5;
                        this.flowerPathLine.update(0);
                        this.container.add(this.flowerPathLine.mesh);
                    }
                });
                resolve();
            });
        });
    }

    async fadeIn() {
        return new Promise<void>((resolve) => {
            // this.flowerPathLine.drawTo(2, 3.5);
            gsap.to(this.flowerPathLine.mesh.material, { opacity: 0, duration: 1 });
            gsap.to(this.title.material, {
                opacity: 1,
                duration: 1.5,
                delay: 1,
                ease: "Power3.easeInOut",
                onComplete: () => {
                    resolve();
                },
            });
        });
    }

    resize(stageSize: stageSizeType = this.stageSize) {
        if (!this.isReady) return;
        this.stageSize = Object.assign(this.stageSize, stageSize);
        this.logo.position.y = stageSize.height * 0.5; // move to screen top

        this.logo.position.y += -this.itemSize.logo.height * 0.5; // fit to screen top
        if (stageSize.width >= config.mediaQuery.md) {
            this.logo.position.y -= this.config.logo.position.md.y;
        } else {
            this.logo.position.y -= this.config.logo.position.base.y;
        }

        if (this.flowerShadow) {
            this.flowerShadow.resize(stageSize);
        }

        if (this.flowerPathLine) {
            let flowerScale = this.stageSize.height / this.itemSize.flowerLine.height;
            let scale = 0.8;
            flowerScale *= scale;
            this.flowerPathLine.mesh.scale.set(flowerScale, flowerScale, 1);
            this.flowerPathLine.mesh.position.x = -50;
            let y = stageSize.height * (1 - scale) * 0.5;
            this.flowerPathLine.mesh.position.y = -(y + 50);
        }
    }

    update() {
        if (this.flowerShadow) {
            this.flowerShadow.update();
        }
    }

    loading(progress) {
        this.progress = progress;
        this.flowerShadow.params.opacity = this.progress * 0.5;
        this.flowerShadow.updateUniforms();
        this.flowerPathLine.update(progress);
    }

    setGui(_gui = gui) {
        let g = gui.addFolder("🚪entrance").close();
        this.flowerShadow.setGui(g);
        this.flowerPathLine.setGui(g);
    }
}
