export default {
    points: [
        -4, -233.60000610351562, 0, -6, -233.60000610351562, 0, -8, -233.60000610351562, 0, -10,
        -233.60000610351562, 0, -12, -233.60000610351562, 0, -14, -233.60000610351562, 0, -16,
        -233.60000610351562, 0, -18, -233.60000610351562, 0, -20, -233.60000610351562, 0, -22,
        -233.60000610351562, 0, -24, -233.60000610351562, 0, -26, -233.60000610351562, 0, -28,
        -233.60000610351562, 0, -28.699989318847656, -232.30001831054688, 0, -28.699989318847656,
        -230.30001831054688, 0, -28.699989318847656, -228.30001831054688, 0, -28.699989318847656,
        -226.30001831054688, 0, -28.699989318847656, -224.30001831054688, 0, -28.699989318847656,
        -222.30001831054688, 0, -28.699989318847656, -220.30001831054688, 0, -28.699989318847656,
        -218.30001831054688, 0, -28.699989318847656, -216.30001831054688, 0, -28.699989318847656,
        -214.30001831054688, 0, -28.699989318847656, -212.30001831054688, 0, -28.699989318847656,
        -210.30001831054688, 0, -28.699989318847656, -208.30001831054688, 0, -28.699989318847656,
        -206.30001831054688, 0, -28.699996948242188, -204.30001831054688, 0, -28.699996948242188,
        -202.30001831054688, 0, -28.699996948242188, -200.30001831054688, 0, -28.699996948242188,
        -198.30001831054688, 0, -28.699996948242188, -196.30001831054688, 0, -28.699996948242188,
        -194.30001831054688, 0, -28.699996948242188, -192.30001831054688, 0, -28.699996948242188,
        -190.30001831054688, 0, -28.699996948242188, -188.30001831054688, 0, -28.699996948242188,
        -186.30001831054688, 0, -28.699996948242188, -184.30001831054688, 0, -28.699996948242188,
        -182.30001831054688, 0, -28.699996948242188, -180.30001831054688, 0, -28.699996948242188,
        -178.30001831054688, 0, -28.699996948242188, -176.30001831054688, 0, -28.699996948242188,
        -174.30001831054688, 0, -28.699996948242188, -172.30001831054688, 0, -28.699996948242188,
        -170.30001831054688, 0, -28.699996948242188, -168.30001831054688, 0, -28.699996948242188,
        -166.30001831054688, 0, -28.699996948242188, -164.30001831054688, 0, -28.699996948242188,
        -162.30001831054688, 0, -28.699996948242188, -160.30001831054688, 0, -28.699996948242188,
        -158.30001831054688, 0, -28.699996948242188, -156.30001831054688, 0, -28.699996948242188,
        -154.30001831054688, 0, -28.699996948242188, -152.30001831054688, 0, -28.699996948242188,
        -150.30001831054688, 0, -28.699996948242188, -148.30001831054688, 0, -28.699996948242188,
        -146.30001831054688, 0, -28.699996948242188, -144.30001831054688, 0, -28.699996948242188,
        -142.30001831054688, 0, -28.699996948242188, -140.30001831054688, 0, -28.699996948242188,
        -138.30001831054688, 0, -28.699996948242188, -136.30001831054688, 0, -28.699996948242188,
        -134.30001831054688, 0, -28.699996948242188, -132.30001831054688, 0, -28.699996948242188,
        -130.30001831054688, 0, -28.699996948242188, -128.30001831054688, 0, -28.699996948242188,
        -126.30001831054688, 0, -28.699996948242188, -124.30001831054688, 0, -28.699996948242188,
        -122.30001831054688, 0, -28.699996948242188, -120.30001831054688, 0, -28.699996948242188,
        -118.30001831054688, 0, -28.6959228515625, -116.30001831054688, 0, -28.691848754882812,
        -114.30001831054688, 0, -28.687774658203125, -112.30001831054688, 0, -28.68370819091797,
        -110.30001831054688, 0, -28.67963409423828, -108.30001831054688, 0, -28.675559997558594,
        -106.30001831054688, 0, -28.671485900878906, -104.300048828125, 0, -28.66741943359375,
        -102.300048828125, 0, -28.663345336914062, -100.300048828125, 0, -28.659271240234375,
        -98.300048828125, 0, -28.65520477294922, -96.300048828125, 0, -28.65113067626953,
        -94.300048828125, 0, -28.647056579589844, -92.300048828125, 0, -28.642982482910156,
        -90.30007934570312, 0, -28.638916015625, -88.30007934570312, 0, -28.634841918945312,
        -86.30007934570312, 0, -28.630767822265625, -84.30007934570312, 0, -28.626693725585938,
        -82.30007934570312, 0, -28.62262725830078, -80.30007934570312, 0, -28.618553161621094,
        -78.30007934570312, 0, -28.614479064941406, -76.30010986328125, 0, -28.61040496826172,
        -74.30010986328125, 0, -28.606338500976562, -72.30010986328125, 0, -28.602264404296875,
        -70.30010986328125, 0, -28.598365783691406, -68.30010986328125, 0, -28.594688415527344,
        -66.30010986328125, 0, -28.591018676757812, -64.30010986328125, 0, -28.58734130859375,
        -62.30010986328125, 0, -28.583663940429688, -60.30010986328125, 0, -28.579994201660156,
        -58.300140380859375, 0, -28.576316833496094, -56.300140380859375, 0, -28.57263946533203,
        -54.300140380859375, 0, -28.5689697265625, -52.300140380859375, 0, -28.565292358398438,
        -50.300140380859375, 0, -28.561614990234375, -48.300140380859375, 0, -28.557945251464844,
        -46.300140380859375, 0, -28.55426788330078, -44.300140380859375, 0, -28.55059051513672,
        -42.300140380859375, 0, -28.546920776367188, -40.3001708984375, 0, -28.543243408203125,
        -38.3001708984375, 0, -28.539566040039062, -36.3001708984375, 0, -28.53589630126953,
        -34.3001708984375, 0, -28.53221893310547, -32.3001708984375, 0, -28.529014587402344,
        -30.3001708984375, 0, -28.525978088378906, -28.3001708984375, 0, -28.52294158935547,
        -26.3001708984375, 0, -28.51990509033203, -24.3001708984375, 0, -28.516868591308594,
        -22.3001708984375, 0, -28.513832092285156, -20.300186157226562, 0, -28.51079559326172,
        -18.300186157226562, 0, -28.50811004638672, -16.300186157226562, 0, -28.50634765625,
        -14.300186157226562, 0, -28.50458526611328, -12.300186157226562, 0, -28.502822875976562,
        -10.300186157226562, 0, -28.501060485839844, -8.300186157226562, 0, -28.500633239746094,
        -6.3001861572265625, 0, -28.504478454589844, -4.3001861572265625, 0, -28.51390838623047,
        -2.30023193359375, 0, -28.531211853027344, -0.3003082275390625, 0, -28.558258056640625,
        1.69952392578125, 0, -28.597091674804688, 3.6991424560546875, 0, -28.649978637695312,
        5.69842529296875, 0, -28.719146728515625, 7.6972198486328125, 0, -28.80664825439453,
        9.6953125, 0, -28.914222717285156, 11.6923828125, 0, -29.043182373046875,
        13.688217163085938, 0, -29.19434356689453, 15.6824951171875, 0, -29.368003845214844,
        17.674911499023438, 0, -29.564193725585938, 19.665252685546875, 0, -29.782180786132812,
        21.653335571289062, 0, -30.020774841308594, 23.6390380859375, 0, -30.278579711914062,
        25.622344970703125, 0, -30.554931640625, 27.603134155273438, 0, -30.847564697265625,
        29.58160400390625, 0, -31.155685424804688, 31.557723999023438, 0, -31.477256774902344,
        33.531707763671875, 0, -31.81139373779297, 35.50358581542969, 0, -32.156829833984375,
        37.47352600097656, 0, -32.512237548828125, 39.44169616699219, 0, -32.87609100341797,
        41.40830993652344, 0, -33.28376770019531, 43.366241455078125, 0, -33.750091552734375,
        45.31101989746094, 0, -34.263919830322266, 47.24383544921875, 0, -34.817161560058594,
        49.16578674316406, 0, -35.403987884521484, 51.07771301269531, 0, -36.019569396972656,
        52.980621337890625, 0, -36.660335540771484, 54.87516784667969, 0, -37.323516845703125,
        56.761993408203125, 0, -38.00632858276367, 58.64183044433594, 0, -38.70712661743164,
        60.51502990722656, 0, -39.42432403564453, 62.38197326660156, 0, -40.15627670288086,
        64.24324035644531, 0, -40.90172576904297, 66.09912109375, 0, -41.66010284423828,
        67.94973754882812, 0, -42.43016052246094, 69.79556274414062, 0, -43.21104049682617,
        71.63679504394531, 0, -44.00255584716797, 73.47352600097656, 0, -44.803550720214844,
        75.30610656738281, 0, -45.61396408081055, 77.13455200195312, 0, -46.43304443359375,
        78.95912170410156, 0, -47.2605094909668, 80.7799072265625, 0, -48.096038818359375,
        82.59703063964844, 0, -48.93917465209961, 84.41061401367188, 0, -49.78988265991211,
        86.22065734863281, 0, -50.64759826660156, 88.02740478515625, 0, -51.512569427490234,
        89.8306884765625, 0, -52.38420486450195, 91.63075256347656, 0, -53.26259231567383,
        93.42753601074219, 0, -54.147613525390625, 95.2210693359375, 0, -55.03904724121094,
        97.01141357421875, 0, -55.937042236328125, 98.79847717285156, 0, -56.841548919677734,
        100.582275390625, 0, -57.752742767333984, 102.36260986328125, 0, -58.67001724243164,
        104.139892578125, 0, -59.5942497253418, 105.91352844238281, 0, -60.525390625,
        107.68352508544922, 0, -61.46373748779297, 109.4497299194336, 0, -62.409629821777344,
        111.2119140625, 0, -63.3635139465332, 112.96976470947266, 0, -64.3260383605957,
        114.72293853759766, 0, -65.2983512878418, 116.47065734863281, 0, -66.28160095214844,
        118.2122573852539, 0, -67.27786445617676, 119.94648742675781, 0, -68.25294876098633,
        121.69242095947266, 0, -69.17373847961426, 123.46782684326172, 0, -70.07263946533203,
        125.25444030761719, 0, -70.9588565826416, 127.04737091064453, 0, -71.8365592956543,
        128.844482421875, 0, -72.70795059204102, 130.64466857910156, 0, -73.57431030273438,
        132.44728088378906, 0, -74.4365005493164, 134.25189971923828, 0, -75.29473114013672,
        136.05841827392578, 0, -76.14980697631836, 137.86638641357422, 0, -77.00127029418945,
        139.67610931396484, 0, -77.80962753295898, 141.5052719116211, 0, -78.57754325866699,
        143.3519744873047, 0, -79.31193733215332, 145.21221923828125, 0, -80.01117134094238,
        147.08596801757812, 0, -80.67334938049316, 148.97313690185547, 0, -81.2964038848877,
        150.8735580444336, 0, -81.87824249267578, 152.78702545166016, 0, -82.41670894622803,
        154.7131118774414, 0, -82.90941333770752, 156.65139770507812, 0, -83.35397434234619,
        158.60131072998047, 0, -83.74770259857178, 160.56211853027344, 0, -84.08791446685791,
        162.53289794921875, 0, -84.37196636199951, 164.51255798339844, 0, -84.59698104858398,
        166.4997787475586, 0, -84.76008796691895, 168.49303436279297, 0, -84.85834789276123,
        170.49053192138672, 0, -84.8889970779419, 172.4902000427246, 0, -84.84895896911621,
        174.48968124389648, 0, -84.73574352264404, 176.48636627197266, 0, -84.54668426513672,
        178.47726821899414, 0, -84.27955532073975, 180.45923233032227, 0, -83.93222236633301,
        182.42868423461914, 0, -83.50293064117432, 184.38190460205078, 0, -82.99057292938232,
        186.31501388549805, 0, -82.39415454864502, 188.22385025024414, 0, -81.71341705322266,
        190.104248046875, 0, -80.9483871459961, 191.95196533203125, 0, -80.09967803955078,
        193.76274871826172, 0, -79.1684513092041, 195.53250122070312, 0, -78.15618515014648,
        197.25720596313477, 0, -77.06463050842285, 198.93285369873047, 0, -75.89597702026367,
        200.55566787719727, 0, -74.65243911743164, 202.12182426452637, 0, -73.33621025085449,
        203.6274356842041, 0, -71.94931030273438, 205.0681552886963, 0, -70.49310493469238,
        206.43876457214355, 0, -68.96781539916992, 207.73203659057617, 0, -67.37017059326172,
        208.93456268310547, 0, -65.6890983581543, 210.0172119140625, 0, -63.965415954589844,
        211.03138732910156, 0, -62.20428466796875, 211.9789218902588, 0, -60.40659713745117,
        212.85515594482422, 0, -58.5738525390625, 213.65542030334473, 0, -56.70791244506836,
        214.37481307983398, 0, -54.81135559082031, 215.00914192199707, 0, -52.88722229003906,
        215.55412483215332, 0, -50.9391975402832, 216.0061206817627, 0, -48.97130584716797,
        216.36195755004883, 0, -46.98815155029297, 216.61935234069824, 0, -44.99458312988281,
        216.77670860290527, 0, -42.99560546875, 216.83342361450195, 0, -40.996315002441406,
        216.78950119018555, 0, -39.00164794921875, 216.64617347717285, 0, -37.01642608642578,
        216.40526962280273, 0, -35.04502487182617, 216.0692081451416, 0, -33.09154510498047,
        215.6412124633789, 0, -31.159584045410156, 215.1246395111084, 0, -29.252296447753906,
        214.523286819458, 0, -27.37236785888672, 213.84114456176758, 0, -25.52215576171875,
        213.08212280273438, 0, -23.70355987548828, 212.2501163482666, 0, -21.918296813964844,
        211.34883499145508, 0, -20.16778564453125, 210.38168907165527, 0, -18.453453063964844,
        209.35186767578125, 0, -16.776512145996094, 208.2621955871582, 0, -15.138504028320312,
        207.11478805541992, 0, -13.541389465332031, 205.9111557006836, 0, -11.988029479980469,
        204.65148162841797, 0, -10.483604431152344, 203.33391761779785, 0, -9.04193115234375,
        201.94805145263672, 0, -7.641960144042969, 200.51985931396484, 0, -6.2672119140625,
        199.06730270385742, 0, -4.922248840332031, 197.58710098266602, 0, -3.6085586547851562,
        196.07910919189453, 0, -2.327606201171875, 194.54323959350586, 0, -1.0810470581054688,
        192.9792594909668, 0, 0.12973785400390625, 191.38745498657227, 0, 1.3030014038085938,
        189.76780319213867, 0, 2.4372177124023438, 188.12059783935547, 0, 3.5307235717773438,
        186.44609451293945, 0, 4.581855773925781, 184.7446632385254, 0, 5.589012145996094,
        183.0168342590332, 0, 6.550666809082031, 181.26324462890625, 0, 7.464988708496094,
        179.4845428466797, 0, 8.330665588378906, 177.68166732788086, 0, 9.146125793457031,
        175.85552978515625, 0, 9.909912109375, 174.00724029541016, 0, 10.620758056640625,
        172.13788223266602, 0, 11.277359008789062, 170.24879837036133, 0, 11.87860107421875,
        168.34141540527344, 0, 12.423370361328125, 166.41710662841797, 0, 12.910789489746094,
        164.47747802734375, 0, 13.340011596679688, 162.52411651611328, 0, 13.710479736328125,
        160.5588150024414, 0, 14.021514892578125, 158.58326721191406, 0, 14.272659301757812,
        156.5991668701172, 0, 14.463638305664062, 154.60839080810547, 0, 14.594078063964844,
        152.6127166748047, 0, 14.664100646972656, 150.61402893066406, 0, 14.673446655273438,
        148.6141357421875, 0, 14.621833801269531, 146.6148681640625, 0, 14.509468078613281,
        144.6181182861328, 0, 14.336090087890625, 142.62571716308594, 0, 14.101547241210938,
        140.63961791992188, 0, 13.805084228515625, 138.6617889404297, 0, 13.445953369140625,
        136.69438934326172, 0, 13.022903442382812, 134.7397232055664, 0, 12.533126831054688,
        132.8007354736328, 0, 11.972068786621094, 130.88117218017578, 0, 11.328804016113281,
        128.98768615722656, 0, 10.54010009765625, 127.1512680053711, 0, 9.651954650878906,
        125.35932922363281, 0, 8.735763549804688, 123.58155822753906, 0, 7.79425048828125,
        121.8170166015625, 0, 6.829994201660156, 120.06486511230469, 0, 5.8451080322265625,
        118.32420349121094, 0, 4.841377258300781, 116.59426879882812, 0, 3.8203887939453125,
        114.87455749511719, 0, 2.783447265625, 113.16434478759766, 0, 1.7318038940429688,
        111.46319580078125, 0, 0.6664962768554688, 109.77053833007812, 0, -0.41155242919921875,
        108.08592987060547, 0, -1.5014495849609375, 106.40898895263672, 0, -2.6024246215820312,
        104.73936462402344, 0, -3.7138900756835938, 103.07659912109375, 0, -4.8351898193359375,
        101.42050170898438, 0, -5.965728759765625, 99.77072143554688, 0, -7.1049957275390625,
        98.12690734863281, 0, -8.252494812011719, 96.48886108398438, 0, -9.407814025878906,
        94.85627746582031, 0, -10.57049560546875, 93.22897338867188, 0, -11.740486145019531,
        91.60688781738281, 0, -12.916603088378906, 89.98928833007812, 0, -14.099479675292969,
        88.37663269042969, 0, -15.287895202636719, 86.76800537109375, 0, -16.482452392578125,
        85.16390991210938, 0, -17.682144165039062, 83.56364440917969, 0, -18.886764526367188,
        81.96717834472656, 0, -20.09644317626953, 80.37443542480469, 0, -21.31037139892578,
        78.78501892089844, 0, -22.528350830078125, 77.19866943359375, 0, -23.750389099121094,
        75.61541748046875, 0, -24.975196838378906, 74.03431701660156, 0, -26.20256805419922,
        72.45527648925781, 0, -27.431777954101562, 70.87759399414062, 0, -28.661705017089844,
        69.30049133300781, 0, -29.89038848876953, 67.72236633300781, 0, -31.114646911621094,
        66.14083862304688, 0, -32.331504821777344, 64.5535888671875, 0, -33.547828674316406,
        62.96595764160156, 0, -34.76414489746094, 61.378326416015625, 0, -35.979103088378906,
        59.789703369140625, 0, -37.19389343261719, 58.200897216796875, 0, -38.4086799621582,
        56.612091064453125, 0, -39.623470306396484, 55.02330017089844, 0, -40.83755111694336,
        53.433990478515625, 0, -42.05160140991211, 51.84461975097656, 0, -43.265647888183594,
        50.25526428222656, 0, -44.479698181152344, 48.6658935546875, 0, -45.693580627441406,
        47.07635498046875, 0, -46.90719223022461, 45.48664855957031, 0, -48.12080383300781,
        43.89695739746094, 0, -49.334415435791016, 42.3072509765625, 0, -50.54802703857422,
        40.71754455566406, 0, -51.761253356933594, 39.127593994140625, 0, -52.97439956665039,
        37.53753662109375, 0, -54.18754577636719, 35.947479248046875, 0, -55.400691986083984,
        34.357421875, 0, -56.61383819580078, 32.767364501953125, 0, -57.82698440551758,
        31.17730712890625, 0, -59.040130615234375, 29.587249755859375, 0, -60.25327682495117,
        27.997207641601562, 0, -61.46642303466797, 26.407150268554688, 0, -62.679569244384766,
        24.817092895507812, 0, -63.89271545410156, 23.227035522460938, 0, -65.14553451538086,
        21.669219970703125, 0, -66.43912506103516, 20.143905639648438, 0, -67.71090316772461,
        18.600372314453125, 0, -68.96007537841797, 17.038482666015625, 0, -70.18618774414062,
        15.458419799804688, 0, -71.38907051086426, 13.860687255859375, 0, -72.56758308410645,
        12.244796752929688, 0, -73.7219009399414, 10.611557006835938, 0, -74.85086441040039,
        8.960693359375, 0, -75.95465278625488, 7.292877197265625, 0, -77.03199577331543,
        5.6078643798828125, 0, -78.08292961120605, 3.9062652587890625, 0, -79.10693168640137,
        2.188385009765625, 0, -80.10337257385254, 0.45428466796875, 0, -81.07163429260254,
        -1.2956695556640625, 0, -82.01147270202637, -3.061065673828125, 0, -82.92240238189697,
        -4.8415374755859375, 0, -83.80396747589111, -6.63677978515625, 0, -84.65569400787354,
        -8.446319580078125, 0, -85.47715759277344, -10.269805908203125, 0, -86.26766300201416,
        -12.106918334960938, 0, -87.02713966369629, -13.957046508789062, 0, -87.75522994995117,
        -15.819793701171875, 0, -88.45099449157715, -17.694808959960938, 0, -89.1146297454834,
        -19.581466674804688, 0, -89.74532699584961, -21.479385375976562, 0, -90.34274768829346,
        -23.3880615234375, 0, -90.90657043457031, -25.30694580078125, 0, -91.43629026412964,
        -27.235443115234375, 0, -91.93153429031372, -29.173126220703125, 0, -92.39178848266602,
        -31.11944580078125, 0, -92.81634140014648, -33.0738525390625, 0, -93.20497179031372,
        -35.035675048828125, 0, -93.55693674087524, -37.00439453125, 0, -93.8714919090271,
        -38.979522705078125, 0, -94.14792013168335, -40.96026611328125, 0, -94.38530397415161,
        -42.94610595703125, 0, -94.5825035572052, -44.936279296875, 0, -94.73812961578369,
        -46.930206298828125, 0, -94.85023403167725, -48.927001953125, 0, -94.91658139228821,
        -50.92584228515625, 0, -94.93361449241638, -52.92572021484375, 0, -94.89499998092651,
        -54.92529296875, 0, -94.78893995285034, -56.92236328125, 0, -94.60168838500977,
        -58.913482666015625, 0, -94.38445258140564, -60.901641845703125, 0, -94.13374328613281,
        -62.885833740234375, 0, -93.84830379486084, -64.86532592773438, 0, -93.52688360214233,
        -66.83932495117188, 0, -93.16796636581421, -68.8067626953125, 0, -92.7702465057373,
        -70.76681518554688, 0, -92.33237886428833, -72.71820068359375, 0, -91.85297012329102,
        -74.65985107421875, 0, -91.3302116394043, -76.59030151367188, 0, -90.76296901702881,
        -78.50814819335938, 0, -90.14974021911621, -80.41171264648438, 0, -89.48898887634277,
        -82.29934692382812, 0, -88.779221534729, -84.16915893554688, 0, -88.019455909729,
        -86.01910400390625, 0, -87.20788764953613, -87.84701538085938, 0, -86.34376430511475,
        -89.65060424804688, 0, -85.42590045928955, -91.42745971679688, 0, -84.45341396331787,
        -93.17498779296875, 0, -83.42563819885254, -94.89056396484375, 0, -82.34202194213867,
        -96.57144165039062, 0, -81.2022705078125, -98.21484375, 0, -80.00648880004883,
        -99.81787109375, 0, -78.7550048828125, -101.37774658203125, 0, -77.44825744628906,
        -102.89169311523438, 0, -76.08733749389648, -104.3570556640625, 0, -74.67338752746582,
        -105.7713623046875, 0, -73.20768928527832, -107.1319580078125, 0, -71.69229316711426,
        -108.43698120117188, 0, -70.12913513183594, -109.68441772460938, 0, -68.52041435241699,
        -110.87249755859375, 0, -66.86857986450195, -111.99990844726562, 0, -65.1762809753418,
        -113.0655517578125, 0, -63.4461669921875, -114.06863403320312, 0, -61.68075180053711,
        -115.00830078125, 0, -59.88320541381836, -115.8848876953125, 0, -58.05609130859375,
        -116.69796752929688, 0, -56.202125549316406, -117.44781494140625, 0, -54.32392883300781,
        -118.1348876953125, 0, -52.42396926879883, -118.75921630859375, 0, -50.50482940673828,
        -119.32177734375, 0, -48.5687370300293, -119.82290649414062, 0, -46.61793518066406,
        -120.26336669921875, 0, -44.654541015625, -120.64364624023438, 0, -42.68046188354492,
        -120.96420288085938, 0, -40.697628021240234, -121.22503662109375, 0, -38.70779037475586,
        -121.42587280273438, 0, -36.71281051635742, -121.56610107421875, 0, -34.714385986328125,
        -121.64346313476562, 0, -32.714569091796875, -121.6549072265625, 0, -30.715721130371094,
        -121.59048461914062, 0, -28.71971893310547, -121.47341918945312, 0, -26.721946716308594,
        -121.37985229492188, 0, -24.726295471191406, -121.24822998046875, 0, -22.733566284179688,
        -121.07846069335938, 0, -20.744400024414062, -120.87051391601562, 0, -18.7596435546875,
        -120.62442016601562, 0, -16.78003692626953, -120.33984375, 0, -14.806289672851562,
        -120.01702880859375, 0, -12.83917236328125, -119.65631103515625, 0, -10.879356384277344,
        -119.25753784179688, 0, -8.9276123046875, -118.82080078125, 0, -6.984672546386719,
        -118.34664916992188, 0, -5.051361083984375, -117.8348388671875, 0, -3.1281204223632812,
        -117.2860107421875, 0, -1.2158432006835938, -116.700439453125, 0, 0.6848831176757812,
        -116.078125, 0, 2.5732955932617188, -115.41961669921875, 0, 4.448875427246094,
        -114.72528076171875, 0, 6.310943603515625, -113.99551391601562, 0, 8.158897399902344,
        -113.23068237304688, 0, 9.992034912109375, -112.43106079101562, 0, 11.809829711914062,
        -111.59701538085938, 0, 13.611686706542969, -110.72918701171875, 0, 15.396957397460938,
        -109.82766723632812, 0, 17.165130615234375, -108.89309692382812, 0, 18.915512084960938,
        -107.92568969726562, 0, 20.647552490234375, -106.9256591796875, 0, 22.360397338867188,
        -105.89321899414062, 0, 24.053627014160156, -104.82882690429688, 0, 25.726585388183594,
        -103.73291015625, 0, 27.378128051757812, -102.60501098632812, 0, 29.007713317871094,
        -101.445556640625, 0, 30.61395263671875, -100.25399780273438, 0, 32.19549560546875,
        -99.02984619140625, 0, 33.75108337402344, -97.77285766601562, 0, 35.27790832519531,
        -96.4810791015625, 0, 36.772735595703125, -95.15249633789062, 0, 38.228912353515625,
        -93.78170776367188, 0, 39.645751953125, -92.37039184570312, 0, 41.07164001464844,
        -90.96798706054688, 0, 42.4619140625, -89.53033447265625, 0, 43.8160400390625,
        -88.05862426757812, 0, 45.1336669921875, -86.55413818359375, 0, 46.41423034667969,
        -85.01785278320312, 0, 47.6580810546875, -83.45172119140625, 0, 48.86480712890625,
        -81.85684204101562, 0, 50.03431701660156, -80.234619140625, 0, 51.16685485839844,
        -78.58624267578125, 0, 52.26243591308594, -76.91302490234375, 0, 53.32127380371094,
        -75.21640014648438, 0, 54.34368896484375, -73.49746704101562, 0, 55.329925537109375,
        -71.75759887695312, 0, 56.28004455566406, -69.99771118164062, 0, 57.1947021484375,
        -68.21914672851562, 0, 58.07438659667969, -66.42315673828125, 0, 58.91966247558594,
        -64.6104736328125, 0, 59.73051452636719, -62.782318115234375, 0, 60.50773620605469,
        -60.93951416015625, 0, 61.25201416015625, -59.083251953125, 0, 61.96331787109375,
        -57.213958740234375, 0, 62.64259338378906, -55.332916259765625, 0, 63.29005432128906,
        -53.440643310546875, 0, 63.90647888183594, -51.538055419921875, 0, 64.49191284179688,
        -49.625640869140625, 0, 65.04722595214844, -47.70428466796875, 0, 65.57270812988281,
        -45.774627685546875, 0, 66.06866455078125, -43.837127685546875, 0, 66.53555297851562,
        -41.892425537109375, 0, 66.97378540039062, -39.941009521484375, 0, 67.38360595703125,
        -37.983489990234375, 0, 67.76524353027344, -36.02020263671875, 0, 68.11897277832031,
        -34.051849365234375, 0, 68.44483947753906, -32.0784912109375, 0, 68.74324035644531,
        -30.100982666015625, 0, 69.0140380859375, -28.11944580078125, 0, 69.25718688964844,
        -26.134246826171875, 0, 69.47219848632812, -24.1458740234375, 0, 69.65867614746094,
        -22.15460205078125, 0, 69.81591796875, -20.1607666015625, 0, 69.94287109375,
        -18.164871215820312, 0, 70.03717041015625, -16.167068481445312, 0, 70.09547424316406,
        -14.167938232421875, 0, 70.10888671875, -12.168060302734375, 0, 70.11311340332031,
        -10.168243408203125, 0, 70.10775756835938, -8.168228149414062, 0, 70.07460021972656,
        -6.16845703125, 0, 70.01365661621094, -4.1695098876953125, 0, 69.92497253417969,
        -2.1714324951171875, 0, 69.80865478515625, -0.1748046875, 0, 69.66468811035156,
        1.8199462890625, 0, 69.49276733398438, 3.8125152587890625, 0, 69.29347229003906,
        5.802490234375, 0, 69.06697082519531, 7.789581298828125, 0, 68.81303405761719, 9.7734375, 0,
        68.53181457519531, 11.753494262695312, 0, 68.223876953125, 13.729644775390625, 0,
        67.88876342773438, 15.701385498046875, 0, 67.52705383300781, 17.66839599609375, 0,
        67.13882446289062, 19.630279541015625, 0, 66.72415161132812, 21.586898803710938, 0,
        66.28334045410156, 23.53759765625, 0, 65.81663513183594, 25.482406616210938, 0,
        65.3238525390625, 27.420791625976562, 0, 64.80575561523438, 29.352462768554688, 0,
        64.26219177246094, 31.27716064453125, 0, 63.69328308105469, 33.19450378417969, 0,
        63.09947204589844, 35.10432434082031, 0, 62.48091125488281, 37.006256103515625, 0,
        61.83781433105469, 38.900054931640625, 0, 61.17033386230469, 40.78533935546875, 0,
        60.47868347167969, 42.66181945800781, 0, 59.76298522949219, 44.529449462890625, 0,
        59.023529052734375, 46.38774108886719, 0, 58.260528564453125, 48.2364501953125, 0,
        57.47419738769531, 50.075347900390625, 0, 56.66435241699219, 51.903961181640625, 0,
        55.83158874511719, 53.72233581542969, 0, 54.975616455078125, 55.529937744140625, 0,
        54.096893310546875, 57.326568603515625, 0, 53.19538879394531, 59.11180114746094, 0,
        52.27104187011719, 60.88545227050781, 0, 51.323974609375, 62.64689636230469, 0,
        50.3541259765625, 64.39598083496094, 0, 49.36149597167969, 66.13233947753906, 0,
        48.34556579589844, 67.85505676269531, 0, 47.30674743652344, 69.56405639648438, 0,
        46.24440002441406, 71.25856018066406, 0, 45.15803527832031, 72.93780517578125, 0,
        44.04676818847656, 74.60064697265625, 0, 42.909698486328125, 76.24594116210938, 0,
        41.744873046875, 77.87167358398438, 0, 40.54972839355469, 79.47517395019531, 0,
        39.3177490234375, 81.05067443847656, 0, 38.047698974609375, 82.59550476074219, 0,
        36.78678894042969, 84.14794921875, 0, 35.53375244140625, 85.70687866210938, 0,
        34.28700256347656, 87.27070617675781, 0, 33.04515075683594, 88.83843994140625, 0,
        31.807998657226562, 90.40985107421875, 0, 30.574310302734375, 91.9840087890625, 0,
        29.343948364257812, 93.56077575683594, 0, 28.116615295410156, 95.13986206054688, 0,
        26.891685485839844, 96.720947265625, 0, 25.669029235839844, 98.3037109375, 0,
        24.448524475097656, 99.88809204101562, 0, 23.230026245117188, 101.47410583496094, 0,
        22.013290405273438, 103.06135559082031, 0, 20.798133850097656, 104.64984130859375, 0,
        19.584823608398438, 106.23977661132812, 0, 18.37218475341797, 107.83021545410156, 0,
        17.16161346435547, 109.42222595214844, 0, 15.951591491699219, 111.01464080810547, 0,
        14.7432861328125, 112.6083755493164, 0, 13.535697937011719, 114.20276641845703, 0,
        12.329254150390625, 115.79792022705078, 0, 11.123977661132812, 117.3938980102539, 0,
        9.919242858886719, 118.9903335571289, 0, 8.71600341796875, 120.58783721923828, 0,
        7.513130187988281, 122.18576049804688, 0, 6.311370849609375, 123.7844467163086, 0,
        5.1104278564453125, 125.38362884521484, 0, 3.90997314453125, 126.98329162597656, 0,
        2.7107009887695312, 128.58384704589844, 0, 1.5119247436523438, 130.18475341796875, 0,
        0.31381988525390625, 131.78617095947266, 0, -0.8829421997070312, 133.38861083984375, 0,
        -2.07952880859375, 134.9911651611328, 0, -3.2760009765625, 136.59391021728516, 0,
        -4.47076416015625, 138.19782257080078, 0, -5.66552734375, 139.80174255371094, 0,
        -6.859245300292969, 141.40641021728516, 0, -8.052406311035156, 143.01151275634766, 0,
        -9.244659423828125, 144.61721801757812, 0, -10.436302185058594, 146.2234878540039, 0,
        -11.626548767089844, 147.83075714111328, 0, -12.78961181640625, 149.45769500732422, 0,
        -13.917778015136719, 151.10907745361328, 0, -15.014045715332031, 152.78187561035156, 0,
        -16.076400756835938, 154.47633361816406, 0, -17.103195190429688, 156.19261169433594, 0,
        -18.092201232910156, 157.9307861328125, 0, -19.041603088378906, 159.691162109375, 0,
        -19.948928833007812, 161.47341918945312, 0, -20.811912536621094, 163.27758026123047, 0,
        -21.628189086914062, 165.10328674316406, 0, -22.39521026611328, 166.95030975341797, 0,
        -23.110488891601562, 168.81803131103516, 0, -23.771133422851562, 170.70569229125977, 0,
        -24.37462615966797, 172.61226272583008, 0, -24.91815948486328, 174.53693389892578, 0,
        -25.399185180664062, 176.4781723022461, 0, -25.814895629882812, 178.43431091308594, 0,
        -26.16291046142578, 180.40378952026367, 0, -26.440689086914062, 182.38429260253906, 0,
        -26.646087646484375, 184.37349700927734, 0, -26.77690887451172, 186.36912155151367, 0,
        -26.831573486328125, 188.36822891235352, 0, -26.808639526367188, 190.36804962158203, 0,
        -26.707000732421875, 192.3652229309082, 0, -26.52587127685547, 194.35686492919922, 0,
        -26.26496124267578, 196.33975219726562, 0, -25.92443084716797, 198.3102912902832, 0,
        -25.504501342773438, 200.26556015014648, 0, -25.006088256835938, 202.2024326324463, 0,
        -24.430282592773438, 204.1175937652588, 0, -23.77849578857422, 206.00828552246094, 0,
        -23.052261352539062, 207.87155151367188, 0, -22.2532958984375, 209.70495986938477, 0,
        -21.38353729248047, 211.50579452514648, 0, -20.444801330566406, 213.27166366577148, 0,
        -19.438880920410156, 215.00004959106445, 0, -18.367202758789062, 216.6885280609131, 0,
        -17.231178283691406, 218.33443355560303, 0, -16.031234741210938, 219.93435287475586, 0,
        -14.766799926757812, 221.48371124267578, 0, -13.434593200683594, 222.9752712249756, 0,
        -12.021499633789062, 224.38990020751953, 0, -10.546905517578125, 225.74074459075928, 0,
        -9.001304626464844, 227.0096664428711, 0, -7.380790710449219, 228.18121337890625, 0,
        -5.686676025390625, 229.24342727661133, 0, -3.9223175048828125, 230.18429017066956, 0,
        -2.0933761596679688, 230.99235105514526, 0, -0.20774078369140625, 231.65769600868225, 0,
        1.7242279052734375, 232.1725596189499, 0, 3.691131591796875, 232.53219664096832, 0,
        5.6802978515625, 232.73488354682922, 0, 7.679283142089844, 232.7820861339569, 0,
        9.676132202148438, 232.6778666973114, 0, 11.659957885742188, 232.4284337759018, 0,
        13.621620178222656, 232.0406939983368, 0, 15.552902221679688, 231.52199268341064, 0,
        17.44629669189453, 230.8788878917694, 0, 19.2945556640625, 230.1156051158905, 0,
        21.087890625, 229.2311463356018, 0, 22.805953979492188, 228.20863246917725, 0,
        24.458961486816406, 227.0834879875183, 0, 26.028419494628906, 225.8444766998291, 0,
        27.497634887695312, 224.48838710784912, 0, 28.849044799804688, 223.01480102539062, 0,
        30.064788818359375, 221.42766761779785, 0, 31.129058837890625, 219.73528480529785, 0,
        32.02964782714844, 217.9502830505371, 0, 32.75898742675781, 216.08885192871094, 0,
        33.31526184082031, 214.16841316223145, 0, 33.701629638671875, 212.206787109375, 0,
        33.925445556640625, 210.2198314666748, 0, 33.99627685546875, 208.2215805053711, 0,
        33.92547607421875, 206.22320938110352, 0, 33.72393798828125, 204.23367309570312, 0,
        33.40191650390625, 202.26010131835938, 0, 32.96821594238281, 200.30801391601562, 0,
        32.42942810058594, 198.38214874267578, 0, 31.788650512695312, 196.48783111572266, 0,
        31.041244506835938, 194.6331329345703, 0, 30.220413208007812, 192.80967712402344, 0,
        29.316856384277344, 191.0257568359375, 0, 28.308631896972656, 189.2987174987793, 0,
        27.213401794433594, 187.62554550170898, 0, 26.045013427734375, 186.00252151489258, 0,
        24.814910888671875, 184.42575073242188, 0, 23.531822204589844, 182.89169692993164, 0,
        22.202919006347656, 181.39703369140625, 0, 20.83417510986328, 179.93886184692383, 0,
        19.430404663085938, 178.51440811157227, 0, 17.99547576904297, 177.12126541137695, 0,
        16.532699584960938, 175.75732803344727, 0, 15.045028686523438, 174.42062759399414, 0,
        13.534828186035156, 173.10950469970703, 0, 12.003890991210938, 171.8224868774414, 0,
        10.45428466796875, 170.55829620361328, 0, 8.887191772460938, 169.31554412841797, 0,
        7.304046630859375, 168.09339141845703, 0, 5.705902099609375, 166.89100646972656, 0,
        4.0937957763671875, 165.7072525024414, 0, 2.4685745239257812, 164.54171752929688, 0,
        0.83074951171875, 163.39400482177734, 0, -0.8190994262695312, 162.26334381103516, 0,
        -2.480224609375, 161.1496810913086, 0, -4.1527252197265625, 160.05298614501953, 0,
        -5.836151123046875, 158.97315216064453, 0, -7.5306549072265625, 157.9107208251953, 0,
        -9.236747741699219, 156.8670196533203, 0, -10.955665588378906, 155.84465789794922, 0,
        -12.695770263671875, 154.85894012451172, 0, -14.436790466308594, 153.87479400634766, 0,
        -16.16668701171875, 152.87116241455078, 0, -17.885910034179688, 151.8493194580078, 0,
        -19.594810485839844, 150.8102264404297, 0, -21.293731689453125, 149.75482177734375, 0,
        -22.982864379882812, 148.68407440185547, 0, -24.662551879882812, 147.59844207763672, 0,
        -26.333267211914062, 146.4989776611328, 0, -27.995132446289062, 145.3863067626953, 0,
        -29.648536682128906, 144.2609100341797, 0, -31.293418884277344, 143.12325286865234, 0,
        -32.930084228515625, 141.973876953125, 0, -34.558895111083984, 140.8132553100586, 0,
        -36.179935455322266, 139.64183807373047, 0, -37.7934455871582, 138.45993041992188, 0,
        -39.399349212646484, 137.26801300048828, 0, -40.998043060302734, 136.06623077392578, 0,
        -42.589515686035156, 134.85494995117188, 0, -44.173919677734375, 133.63441467285156, 0,
        -45.751304626464844, 132.40492248535156, 0, -47.32170867919922, 131.16637420654297, 0,
        -48.88526916503906, 129.91921997070312, 0, -50.44192123413086, 128.6635513305664, 0,
        -51.99177932739258, 127.39938354492188, 0, -53.53464126586914, 126.12675476074219, 0,
        -55.07049560546875, 124.84577178955078, 0, -56.5991096496582, 123.55606079101562, 0,
        -58.12065887451172, 122.25804138183594, 0, -59.63429260253906, 120.95073699951172, 0,
        -61.139678955078125, 119.63411712646484, 0, -62.63576889038086, 118.30680847167969, 0,
        -64.11960983276367, 116.9657211303711, 0, -65.65801620483398, 115.6884536743164, 0,
        -67.18089485168457, 114.39191436767578, 0, -68.67617225646973, 113.06378173828125, 0,
        -70.14286994934082, 111.70408630371094, 0, -71.5798168182373, 110.3131332397461, 0,
        -72.98598098754883, 108.8910140991211, 0, -74.36032295227051, 107.43798065185547, 0,
        -75.7016487121582, 105.95457458496094, 0, -77.00911140441895, 104.44114685058594, 0,
        -78.28154945373535, 102.89830017089844, 0, -79.51814079284668, 101.32640075683594, 0,
        -80.71784973144531, 99.72622680664062, 0, -81.87980461120605, 98.09849548339844, 0,
        -83.00332546234131, 96.44395446777344, 0, -84.08750820159912, 94.76338195800781, 0,
        -85.13175582885742, 93.05770874023438, 0, -86.13531017303467, 91.32768249511719, 0,
        -87.09760761260986, 89.57452392578125, 0, -88.01821804046631, 87.79899597167969, 0,
        -88.89663028717041, 86.00225830078125, 0, -89.73239326477051, 84.18536376953125, 0,
        -90.52516508102417, 82.34925842285156, 0, -91.27459859848022, 80.4949951171875, 0,
        -91.98069524765015, 78.62387084960938, 0, -92.64316844940186, 76.73678588867188, 0,
        -93.26186418533325, 74.83494567871094, 0, -93.83670949935913, 72.91941833496094, 0,
        -94.36732912063599, 70.99111938476562, 0, -94.8539981842041, 69.05130004882812, 0,
        -95.29642796516418, 67.100830078125, 0, -95.69467639923096, 65.14094543457031, 0,
        -96.04849767684937, 63.17256164550781, 0, -96.35772264003754, 61.19673156738281, 0,
        -96.6222916841507, 59.21434020996094, 0, -96.84144461154938, 57.22639465332031, 0,
        -97.01495790481567, 55.2340087890625, 0, -97.14174962043762, 53.23802185058594, 0,
        -97.22085356712341, 51.23973083496094, 0, -97.2502834200859, 49.239898681640625, 0,
        -97.22697138786316, 47.24015808105469, 0, -97.14589750766754, 45.24189758300781, 0,
        -96.99535655975342, 43.2476806640625, 0, -96.7631653547287, 41.26127624511719, 0,
        -96.44499933719635, 39.28697204589844, 0, -96.02157688140869, 37.33262634277344, 0,
        -95.48986530303955, 35.40486145019531, 0, -94.8478479385376, 33.51100158691406, 0,
        -94.09495496749878, 31.658447265625, 0, -93.23208713531494, 29.854537963867188, 0,
        -92.26173877716064, 28.106094360351562, 0, -91.18803787231445, 26.418991088867188, 0,
        -90.01646995544434, 24.798553466796875, 0, -88.75295066833496, 23.248443603515625, 0,
        -87.4049482345581, 21.771408081054688, 0, -85.97968578338623, 20.368743896484375, 0,
        -84.48449611663818, 19.040679931640625, 0, -82.92657947540283, 17.786911010742188, 0,
        -81.31278419494629, 16.605667114257812, 0, -79.6496524810791, 15.495162963867188, 0,
        -77.94284439086914, 14.452972412109375, 0, -76.19753646850586, 13.476455688476562, 0,
        -74.41855049133301, 12.562728881835938, 0, -72.61007499694824, 11.709075927734375, 0,
        -70.7755298614502, 10.912551879882812, 0, -68.91841316223145, 10.17034912109375, 0,
        -67.04140663146973, 9.480224609375, 0, -65.1470718383789, 8.83892822265625, 0,
        -63.23748779296875, 8.244522094726562, 0, -61.314552307128906, 7.694854736328125, 0,
        -59.379966735839844, 7.1876373291015625, 0, -57.435211181640625, 6.7209930419921875, 0,
        -55.481536865234375, 6.2931671142578125, 0, -53.52010726928711, 5.9025115966796875, 0,
        -51.55185317993164, 5.5475616455078125, 0, -49.57778549194336, 5.227142333984375, 0,
        -47.59848403930664, 4.9399871826171875, 0, -45.61489486694336, 4.6849212646484375, 0,
        -43.62738037109375, 4.4612884521484375, 0, -41.636688232421875, 4.268341064453125, 0,
        -39.64334487915039, 4.1058349609375, 0, -37.647865295410156, 3.9727630615234375, 0,
        -35.65048599243164, 3.869903564453125, 0, -33.651885986328125, 3.79736328125, 0,
        -31.65233612060547, 3.7564544677734375, 0, -29.652305603027344, 3.7502593994140625, 0,
        -27.6527099609375, 3.786712646484375, 0, -25.653091430664062, 3.80865478515625, 0,
        -23.6533203125, 3.8421630859375, 0, -21.654220581054688, 3.9017486572265625, 0,
        -19.6561279296875, 3.988006591796875, 0, -17.659317016601562, 4.1010284423828125, 0,
        -15.664253234863281, 4.2415313720703125, 0, -13.671417236328125, 4.4100341796875, 0,
        -11.681205749511719, 4.6071014404296875, 0, -9.694091796875, 4.8332366943359375, 0,
        -7.710502624511719, 5.08892822265625, 0, -5.730979919433594, 5.374664306640625, 0,
        -3.7562332153320312, 5.69097900390625, 0, -1.7867660522460938, 6.038818359375, 0,
        0.177001953125, 6.418060302734375, 0, 2.1342391967773438, 6.8290252685546875, 0,
        4.084251403808594, 7.273223876953125, 0, 6.026611328125, 7.7498779296875, 0,
        7.960243225097656, 8.260467529296875, 0, 9.884780883789062, 8.804489135742188, 0,
        11.799232482910156, 9.383163452148438, 0, 13.70281982421875, 9.996490478515625, 0,
        15.594825744628906, 10.64471435546875, 0, 17.474334716796875, 11.32830810546875, 0,
        19.34050750732422, 12.047653198242188, 0, 21.19239044189453, 12.803024291992188, 0,
        23.028953552246094, 13.594589233398438, 0, 24.849456787109375, 14.422592163085938, 0,
        26.652976989746094, 15.287109375, 0, 28.438156127929688, 16.188705444335938, 0,
        30.204360961914062, 17.127059936523438, 0, 31.950180053710938, 18.102630615234375, 0,
        33.674835205078125, 19.115203857421875, 0, 35.3770751953125, 20.165008544921875, 0,
        37.05577087402344, 21.252105712890625, 0, 38.709716796875, 22.37652587890625, 0,
        40.33781433105469, 23.538177490234375, 0, 41.93855285644531, 24.737030029296875, 0,
        43.51078796386719, 25.973129272460938, 0, 45.0528564453125, 27.246658325195312, 0,
        46.56324768066406, 28.557601928710938, 0, 48.04035949707031, 29.9058837890625, 0,
        49.48204040527344, 31.292007446289062, 0, 50.88603210449219, 32.716278076171875, 0,
        52.2496337890625, 34.17918395996094, 0, 53.56913757324219, 35.682159423828125, 0,
        54.839263916015625, 37.22686767578125, 0, 56.05235290527344, 38.81681823730469, 0,
        57.18882751464844, 40.462188720703125, 0, 58.203216552734375, 42.18559265136719, 0,
        59.18452453613281, 43.92826843261719, 0, 60.13995361328125, 45.68531799316406, 0,
        61.06849670410156, 47.456573486328125, 0, 61.96875, 49.24253845214844, 0, 62.83880615234375,
        51.04339599609375, 0, 63.67738342285156, 52.859039306640625, 0, 64.48274230957031,
        54.68963623046875, 0, 65.2530517578125, 56.53526306152344, 0, 65.9864501953125,
        58.39601135253906, 0, 66.68037414550781, 60.27171325683594, 0, 67.33291625976562,
        62.16212463378906, 0, 67.94171142578125, 64.0672607421875, 0, 68.50422668457031,
        65.98634338378906, 0, 69.01751708984375, 67.9193115234375, 0, 69.47879028320312,
        69.86540222167969, 0, 69.88482666015625, 71.82366943359375, 0, 70.23280334472656,
        73.79304504394531, 0, 70.51902770996094, 75.77239990234375, 0, 70.7401123046875,
        77.75999450683594, 0, 70.89227294921875, 79.75410461425781, 0, 70.97189331054688,
        81.75242614746094, 0, 70.97471618652344, 83.75222778320312, 0, 70.89710998535156,
        85.75053405761719, 0, 70.73512268066406, 87.74380493164062, 0, 70.48429870605469,
        89.72789001464844, 0, 70.14117431640625, 91.69804382324219, 0, 69.701416015625,
        93.64891052246094, 0, 69.16130065917969, 95.57429504394531, 0, 68.51673889160156,
        97.46726989746094, 0, 67.76319885253906, 99.319580078125, 0, 66.89503479003906,
        101.1209716796875, 0, 65.90400695800781, 102.85755920410156, 0, 64.77200317382812,
        104.50550842285156, 0, 63.44818115234375, 106.00286865234375, 0, 62.02796936035156,
        107.410888671875, 0, 60.538787841796875, 108.74559020996094, 0, 58.98121643066406,
        109.99959564208984, 0, 57.35655212402344, 111.16558837890625, 0, 55.66755676269531,
        112.23625183105469, 0, 53.91804504394531, 113.20462799072266, 0, 52.112945556640625,
        114.06507110595703, 0, 50.2581787109375, 114.81236267089844, 0, 48.360595703125,
        115.44319152832031, 0, 46.42759704589844, 115.95507049560547, 0, 44.46673583984375,
        116.34732055664062, 0, 42.48567199707031, 116.61995697021484, 0, 40.491973876953125,
        116.77468872070312, 0, 38.49259948730469, 116.81348419189453, 0, 36.49430847167969,
        116.73893737792969, 0, 34.50311279296875, 116.55340576171875, 0, 32.52531433105469,
        116.258544921875, 0, 30.566864013671875, 115.85424041748047, 0, 28.63544464111328,
        115.33610534667969, 0, 26.746620178222656, 114.6807861328125, 0, 24.877464294433594,
        113.96961975097656, 0, 23.037521362304688, 113.18597412109375, 0, 21.23180389404297,
        112.32648468017578, 0, 19.465835571289062, 111.38793182373047, 0, 17.746017456054688,
        110.36756134033203, 0, 16.07891082763672, 109.26310729980469, 0, 14.471717834472656,
        108.07318878173828, 0, 12.931900024414062, 106.79717254638672, 0, 11.467109680175781,
        105.43588256835938, 0, 10.084526062011719, 103.99105834960938, 0, 8.791229248046875,
        102.46591186523438, 0, 7.593284606933594, 100.86479187011719, 0, 6.495613098144531,
        99.1932373046875, 0, 5.502227783203125, 97.45779418945312, 0, 4.61566162109375,
        95.66543579101562, 0, 3.8371124267578125, 93.82354736328125, 0, 3.1666946411132812,
        91.93948364257812, 0, 2.60369873046875, 90.02066040039062, 0, 2.1461029052734375,
        88.07395935058594, 0, 1.7922744750976562, 86.10575866699219, 0, 1.5398712158203125,
        84.1219482421875, 0, 1.3870773315429688, 82.1280517578125, 0, 1.3328704833984375,
        80.12899780273438, 0, 1.3782196044921875, 78.12969970703125, 0, 1.5283737182617188,
        76.13560485839844, 0, 1.80889892578125, 74.15591430664062, 0, 2.2395095825195312,
        72.20295715332031, 0, 2.7464828491210938, 70.26849365234375, 0, 3.3287734985351562,
        68.35523986816406, 0, 3.9848556518554688, 66.46603393554688, 0, 4.713111877441406,
        64.60353088378906, 0, 5.511161804199219, 62.769744873046875, 0, 6.376579284667969,
        60.96678161621094, 0, 7.306785583496094, 59.19647216796875, 0, 8.298881530761719,
        57.45997619628906, 0, 9.349998474121094, 55.75859069824219, 0, 10.457107543945312,
        54.09309387207031, 0, 11.617240905761719, 52.4639892578125, 0, 12.827476501464844,
        50.87181091308594, 0, 14.084754943847656, 49.316619873046875, 0, 15.38653564453125,
        47.79829406738281, 0, 16.729934692382812, 46.31672668457031, 0, 18.112396240234375,
        44.87150573730469, 0, 19.531578063964844, 43.46234130859375, 0, 20.985183715820312,
        42.08869934082031, 0, 22.470726013183594, 40.749725341796875, 0, 23.98651885986328,
        39.445281982421875, 0, 25.530487060546875, 38.173919677734375, 0, 27.101119995117188,
        36.93571472167969, 0, 28.69634246826172, 35.729522705078125, 0, 30.314971923828125,
        34.55485534667969, 0, 31.955490112304688, 33.41078186035156, 0, 33.6165771484375,
        32.296966552734375, 0, 35.29693603515625, 31.212295532226562, 0, 36.99560546875,
        30.156631469726562, 0, 38.71144104003906, 29.12908935546875, 0, 40.4434814453125,
        28.12908935546875, 0, 42.19068908691406, 27.156295776367188, 0, 43.95268249511719,
        26.210006713867188, 0, 45.72859191894531, 25.289932250976562, 0, 47.5174560546875,
        24.395675659179688, 0, 49.31880187988281, 23.526947021484375, 0, 51.13240051269531,
        22.683578491210938, 0, 52.95741271972656, 21.865585327148438, 0, 54.79350280761719,
        21.07257080078125, 0, 56.6402587890625, 20.304931640625, 0, 58.497467041015625,
        19.562713623046875, 0, 60.36515808105469, 18.8472900390625, 0, 62.243011474609375,
        18.159210205078125, 0, 64.13156127929688, 17.5009765625, 0, 66.03147888183594,
        16.876815795898438, 0, 67.95008850097656, 16.313980102539062, 0, 69.88156127929688,
        15.794906616210938, 0, 71.791259765625, 15.2015380859375, 0, 73.67491149902344,
        14.529739379882812, 0, 75.52714538574219, 13.7757568359375, 0, 77.34208679199219,
        12.9359130859375, 0, 79.11337280273438, 12.007431030273438, 0, 80.83375549316406,
        10.98785400390625, 0, 82.49571228027344, 9.875869750976562, 0, 84.09181213378906,
        8.6712646484375, 0, 85.61457824707031, 7.374908447265625, 0, 87.05644226074219,
        5.9893646240234375, 0, 88.41091918945312, 4.5184173583984375, 0, 89.672607421875,
        2.9669342041015625, 0, 90.83676147460938, 1.3412017822265625, 0, 91.90036010742188,
        -0.3521575927734375, 0, 92.86199951171875, -2.10552978515625, 0, 93.72090148925781,
        -3.911407470703125, 0, 94.47775268554688, -5.762451171875, 0, 95.13420104980469,
        -7.651458740234375, 0, 95.692138671875, -9.57171630859375, 0, 96.15447998046875,
        -11.517303466796875, 0, 96.52392578125, -13.482696533203125, 0, 96.8028564453125,
        -15.46295166015625, 0, 96.99365234375, -17.453720092773438, 0, 97.09744262695312,
        -19.450927734375, 0, 97.11428833007812, -21.450714111328125, 0, 97.04081726074219,
        -23.449005126953125, 0, 96.8626708984375, -25.440673828125, 0, 96.54966735839844,
        -27.416107177734375, 0, 96.1444091796875, -29.374359130859375, 0, 95.64561462402344,
        -31.310943603515625, 0, 95.04969787597656, -33.219818115234375, 0, 94.35397338867188,
        -35.09454345703125, 0, 93.55706787109375, -36.928863525390625, 0, 92.65788269042969,
        -38.71502685546875, 0, 91.65733337402344, -40.446380615234375, 0, 90.55714416503906,
        -42.116180419921875, 0, 89.3609619140625, -43.71868896484375, 0, 88.07273864746094,
        -45.248016357421875, 0, 86.69802856445312, -46.7001953125, 0, 85.24288940429688,
        -48.071807861328125, 0, 83.71369934082031, -49.360687255859375, 0, 82.11752319335938,
        -50.56512451171875, 0, 80.46061706542969, -51.68511962890625, 0, 78.74977111816406,
        -52.72064208984375, 0, 76.99125671386719, -53.67254638671875, 0, 75.1903076171875,
        -54.541900634765625, 0, 73.35231018066406, -55.330169677734375, 0, 71.48208618164062,
        -56.038238525390625, 0, 69.5833740234375, -56.66656494140625, 0, 67.660400390625,
        -57.215301513671875, 0, 65.7156982421875, -57.681732177734375, 0, 63.75193786621094,
        -58.059844970703125, 0, 61.77130126953125, -58.334686279296875, 0, 59.796234130859375,
        -58.64959716796875, 0, 57.82594299316406, -58.994049072265625, 0, 55.861541748046875,
        -59.369293212890625, 0, 53.9033203125, -59.77587890625, 0, 51.952301025390625,
        -60.215057373046875, 0, 50.00892639160156, -60.6876220703125, 0, 48.07435607910156,
        -61.194793701171875, 0, 46.149383544921875, -61.737640380859375, 0, 44.23515319824219,
        -62.317047119140625, 0, 42.332855224609375, -62.934112548828125, 0, 40.44337463378906,
        -63.590087890625, 0, 38.56840515136719, -64.28567504882812, 0, 36.70890808105469,
        -65.022216796875, 0, 34.8665771484375, -65.8004150390625, 0, 33.04315185546875,
        -66.62155151367188, 0, 31.239822387695312, -67.48648071289062, 0, 29.45862579345703,
        -68.39593505859375, 0, 27.701515197753906, -69.35089111328125, 0, 25.97010040283203,
        -70.35198974609375, 0, 24.266677856445312, -71.39974975585938, 0, 22.593109130859375,
        -72.49472045898438, 0, 20.951644897460938, -73.63729858398438, 0, 19.3446044921875,
        -74.82760620117188, 0, 17.774032592773438, -76.06582641601562, 0, 16.242156982421875,
        -77.35177612304688, 0, 14.75146484375, -78.68502807617188, 0, 13.304191589355469,
        -80.0650634765625, 0, 11.902236938476562, -81.4913330078125, 0, 10.54791259765625,
        -82.96298217773438, 0, 9.243415832519531, -84.47894287109375, 0, 7.99041748046875,
        -86.03750610351562, 0, 6.790931701660156, -87.6375732421875, 0, 5.646354675292969,
        -89.27777099609375, 0, 4.558555603027344, -90.95571899414062, 0, 3.5289306640625,
        -92.67044067382812, 0, 2.55859375, -94.41912841796875, 0, 1.6491775512695312,
        -96.20034790039062, 0, 0.8014755249023438, -98.01144409179688, 0, 0.0163726806640625,
        -99.8509521484375, 0, -0.7046966552734375, -101.71640014648438, 0, -1.3609390258789062,
        -103.60546875, 0, -1.9511260986328125, -105.51629638671875, 0, -2.4734649658203125,
        -107.44680786132812, 0, -2.9260482788085938, -109.39483642578125, 0, -3.3054428100585938,
        -111.3582763671875, 0, -3.6064987182617188, -113.33544921875, 0, -3.8182601928710938,
        -115.32376098632812, 0, -3.8999786376953125, -117.32144165039062, 0, -3.8999786376953125,
        -119.32144165039062, 0, -3.8999786376953125, -121.32144165039062, 0, -3.8999786376953125,
        -123.32144165039062, 0, -3.8999786376953125, -125.32144165039062, 0, -3.8999786376953125,
        -127.32144165039062, 0, -3.8999786376953125, -129.32144165039062, 0, -3.8999786376953125,
        -131.32144165039062, 0, -3.8999786376953125, -133.32144165039062, 0, -3.8999786376953125,
        -135.32144165039062, 0, -3.8999786376953125, -137.32144165039062, 0, -3.8999786376953125,
        -139.32144165039062, 0, -3.8999786376953125, -141.32144165039062, 0, -3.8999786376953125,
        -143.32144165039062, 0, -3.8999786376953125, -145.32144165039062, 0, -3.8999862670898438,
        -147.32144165039062, 0, -3.8999862670898438, -149.32144165039062, 0, -3.8999862670898438,
        -151.32144165039062, 0, -3.8999862670898438, -153.32144165039062, 0, -3.8999862670898438,
        -155.32144165039062, 0, -3.8999862670898438, -157.32144165039062, 0, -3.8999862670898438,
        -159.32144165039062, 0, -3.8999862670898438, -161.32144165039062, 0, -3.8999862670898438,
        -163.32144165039062, 0, -3.8999862670898438, -165.32144165039062, 0, -3.8999862670898438,
        -167.32144165039062, 0, -3.8999862670898438, -169.32144165039062, 0, -3.8999862670898438,
        -171.32144165039062, 0, -3.8999862670898438, -173.32144165039062, 0, -3.8999862670898438,
        -175.32144165039062, 0, -3.8999862670898438, -177.32144165039062, 0, -3.8999862670898438,
        -179.32144165039062, 0, -3.8999862670898438, -181.32144165039062, 0, -3.8999862670898438,
        -183.32144165039062, 0, -3.8999862670898438, -185.32144165039062, 0, -3.8999862670898438,
        -187.32144165039062, 0, -3.8999862670898438, -189.32144165039062, 0, -3.8999862670898438,
        -191.32144165039062, 0, -3.8999862670898438, -193.32144165039062, 0, -3.8999862670898438,
        -195.32144165039062, 0, -3.8999862670898438, -197.32144165039062, 0, -3.8999862670898438,
        -199.32144165039062, 0, -3.8999862670898438, -201.32144165039062, 0, -3.8999862670898438,
        -203.32144165039062, 0, -3.8999862670898438, -205.32144165039062, 0, -3.8999862670898438,
        -207.32144165039062, 0, -3.8999862670898438, -209.32144165039062, 0, -3.8999862670898438,
        -211.32144165039062, 0, -3.8999862670898438, -213.32144165039062, 0, -3.8999862670898438,
        -215.32144165039062, 0, -3.8999862670898438, -217.32144165039062, 0, -3.8999862670898438,
        -219.32144165039062, 0, -3.8999862670898438, -221.32144165039062, 0, -3.8999862670898438,
        -223.32144165039062, 0, -3.8999862670898438, -225.32144165039062, 0, -3.8999862670898438,
        -227.32144165039062, 0, -3.8999862670898438, -229.32144165039062, 0, -3.8999862670898438,
        -231.32144165039062, 0, -3.8999862670898438, -233.32144165039062, 0,
    ],
    intersected: [
        {
            x: 43.85871869812088,
            y: 26.260470531129283,
            points: [43.85871869812088, 26.260470531129283],
            from: 1830,
            to: 2076,
            _from: 915,
            _to: 1038,
            length: 2426,
            id: 14,
            positions: [
                {
                    x: 43.85871869812088,
                    y: 26.260470531129283,
                },
            ],
        },
        {
            x: -15.362530195138978,
            y: 153.3377089187447,
            points: [-15.362530195138978, 153.3377089187447],
            from: 1304,
            to: 1520,
            _from: 652,
            _to: 760,
            length: 2426,
            id: 13,
            positions: [
                {
                    x: -15.362530195138978,
                    y: 153.3377089187447,
                },
            ],
        },
        {
            x: 16.879498818251456,
            y: 109.79349487427628,
            points: [16.879498818251456, 109.79349487427628],
            from: 1250,
            to: 1970,
            _from: 625,
            _to: 985,
            length: 2426,
            id: 12,
            positions: [
                {
                    x: 16.879498818251456,
                    y: 109.79349487427628,
                },
            ],
        },
        {
            x: 59.656686897785214,
            y: 44.79658266952965,
            points: [59.656686897785214, 44.79658266952965],
            from: 1172,
            to: 1854,
            _from: 586,
            _to: 927,
            length: 2426,
            id: 11,
            positions: [
                {
                    x: 59.656686897785214,
                    y: 44.79658266952965,
                },
            ],
        },
        {
            x: 67.76619454630051,
            y: 16.367926502292367,
            points: [67.76619454630051, 16.367926502292367],
            from: 1142,
            to: 2102,
            _from: 571,
            _to: 1051,
            length: 2426,
            id: 10,
            positions: [
                {
                    x: 67.76619454630051,
                    y: 16.367926502292367,
                },
            ],
        },
        {
            x: 61.52171579261783,
            y: -58.37448096920663,
            points: [61.52171579261783, -58.37448096920663],
            from: 1066,
            to: 2212,
            _from: 533,
            _to: 1106,
            length: 2426,
            id: 9,
            positions: [
                {
                    x: 61.52171579261783,
                    y: -58.37448096920663,
                },
            ],
        },
        {
            x: -3.8999786376953125,
            y: -117.50627308664598,
            points: [-3.8999786376953125, -117.50627308664598],
            from: 974,
            to: 2308,
            _from: 487,
            _to: 1154,
            length: 2426,
            id: 8,
            positions: [
                {
                    x: -3.8999786376953125,
                    y: -117.50627308664598,
                },
            ],
        },
        {
            x: -72.86212485043943,
            y: 11.828050778475612,
            points: [-72.86212485043943, 11.828050778475612],
            from: 772,
            to: 1706,
            _from: 386,
            _to: 853,
            length: 2426,
            id: 7,
            positions: [
                {
                    x: -72.86212485043943,
                    y: 11.828050778475612,
                },
            ],
        },
        {
            x: 7.792755506894464,
            y: 121.81430006872822,
            points: [7.792755506894464, 121.81430006872822],
            from: 636,
            to: 1264,
            _from: 318,
            _to: 632,
            length: 2426,
            id: 6,
            positions: [
                {
                    x: 7.792755506894464,
                    y: 121.81430006872822,
                },
            ],
        },
        {
            x: 11.011719827131959,
            y: 171.0130596612143,
            points: [11.011719827131959, 171.0130596612143],
            from: 584,
            to: 1488,
            _from: 292,
            _to: 744,
            length: 2426,
            id: 5,
            positions: [
                {
                    x: 11.011719827131959,
                    y: 171.0130596612143,
                },
            ],
        },
        {
            x: -21.556016093737366,
            y: 211.14867727380766,
            points: [-21.556016093737366, 211.14867727380766],
            from: 532,
            to: 1364,
            _from: 266,
            _to: 682,
            length: 2426,
            id: 4,
            positions: [
                {
                    x: -21.556016093737366,
                    y: 211.14867727380766,
                },
            ],
        },
        {
            x: -65.11431837054059,
            y: 116.13986076822738,
            points: [-65.11431837054059, 116.13986076822738],
            from: 380,
            to: 1582,
            _from: 190,
            _to: 791,
            length: 2426,
            id: 3,
            positions: [
                {
                    x: -65.11431837054059,
                    y: 116.13986076822738,
                },
            ],
        },
        {
            x: -37.63609493165694,
            y: 57.62254666288067,
            points: [-37.63609493165694, 57.62254666288067],
            from: 316,
            to: 714,
            _from: 158,
            _to: 357,
            length: 2426,
            id: 2,
            positions: [
                {
                    x: -37.63609493165694,
                    y: 57.62254666288067,
                },
            ],
        },
        {
            x: -28.598951845014888,
            y: 3.7694623642460616,
            points: [-28.598951845014888, 3.7694623642460616],
            from: 262,
            to: 1752,
            _from: 131,
            _to: 876,
            length: 2426,
            id: 1,
            positions: [
                {
                    x: -28.598951845014888,
                    y: 3.7694623642460616,
                },
            ],
        },
        {
            x: -28.699996948242188,
            y: -121.47249549812229,
            points: [-28.699996948242188, -121.47249549812229],
            from: 136,
            to: 950,
            _from: 68,
            _to: 475,
            length: 2426,
            id: 0,
            positions: [
                {
                    x: -28.699996948242188,
                    y: -121.47249549812229,
                },
            ],
        },
    ],
};
