import events from "events";
import throttle from "lodash/throttle";
import UA from "./ua";

// function canPassive() {
//     if (
//         typeof window === "object" &&
//         typeof window.addEventListener === "function" &&
//         Object.defineProperty
//     ) {
//         let passive: boolean = false;
//         const options = Object.defineProperty({}, "passive", {
//             get() {
//                 passive = true;
//             },
//         });
//         window.addEventListener("test", null, options);
//         return passive;
//     }
// }

export type DragStatus = {
    start: {
        x: number;
        y: number;
    };
    move: {
        x: number;
        y: number;
    };
    end: {
        x: number;
        y: number;
    };
    wheel: {
        deltaX: number;
        deltaY: number;
    };
    distance: {
        x: number;
        y: number;
    };
    distanceOffset: Function;
};

export default class DragAndDrop extends events {
    canPassive: boolean;
    $target: HTMLElement;
    config: any;

    static EVENT_DRAG_START: string = "dragStart";
    static EVENT_DRAG_MOVE: string = "dragMove";
    static EVENT_DRAG_END: string = "dragEnd";
    static EVENT_MOUSE_WHEEL: string = "mouseWheel";

    isDown: boolean;
    status: DragStatus;

    constructor($target: HTMLElement, option = {}) {
        super();
        // this.canPassive = canPassive();
        this.$target = $target;
        this.config = Object.assign(
            {
                moveWhileDown: true,
                targetOut: false,
                draggable: true,
                wheelable: false,
                wheelReverse: false,
                wheelUseYtoX: false,
                wheelPower: 0.1,
                prevent: false,
            },
            option
        );

        this.isDown = false;
        this.status = {
            start: { x: 0, y: 0 },
            move: { x: 0, y: 0 },
            end: { x: 0, y: 0 },
            wheel: { deltaX: 0, deltaY: 0 },
            distance: { x: 0, y: 0 },
            distanceOffset: function () {
                return { x: Math.abs(this.distance.x), y: Math.abs(this.distance.y) };
            },
        };

        const onStart = (e) => {
            this.status.start = getPageInfo(e);
            this.status.move = { x: 0, y: 0 };
            this.status.end = { x: 0, y: 0 };
            this.status.distance = { x: 0, y: 0 };
            // this.onStart(this.status)
            this.emit(DragAndDrop.EVENT_DRAG_START, this.status);
            this.isDown = true;

            if (UA.isPC) {
                document.addEventListener("mousemove", onMove, { passive: false });
                document.addEventListener("mouseup", onEnd);
                if (this.config.targetOut) $target.addEventListener("mouseout", onEnd);
            }

            document.addEventListener("touchmove", onMove, { passive: false });
            document.addEventListener("touchend", onEnd, false);
        };

        const onMove = (e) => {
            // e.preventDefault();
            this.status.move = getPageInfo(e);
            this.status.distance.x = this.status.start.x - this.status.move.x;
            this.status.distance.y = this.status.start.y - this.status.move.y;
            this.emit(DragAndDrop.EVENT_DRAG_MOVE, this.status);
        };

        const onEnd = (e) => {
            this.isDown = false;
            this.status.end = this.status.move;
            this.emit(DragAndDrop.EVENT_DRAG_END, this.status);
            // this.onEnd(this.status)

            if (UA.isPC) {
                document.removeEventListener("mousemove", onMove);
                document.removeEventListener("mouseup", onEnd);
                if (this.config.targetOut) $target.removeEventListener("mouseout", onEnd);
            }

            document.removeEventListener("touchmove", onMove);
            document.removeEventListener("touchend", onEnd);
        };

        let onWheel = (e) => {
            if (this.config.wheelUseYtoX) {
                this.status.wheel.deltaX = -e.deltaY;
                // this.status.wheel.deltaY = -deltaY;
            } else {
                this.status.wheel.deltaX = -e.deltaX;
                this.status.wheel.deltaY = -e.deltaY;
            }

            this.status.wheel.deltaX *= this.config.wheelPower;
            this.status.wheel.deltaY *= this.config.wheelPower;
            this.emit(DragAndDrop.EVENT_MOUSE_WHEEL, this.status);
        };

        //@ts-ignore
        onWheel = throttle(onWheel.bind(this), 16);

        function getPageInfo(e: any) {
            let info = { x: 0, y: 0 };
            let supportTouch = "ontouchstart" in window;

            let targetRect = e.target.getBoundingClientRect(); //{left:e.target.offsetLeft,top:e.target.offsetTop};
            if (UA.isMozilla && UA.isPC) supportTouch = false;
            if (e.touches) {
                let touch;
                if (e.touches) {
                    touch = e.touches[0];
                    info.x = touch.clientX;
                    info.y = touch.clientY;
                } else {
                    info.x = e.clientX;
                    info.y = e.clientY;
                }
            } else {
                info.x = e.clientX;
                info.y = e.clientY;
            }
            return info;
        }

        if (UA.isPC) {
            if (this.config.draggable) this.$target.addEventListener("mousedown", onStart);
            if (this.config.wheelable) {
                this.$target.addEventListener("wheel", onWheel, { passive: true });
            }
        }

        if (this.config.draggable) {
            this.$target.addEventListener("touchstart", onStart, { passive: true });
        }
        this.on("destroy", () => {
            this.$target.removeEventListener("touchstart", onStart);
            if (UA.isPC) {
                this.$target.removeEventListener("mousedown", onStart);
            }
        });
    }

    destroy() {
        this.emit("destroy");
    }
}
