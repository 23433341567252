import gsap from "gsap";
import {
    Color,
    DoubleSide,
    Group,
    Mesh,
    MeshBasicMaterial,
    Object3D,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    ShaderMaterial,
    Texture,
    Vector3,
    WebGLRenderTarget,
} from "three";
import BaseWebGL from "./BaseWebGL";
import config, { CardOptionType } from "./config";
import FlowerCardShader from "./FlowerCardShader";
import FlowerCardStamp from "./FlowerCardStamp";
import FlowerCardTitle from "./FlowerCardTitle";
import FlowerWorld from "./FlowerWorld";
import { gui, gui_vec3 } from "./GUI";
import { makeSimplePlane } from "./utils";

export default class FlowerCard {
    id: number = -1;
    group: Group;
    mesh: Mesh;
    cardHitBox: Mesh;
    cardMaterial: ShaderMaterial;
    cardTitle: FlowerCardTitle;
    cardStamp: FlowerCardStamp;
    cardOption: CardOptionType;

    size: any = {
        width: 278,
        height: 389,
    };

    naviSize: any = {
        width: 278,
        height: 389,
    };

    fullSize: any = {
        width: 278,
        height: 389,
    };
    params: any = {
        border: 10,
        borderBottom: 90,
        // border: 0,
        // borderBottom: 0,
        freq: 0,
        amp: 0,
        timeSpeed: 0.04,
        uRotY: 0,
        progress: 0,
        _progress: -1,
    };
    time: number = 0;
    cardTimeline: GSAPTimeline;
    geometryGsap: GSAPAnimation;
    isAnimation: Boolean = false;
    scene: Scene;
    camera: PerspectiveCamera;
    rtt: WebGLRenderTarget;
    rttScale: number = 2;

    cardContainer: Object3D;
    cardBox: mesh;
    isHide: boolean = false;

    constructor(cardData: FlowerDataType, id = -1, cardTexture: Texture, flowerWorld: FlowerWorld) {
        this.id = id;

        this.cardOption = Object.assign({}, config.cardOption);

        /*
            card shape for mask with rtt
        */

        this.scene = new Scene();
        this.scene.background = new Color(0x000000);
        //@ts-ignore
        let baseWebgl: BaseWebGL = config.baseWebgl;
        this.camera = baseWebgl.camera.clone();
        this.rtt = new WebGLRenderTarget(
            baseWebgl.stage.width * this.rttScale,
            baseWebgl.stage.height * this.rttScale,
            {
                depthBuffer: true,
                stencilBuffer: false,
            }
        );

        this.cardContainer = new Object3D();
        this.cardBox = new Mesh(
            new PlaneGeometry(config.cardOption.size.width, config.cardOption.size.height, 1, 1),
            new MeshBasicMaterial({ color: 0xffffff, side: DoubleSide })
        );
        this.cardContainer.add(this.cardBox);
        this.scene.add(this.cardContainer);

        /*
            card
        */
        //@ts-ignore
        let material = FlowerCardShader();
        material.uniforms.tDiffuse.value = cardTexture;
        material.uniforms.tDiffuseMask.value = this.rtt.texture;

        // material.uniforms.uFrameResolution.value.x = this.size.width;
        // material.uniforms.uFrameResolution.value.y = this.size.height;
        // material.uniforms.uResolution.value.x = window.innerWidth;
        // material.uniforms.uResolution.value.y = window.innerHeight;
        // material.uniforms.uFrameResolution.value.x = config.cardOption.size.width;
        // material.uniforms.uFrameResolution.value.y = config.cardOption.size.height;

        // material.uniforms.uResolution.value.x = config.cardOption.size.width;
        // material.uniforms.uResolution.value.y = config.cardOption.size.height;

        material.uniforms.uColor1.value = new Color(cardData.bgColors[0]);
        material.uniforms.uColor2.value = new Color(cardData.bgColors[1]);
        // material.depthWrite = true;
        // material.depthTest = true;
        material.transparent = true;

        /*
            card 
        */
        this.group = new Group();
        this.cardMaterial = new ShaderMaterial(material);
        this.mesh = new Mesh(
            // new PlaneGeometry(this.size.width, this.size.height),
            new PlaneGeometry(baseWebgl.stage.width, baseWebgl.stage.height),
            this.cardMaterial
        );
        // if (id == 0) {
        this.group.add(this.mesh);
        // }

        this.cardHitBox = makeSimplePlane(
            new Vector3(),
            this.size.width,
            this.size.height,
            0x000000,
            { depthWrite: false, depthTest: false, transparent: true, opacity: 0 }
        );

        //@ts-ignore
        this.cardHitBox._id = this.id;
        this.cardHitBox.scale.set(1.2, 1.2, 1.2);
        this.cardHitBox.renderOrder = 20;

        /*
            card Title
        */
        this.cardTitle = new FlowerCardTitle();
        this.cardTitle.setup(cardData.textures.cardTitle).then(() => {
            this.group.add(this.cardTitle.mesh);
            this.cardTitle.mesh.renderOrder = 10;
            this.cardTitle.resize(this.size, this.params.borderBottom);
        });

        /*
            card Stamp
        */

        this.cardStamp = new FlowerCardStamp();
        this.cardStamp.setup(cardData.textures.cardStamp).then(() => {
            this.group.add(this.cardStamp.mesh);
            this.cardStamp.mesh.renderOrder = 11;
            this.cardStamp.resize(this.size);
        });

        this.changeShaderParams();
    }

    resize(stageSize) {
        // material.uniforms.uFrameResolution.value.x = this.size.width;
        // material.uniforms.uFrameResolution.value.y = this.size.height;

        this.fullSize.width = stageSize.width;
        this.fullSize.height = stageSize.height;

        this.cardMaterial.uniforms.uResolution.value.x = stageSize.width;
        this.cardMaterial.uniforms.uResolution.value.y = stageSize.height;
        this.cardMaterial.uniforms.uFrameResolution.value.x = stageSize.width;
        this.cardMaterial.uniforms.uFrameResolution.value.y = stageSize.height;

        this.mesh.geometry.dispose();
        this.mesh.geometry = new PlaneGeometry(stageSize.width, stageSize.height);
        this.cardMaterial.uniforms.uCardResolution.value.x = this.cardOption.size.width;
        this.cardMaterial.uniforms.uCardResolution.value.y = this.cardOption.size.height;

        this.rtt.setSize(stageSize.width * this.rttScale, stageSize.height * this.rttScale);

        if (this.cardTitle && this.cardTitle.isReady) {
            // calc bottom
            this.cardTitle.resize(this.size, this.params.borderBottom);
        }

        if (this.cardStamp && this.cardStamp.isReady) {
            // alight right
            this.cardStamp.resize(this.size);
        }

        this.changeShaderParams();
    }

    async cardOn(_duration = 1.2, _delay = 1.2) {
        return new Promise<void>((resolve) => {
            if (this.cardTimeline) this.cardTimeline.kill();
            let duration = _duration;
            let delay = _delay;

            // this.changeGeometry("navi", 1.5);

            this.cardTimeline = gsap.timeline({
                delay: 1.2,
                onComplete: () => {
                    this.isAnimation = false;
                    resolve();
                },
                onStart: () => {
                    this.isAnimation = true;
                    setTimeout(() => {
                        this.cardTitle.fadeIn();
                        this.cardStamp.fadeIn();
                    }, duration * 1000);
                },
            });

            this.cardTimeline.to(
                this.params,
                {
                    border: config.cardOption.border,
                    borderBottom: config.cardOption.borderBottom,
                    progress: 0,
                    duration: duration,
                    ease: "Power4.easeInOut",
                },
                "-=" + duration
            );
            this.cardTimeline.to(
                this.group.position,
                {
                    z: 0,
                    duration: duration,
                    ease: "Power4.easeInOut",
                },
                "-=" + duration
            );
        });
    }

    async cardOff(_duration = 1.2, _delay = 1.2) {
        return new Promise<void>((resolve) => {
            if (this.cardTimeline) this.cardTimeline.kill();
            let duration = _duration;

            this.cardTimeline = gsap.timeline({
                onStart: () => {
                    this.isAnimation = true;
                },
                onComplete: () => {
                    this.isAnimation = false;
                    resolve();
                },
            });

            this.cardTitle.fadeOut(0.5);
            this.cardStamp.fadeOut(0.5);

            // this.changeGeometry("full", 1, 0.5);

            this.cardTimeline.to(this.params, {
                border: 0,
                borderBottom: 0,
                duration: 0.5,
                ease: "Power4.easeInOut",
            });

            this.cardTimeline.to(
                this.params,
                {
                    progress: 1,
                    duration: duration,
                    ease: "Power4.easeInOut",
                },
                "-=" + 0.5
            );
        });
    }

    async cardShow(_duration = 1, _delay = 0) {
        return new Promise<void>((resolve) => {
            if (this.cardTimeline) this.cardTimeline.kill();
            let duration = _duration;
            this.cardTimeline = gsap.timeline({
                onStart: () => {
                    this.isAnimation = true;
                    this.group.visible = true;
                    this.isHide = false;
                },
                onComplete: () => {
                    this.isAnimation = false;
                    resolve();
                },
            });

            this.cardTimeline.to(this.cardBox.scale, {
                y: 1,
                duration: duration,
                delay: _delay,
                ease: "Power4.easeInOut",
            });
            this.cardTitle.fadeIn(0.5, duration + _delay);
            this.cardStamp.fadeIn(0.5, duration + _delay);
        });
    }

    async cardHide(_duration = 1, _delay = 0) {
        return new Promise<void>((resolve) => {
            this.cardTitle.fadeOut(0.5);
            this.cardStamp.fadeOut(0.5);

            if (this.cardTimeline) this.cardTimeline.kill();
            let duration = _duration;
            this.cardTimeline = gsap.timeline({
                onStart: () => {
                    this.isAnimation = true;
                },
                onComplete: () => {
                    this.isAnimation = false;
                    this.group.visible = false;
                    this.isHide = true;
                    resolve();
                },
            });
            this.cardTimeline.to(this.cardBox.scale, {
                y: 0,
                duration: duration,
                delay: _delay,
                ease: "Power4.easeInOut",
            });
        });
    }

    changeShaderParams() {
        this.cardMaterial.uniforms.uAmp.value = this.params.amp;
        this.cardMaterial.uniforms.uFreq.value = this.params.freq;
        this.cardMaterial.uniforms.uBorder.value = this.params.border;
        this.cardMaterial.uniforms.uBorderBottom.value = this.params.borderBottom;
        this.cardMaterial.uniforms.uRotY.value = this.params.uRotY;
    }

    changeGeometry(mode = "navi", duration = 1, delay = 0) {
        return new Promise<void>((resolve) => {
            if (this.geometryGsap) this.geometryGsap.kill();
            let tgSize = mode == "navi" ? this.naviSize : this.fullSize;
            this.geometryGsap = gsap.to(this.size, {
                duration: duration,
                width: tgSize.width,
                // height: tgSize.height,
                delay: delay,
                ease: "Power4.easeInOut",
                onUpdate: () => {
                    this.mesh.geometry.dispose();
                    this.mesh.geometry = new PlaneGeometry(this.size.width, this.size.height);
                    this.cardMaterial.uniforms.uFrameResolution.value.x = this.size.width;
                    this.cardMaterial.uniforms.uFrameResolution.value.y = this.size.height;
                },
                onComplete: () => {
                    resolve();
                },
            });
        });
    }

    update(containerX = 0, mouse = { _rx: 0, _ry: 0 }) {
        if (this.isHide) return;
        this.time += this.params.timeSpeed;
        this.cardMaterial.uniforms.uTime.value = this.time;
        let progress = this.params.progress;
        if (this.params._progress != this.params.progress) {
            let diffW = window.innerWidth - this.size.width;
            let diffH = window.innerHeight - this.size.height;
            let w = this.size.width + diffW * progress;
            let h = this.size.height + diffW * progress;
            this.cardMaterial.uniforms.uCardResolution.value.x = w;
            this.cardMaterial.uniforms.uCardResolution.value.y = h;
        }
        if (this.cardBox.position.z != config.baseWebgl.cardFitZ) {
        }
        this.cardBox.position.z = this.params.progress * config.baseWebgl.cardFitZ;

        this.params._progress = this.params.progress;

        if (this.isAnimation) {
        }
        this.changeShaderParams();
    }

    setGui(_gui: gui) {
        let g = _gui.addFolder("card" + this.id).close();

        gui_vec3(g, this.group.position, {
            folder: "position",
            range: 5000,
            threshold: 0.1,
        }).close();

        gui_vec3(g, this.group.rotation, {
            folder: "rotation",
            range: Math.PI * 2,
            threshold: Math.PI / 180,
        }).close();

        g.add(this, "cardOn");
        g.add(this, "cardOff");

        g.add(this.params, "timeSpeed", -1, 1, 0.01);

        g.add(this.params, "freq", -10, 10, 0.01).onChange(() => {
            this.changeShaderParams();
        });
        g.add(this.params, "amp", -10, 10, 0.01).onChange(() => {
            this.changeShaderParams();
        });
        g.add(this.params, "border", 0, 100, 0.01).onChange(() => {
            this.changeShaderParams();
        });
        g.add(this.params, "borderBottom", 0, 100, 0.01).onChange(() => {
            this.changeShaderParams();
        });

        g.add(this.params, "uRotY", -Math.PI * 2, Math.PI * 2, 0.01).onChange((value) => {
            this.cardBox.rotation.y = value;
            // this.changeShaderParams();
        });

        g.add(this.params, "progress", 0, 1, 0.001).onChange(() => {
            this.changeShaderParams();
        });
    }
}
