import { Mesh, Plane, PlaneGeometry, ShaderMaterial, Vector2 } from "three";
import { stageSizeType } from "./BaseWebGL";
import FlowerShadowShader from "./FlowerShadowShader";
import { gui } from "./GUI";

export default class FlowerShadow {
    name: string = "flowerShadow";
    mesh: Mesh;
    material: ShaderMaterial;
    params = {
        waveX: 0.2,
        waveY: 0.03,
        time: 0,
        scale: 0.27,
        x: 0.1,
        y: -0.07,
        rotation: 0,
        speed: 0.2,
        opacity: 0,
    };
    stageSize: stageSizeType;
    isReady: boolean = false;
    constructor() {}
    setup(texture, width = 100, height = 100) {
        let geometry = new PlaneGeometry(width, height, 1);
        this.material = new ShaderMaterial(FlowerShadowShader());
        this.material.uniforms.tDiffuse.value = texture;
        this.material.uniforms.uFrameResolution.value = new Vector2(width, height);
        this.material.uniforms.uResolution.value = new Vector2(
            texture.image.width,
            texture.image.height
        );
        this.material.depthTest = false;
        this.updateUniforms();
        this.mesh = new Mesh(geometry, this.material);
        this.isReady = true;
    }

    updateUniforms() {
        this.material.uniforms.uFreq.value = this.params.waveY;
        this.material.uniforms.uAmp.value = this.params.waveX;
        this.material.uniforms.uScale.value = this.params.scale;
        this.material.uniforms.uRotation.value = (this.params.rotation / 180) * Math.PI;
        this.material.uniforms.uPosition.value.x = this.params.x;
        this.material.uniforms.uPosition.value.y = this.params.y;
        this.material.uniforms.uOpacity.value = this.params.opacity;
    }
    update() {
        this.material.uniforms.uTime.value += this.params.speed;
    }

    resize(stageSize: stageSizeType = this.stageSize) {
        if (!this.isReady) return;
        this.stageSize = Object.assign(stageSize, stageSize);
        this.mesh.geometry.dispose();
        this.mesh.geometry = new PlaneGeometry(stageSize.width, stageSize.height, 1);
        // this.material.uniforms.uResolution.value = new Vector2(
        //     stageSize.width,
        //     stageSize.height
        // );
    }

    setGui(_gui: gui) {
        let g = _gui.addFolder(this.name);
        g.add(this.params, "waveX", -300, 300, 0.1).onChange(() => {
            this.updateUniforms();
        });
        g.add(this.params, "waveY", -10, 10, 0.01).onChange(() => {
            this.updateUniforms();
        });
        g.add(this.params, "scale", 0, 1, 0.01).onChange(() => {
            this.updateUniforms();
        });

        g.add(this.params, "x", -1, 1, 0.01).onChange(() => {
            this.updateUniforms();
        });
        g.add(this.params, "y", -1, 1, 0.01).onChange(() => {
            this.updateUniforms();
        });

        g.add(this.params, "rotation", -180, 180, 1).onChange(() => {
            this.updateUniforms();
        });
        g.add(this.params, "speed", -1, 1, 0.01).onChange(() => {
            this.updateUniforms();
        });
        g.add(this.params, "opacity", 0, 1, 0.01).onChange(() => {
            this.updateUniforms();
        });
    }
}
