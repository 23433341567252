import gsap from "gsap";
import { Mesh, MeshBasicMaterial, PlaneGeometry, TextureLoader } from "three";

export default class FlowerCardStamp {
    mesh: Mesh;
    texture: Texture;
    material: MeshBasicMaterial;
    size: any = {
        width: 60,
        height: 60,
        scale: 0.25,
    };
    isReady: boolean = false;
    gsap: GSAPAnimation;
    constructor() {}
    async setup(texture) {
        return new Promise<this>((resolve) => {
            //@ts-ignore
            let t: Texture = texture;
            this.size.width = t.image.width;
            this.size.height = t.image.height;
            this.texture = t;
            let geometry = new PlaneGeometry(t.image.width, t.image.height, 1);
            this.material = new MeshBasicMaterial({
                map: t,
                transparent: true,
                // depthTest: false,
                // depthWrite: false,
            });

            this.mesh = new Mesh(geometry, this.material);
            let scale = this.size.scale;
            this.mesh.scale.set(scale, scale, scale);

            this.isReady = true;
            resolve(this);
            // });
        });
    }
    fadeIn(duration = 1, _delay = 0) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this.mesh.material, {
            duration: duration,
            delay: _delay,
            opacity: 1,
            ease: "Power2.easeInOut",
        });
    }

    fadeOut(duration = 1) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this.mesh.material, {
            duration: duration,
            opacity: 0,
            ease: "Power2.easeInOut",
        });
    }

    resize(cardSize) {
        let x = cardSize.width * 0.5 - this.size.width * this.size.scale * 0.5;

        // align top
        let y = cardSize.height * 0.5 - this.size.height * this.size.scale * 0.5;
        this.mesh.position.x = x - 20;
        this.mesh.position.y = y - 20;
    }
}
