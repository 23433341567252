import { Texture, Vector2, Vector3 } from "three";
import BaseWebGL from "./BaseWebGL";
import flower1 from "./flowerData/flower1";
import flower2 from "./flowerData/flower2";

interface FlowerTexturesType {
    cardTitle: string;
    cardStamp: string;
    bg: string;
}

interface FlowerDataType {
    url: string;
    name: string;
    paths: Array<number>;
    textures: FlowerTexturesType;
    svg: any;
    bgColors: Array<number>;
    points: Array<number>;
    intersected: Array<any>;
}

interface CardOptionType {
    border: number;
    borderBottom: number;
    size: any;
}

export { FlowerDataType, CardOptionType };

export default {
    baseWebgl: BaseWebGL,
    mediaQuery: {
        sm: 640,
        // => @media (min-width: 640px) { ... }

        md: 768,
        // => @media (min-width: 768px) { ... }

        lg: 1024,
        // => @media (min-width: 1024px) { ... }

        xl: 1280,
        // => @media (min-width: 1280px) { ... }

        "2xl": 1536,
        // => @media (min-width: 1536px) { ... }
    },
    colors: {
        gray: 0x57554e,
    },
    cardOption: {
        border: 10,
        borderBottom: 90,
        size: {
            width: 278,
            height: 389,
            aspect: 389 / 278,
        },
    },
    entrance: {
        logo: {
            position: {
                base: new Vector2(0, 45),
                md: new Vector2(0, 75),
            },
        },
        textures: {
            floor: "./assets/images/bg_floor.jpg",
            shadow: "./assets/images/shadow.png",
            cannesLogo: "/assets/images/cannes-logo.png",
            lionsgoodnews: "/assets/images/lionsgoodnews2023.png",
            flowerShadow: "/assets/images/flower/flower1-shape.png",
        },
    },

    textures: {
        floor: "./assets/images/bg_floor.jpg",
        shadow: "./assets/images/shadow.png",
        cannesLogo: "/assets/images/cannes-logo.png",
        lionsgoodnews: "/assets/images/lionsgoodnews2023.png",
    },

    flowers: [
        {
            url: "/assets/images/flower/flower1.svg",
            name: "flower1",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient1.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0xf8a35a, 0xf4f3e7],
            paths: [],
            points: flower1.points,
            intersected: flower1.intersectied,
        },
        {
            url: "/assets/images/flower/flower2.svg",
            name: "flower2",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient2.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0x7d9fdc, 0xf4f3e7],
            paths: [],
            points: flower2.points,
            intersected: flower2.intersectied,
        },
        {
            url: "/assets/images/flower/flower1.svg",
            name: "flower3",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient3.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0xf5bc38, 0xf4f3e7],
            paths: [],
            points: flower1.points,
            intersected: flower1.intersectied,
        },
        {
            url: "/assets/images/flower/flower2.svg",
            name: "flower4",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient4.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0x81c139, 0xf4f3e7],
            paths: [],
            points: flower2.points,
            intersected: flower2.intersected,
        },
        {
            url: "/assets/images/flower/flower1.svg",
            name: "flower5",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient5.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0xf5bc38, 0xf4f3e7],
            paths: [],
            points: flower1.points,
            intersected: flower1.intersected,
        },
        {
            url: "/assets/images/flower/flower2.svg",
            name: "flower6",
            textures: {
                cardTitle: "/assets/images/flower/card-title1.png",
                cardStamp: "/assets/images/flower/card-stamp.png",
                bg: "/assets/images/flower/bg-gradient6.jpg",
                shadow: "/assets/images/flower/flower1-shape.png",
            },
            svg: null,
            bgColors: [0x81c139, 0xf4f3e7],
            paths: [],
            points: flower2.points,
            intersected: flower2.intersected,
        },
    ],
};
