import { Color, DoubleSide, Vector2, Vector3 } from "three";

export default function () {
    return {
        uniforms: {
            uTime: { type: "f", value: 0 },
            uResolution: { type: "v2", value: new Vector2() },
            uColor1: { type: "v3", value: new Color(0xffffff) },
            uColor2: { type: "v3", value: new Color(0x000000) },
            opacity: { type: "f", value: 1.0 },
        },

        side: DoubleSide,

        vertexShader: `
            varying vec2 vUv;
        
            void main() {
                vUv = uv;
                gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
            }
        `,

        fragmentShader: `
    
        uniform float opacity;
        uniform vec2 uResolution;
        uniform vec3 uColor1;
        uniform vec3 uColor2;
    
        varying vec2 vUv;
        varying float depth;
    
        void main() {
            vec4 gColor = mix(vec4(uColor2,1.0),vec4(uColor1,1.),vUv.y);
            // float borderX =  15. / uResolution.x;
            // float borderY =  15. / uResolution.y;
            // if( vUv.x < borderX || vUv.x > 1.0 - borderX) gColor = vec3(1.0);
            // if( vUv.y < borderY * 4. || vUv.y > 1.0 - borderY) gColor = vec3(1.0);
            gl_FragColor = gColor;//vec4(gColor,opacity);
          }
        `,
        transparent: true,
    };
}
