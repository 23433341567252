import { Vector2 } from "three";

const LastScreenShader = {
    uniforms: {
        uTime: { type: "f", value: 0 },
        tDiffuse: { type: "t", value: null },
        tDiffuse2: { type: "t", value: null },
        uOpacity: { type: "f", value: 1.0 },
        uTransition: { type: "f", value: 0.0 },
        uAmp: { type: "f", value: 0 },
        uFreq: { type: "f", value: 0 },
        uResolution: { type: "v2", value: new Vector2(1.0) },
        noiseBrightness: { value: 1 },
        noiseIntensity: { value: 0.05 },
    },

    vertexShader: `
    varying vec2 vUv;

    void main() {
      vUv = uv;
      gl_Position = projectionMatrix * modelViewMatrix * vec4( position, 1.0 );
    }
    `,

    fragmentShader: `
    #define PI 3.1415926535897932384626433832795
    uniform sampler2D tDiffuse;
    uniform sampler2D tDiffuse2;
    uniform vec2 uResolution;
    uniform float uOpacity;
    uniform float uTransition;
    uniform float uAmp;
    uniform float uFreq;
    uniform float uTime;
    uniform float noiseBrightness;
    uniform float noiseIntensity;

    varying vec2 vUv;

    float random(vec2 co){
      return fract(sin(dot(co.xy,vec2(12.9898,78.233))) * 43758.5453);
    }

    vec2 computeUV( vec2 uv, float k, float kcube ){
    
      vec2 t = uv - .5;
      float dist = t.x * t.x + t.y * t.y;
      float f = 0.;
      
      if( kcube == 0.0){
          f = 1. + dist * k;
      }else{
          f = 1. + dist * ( k + kcube * sqrt( dist ) );
      }
      
      vec2 nUv = sin(f) * t + .5;
      // nUv.y = nUv.y;
      return nUv;
      
    }

    void main() {

        // vec2 noiseUV = vUv;
        // noiseUV *= sin(uTime);
        // float r = random(noiseUV);
        // vec3 noise = vec3(r * noiseBrightness);

        float aspect = uResolution.y / uResolution.x;

        vec2 center = vec2(0.5);
        float distX =  center.x - vUv.x;
        float distY =  center.y - vUv.y;
        float rad = (distX * distX + distY * distY);
        float ripple = sin(rad * uFreq) * uAmp;

        vec2 dist = center - vec2(0.0);
        if(dist.y < 0.5)dist.y = 0.5 + 0.5 - dist.y;
        if(dist.x < 0.5)dist.x = 0.5 + 0.5 - dist.x;
        dist.y *= aspect;
        float fullRadius = sqrt(dist.x * dist.x + dist.y * dist.y);


        vec2 uv = vUv * 2. -1.0;
        uv.y *= aspect;

        // Make the distance field

        float d = length(uv);
        d *= fullRadius;


        // float prog1 = (uTransition * 2.0 - 1.0);
        float prog1 = abs(uTransition - 0.5) / 0.5;
        float prog2 = 1.0 - abs((uTransition - 0.5) / 0.5);
        // d = 1.0 + sin(pow(d,uFreq)) * uAmp;
        // d = sin(d * fullRadius * 2.0 + uFreq * PI) * uAmp;


        // d = sin(d * uFreq * uTransition) * uAmp * uTransition;
        float d1 = sin(d * uFreq * uTransition) * uAmp * uTransition;
        vec2 tUv = vUv;
        // tUv -= vec2(0.5);
        // tUv *= 1.0 + d1;
        // tUv += vec2(0.5);

        // prog2 = (1.0 - uTransition);
        // float d2 = sin(pow(d, -uFreq * prog2) + prog2) * -uAmp * prog2;
        // vec2 tUv2 = vUv;
        // tUv2 -= vec2(0.5);
        // tUv2 *= 1.0 + 0.0;
        // tUv2 += vec2(0.5);

        vec4 tColor1 = texture2D(tDiffuse,tUv);
    		vec4 tColor2 = texture2D(tDiffuse2,tUv);
        vec4 tColor = mix(tColor1,tColor2,uTransition);

        // vec3 destColor = mix(tColor.rgb,noise,noiseIntensity);

        // gl_FragColor = tColor;
        gl_FragColor = vec4(tColor.rgb ,1.0);

      }
    `,
};

export { LastScreenShader };
