import {
    Color,
    DirectionalLight,
    DoubleSide,
    Euler,
    Object3D,
    PerspectiveCamera,
    PlaneGeometry,
    Scene,
    ShaderMaterial,
    SphereGeometry,
    TextureLoader,
    Vector2,
    Vector3,
    WebGLRenderTarget,
} from "three";
import { Mesh } from "three";
import { ShapeGeometry } from "three";
import { MeshBasicMaterial } from "three";
import { Group } from "three";
import { getIntersectionPoints } from "./intersectionUtils";
import { gui, gui_vec3 } from "./GUI";
import { SVGLoader } from "./lib/SVGLoader";
import PathLine from "./lib/PathLine";
import gsap from "gsap";
import FlowerLeafShader from "./shaders/FlowerLeafShader";
import FlowerBackgroundShader from "./shaders/FlowerBackgroundShader";
import config, { FlowerDataType } from "./config";
import FlowerCardStamp from "./FlowerCardStamp";
import FlowerCardTitle from "./FlowerCardTitle";
import FlowerWorld from "./FlowerWorld";
import GUI from "lil-gui";

interface FlowerParamsType {
    border: number;
    borderBottom: number;
    timeSpeed: number;
    freq: number;
    amp: number;
    leadDepth: number;
    f_freq: number; // flower_freq
    f_wave: number; // flower_wave
    cameraMove: number;
    extendProgress: number;
}

interface FlowerSizeType {
    x: number;
    y: number;
    width: number;
    height: number;
}

interface leafType {
    mesh: Mesh;
    opacity: number;
}

/*
    group > flowerGroup
*/
export default class Flower {
    linePoints: Array<number>;
    name: string = "flower";
    id: number = 0;
    leaves: Array<leafType> = [];

    cardGroup: Group;
    cardMesh: Mesh;
    cardMaterial: ShaderMaterial;
    cardTitle: FlowerCardTitle;
    cardStamp: FlowerCardStamp;
    cardHitBox: Mesh;

    capsuleMesh: Mesh;

    dirLight: DirectionalLight;

    group: Group;
    scene: Scene;
    camera: PerspectiveCamera;
    rtt: WebGLRenderTarget;
    rttScale: number = 2;
    background: Mesh;
    backgroundMaterial: ShaderMaterial;

    flowerGroup: Group;
    flowerSize: FlowerSizeType;
    ball: Mesh;

    mainPathLine: PathLine;
    mainPathLinePositions: Array<Vector3> = [];
    intersectedPosition: Array<any> = [];
    intersectedMesh: Array<Mesh> = [];

    particleGroup: Group = new Group();
    particles: Array<Mesh> = [];

    isOnScreen: boolean = true;
    isNaviMode: boolean = false;

    progress: number = 0;
    passedId: number = -1;

    viewPort: any = {
        x: 0,
        y: 0,
        width: 278,
        height: 389,
    };

    sizes: any = {
        base: {
            width: 278,
            height: 389,
        },
        normal: {
            width: 1,
            height: 1,
        },
    };

    duration: number = 4;
    time: number = 0;
    isTransform: boolean = true;
    loop: boolean = true;
    lineVisible: boolean = true;
    lookAt: boolean = true;
    lookAtPosition: Vector3 = new Vector3(0, 0, 0);

    gsap: GSAPAnimation;
    timeline: GSAPTimeline;

    params: FlowerParamsType = {
        timeSpeed: 0.04,
        freq: 0,
        amp: 0,
        border: 10,
        borderBottom: 90,
        leadDepth: 0,
        f_freq: 0,
        f_wave: 0,
        cameraMove: 1000,
        extendProgress: 0,
        // leadDepth: 100,
        // f_freq: 10 * (Math.random() * 2 - 1),
        // f_wave: 4 * (Math.random() * 2 - 4),
    };
    baseWebgl: FlowerWorld;
    data: FlowerDataType;

    constructor(data: FlowerDataType, id = 0, stageSize, baseWebgl: FlowerWorld) {
        this.data = data;
        this.name = data.name;
        this.id = id;
        this.baseWebgl = baseWebgl;

        this.group = new Group();
        this.flowerGroup = new Group();
        this.flowerGroup.position.z = 0;
        this.group.add(this.flowerGroup);

        this.flowerSize = data.svg.viewBox.animVal;
        this.backgroundMaterial = new ShaderMaterial(FlowerBackgroundShader());
        this.backgroundMaterial.uniforms.uColor1.value = new Color(data.bgColors[0]);
        this.backgroundMaterial.uniforms.uColor2.value = new Color(data.bgColors[1]);
        this.backgroundMaterial.uniforms.uResolution.value = new Vector2(
            stageSize.width,
            stageSize.height
        );

        this.background = new Mesh(
            new PlaneGeometry(stageSize.width, stageSize.height),
            this.backgroundMaterial
        );

        let sceneBgTexture = new TextureLoader().load(data.textures.bg);

        this.scene = new Scene();

        //@ts-ignore
        this.scene.background = data.textures.bg; //new Color(data.bgColors[0]);
        this.camera = baseWebgl.camera.clone();

        // this.scene.add(this.background);
        this.scene.add(this.group);

        this.rtt = new WebGLRenderTarget(
            stageSize.width * this.rttScale,
            stageSize.height * this.rttScale,
            {
                depthBuffer: true,
                stencilBuffer: false,
            }
        );

        /*
            ball
        */
        this.ball = new Mesh(
            new SphereGeometry(5, 5, 5),
            new MeshBasicMaterial({ color: 0x00ffff, side: DoubleSide, wireframe: false })
        );

        this.ball.position.z = 10;
        this.ball.renderOrder = 100;
        this.ball.visible = false;
        // this.group.add(this.ball);

        let scale = (window.innerHeight / this.flowerSize.height) * 0.8;
        // this.flowerGroup.scale.set(scale, scale, 1);

        // this.setGui();
    }

    async setup(stageSize) {
        return new Promise<void>((resolve) => {
            /*
                make line width data path
                check intersection
            */
            this.data.paths.forEach((path: any) => {
                let subPath = path.subPaths[0];
                let positions: Array<Vector3> = [];
                let points: Array<number> = [];
                let intersectionPoints: Array<number> = [];

                if (path.userData.node.id == "line") {
                    let pahNode = path.userData.node;
                    let length = pahNode.getTotalLength();

                    if (1) {
                        points = this.data.points;
                        for (let i = 0; i < points.length; i += 3) {
                            this.mainPathLinePositions.push(
                                new Vector3(points[i + 0], points[i + 1], points[i + 2])
                            );
                            intersectionPoints.push(points[i + 0], points[i + 1]);
                        }
                    } else {
                        for (let i = 0, n = length; i < n; i += 2) {
                            let v2 = pahNode.getPointAtLength(i);
                            let x = v2.x - Number(this.flowerSize.width * 0.5);
                            let y = v2.y * -1 + this.flowerSize.height * 0.5;
                            points.push(x, y, 0);
                            this.mainPathLinePositions.push(new Vector3(x, y, 0));
                            intersectionPoints.push(x, y);
                        }
                    }

                    this.mainPathLinePositions.reverse();
                    this.mainPathLine = new PathLine("mainPath", points);
                    this.flowerGroup.add(this.mainPathLine.mesh);

                    //@ts-ignore
                    // this.intersectedPosition = [].concat(this.data.intersected);
                    console.log(`🚀 ~ this.intersectedPosition`, this.intersectedPosition);

                    getIntersectionPoints(intersectionPoints).then((intersected: any) => {
                        this.intersectedPosition = intersected.reverse();
                        // this.intersectedPosition.forEach((data, i) => {
                        //     let mesh = makeSimpleBox(new Vector3(data.x, data.y, 5), 5, 5, 5);
                        //     mesh.visible = false;
                        //     data["mesh"] = mesh;
                        //     this.intersectedMesh.push(mesh);
                        //     this.flowerGroup.add(mesh);
                        // });
                    });
                } else {
                    let fillColor = path.userData.node.style.fill;

                    const shapes = SVGLoader.createShapes(path);
                    let material = new ShaderMaterial(FlowerLeafShader());
                    material.transparent = true;
                    material.side = DoubleSide;
                    material.uniforms.uViewportSize.value.x = stageSize.width;
                    material.uniforms.uViewportSize.value.y = stageSize.height;
                    material.uniforms.uStrength.value = this.params.f_wave;
                    material.uniforms.uFreq.value = this.params.f_freq;
                    material.uniforms.uTransform.value = this.isTransform;
                    material.depthTest = true;
                    material.depthWrite = true;

                    material.uniforms.opacity.value = 0.2;
                    material.uniforms.uColor.value = new Color().setStyle(fillColor);
                    // .convertSRGBToLinear();

                    for (let i = 0; i < shapes.length; i++) {
                        const shape = shapes[i];
                        const geometry = new ShapeGeometry(shape);

                        // @ts-ignore
                        geometry.index?.array.reverse();

                        // geometry.attributes.position.array
                        for (let i = 0; i < geometry.attributes.position.array.length; i += 3) {
                            let arr = geometry.attributes.position.array as Array<number>;
                            arr[i + 0] += -this.flowerSize.width * 0.5;
                            arr[i + 1] += -this.flowerSize.height * 0.5;
                            arr[i + 1] *= -1;
                        }

                        const mesh = new Mesh(geometry, material);
                        mesh.material.uniforms.opacity.value = 0;
                        this.flowerGroup.add(mesh);
                        this.leaves.push({ mesh: mesh, opacity: 0 });
                    }
                }
            });

            resolve();
        });
    }

    drawIn(to = 2) {
        if (this.timeline) this.timeline.kill();

        this.progress = 0;
        this.checkPosition(this.progress);
        this.timeline = gsap.timeline({
            onComplete: () => {
                // if (this.loop) this.draw();
            },
        });
        this.timeline.yoyo(true);
        this.timeline.to(this, {
            progress: to,
            duration: this.duration,
            ease: "Power2.easeInOut",
            onUpdate: () => {
                this.updateLine(this.progress);
            },
        });
    }

    drawOut() {
        if (this.timeline) this.timeline.kill();

        this.progress = 1;
        this.checkPosition(this.progress);
        this.timeline = gsap.timeline({
            onComplete: () => {
                // if (this.loop) this.draw();
            },
        });
        this.timeline.yoyo(true);
        this.timeline.to(this, {
            progress: 2,
            duration: this.duration,
            ease: "Power4.easeInOut",
            onUpdate: () => {
                this.updateLine(this.progress);
            },
        });
    }

    resize({ width, height }) {
        let scale = (window.innerHeight / this.flowerSize.height) * 0.8;
        let aspect = width / height;
        // this.flowerGroup.scale.set(scale, scale, 1);

        this.rtt.setSize(width * this.rttScale, height * this.rttScale);

        // this.flowerGroup.position.y = -(height - this.flowerSize.height * scale) * 0.5 - 2 + 15 * 4;

        this.leaves.forEach((leaf: leafType) => {
            let mat: ShaderMaterial = leaf.mesh.material as ShaderMaterial;
            mat.uniforms.uViewportSize.value.x = width;
            mat.uniforms.uViewportSize.value.y = height;
        });

        this.background.geometry.dispose();
        this.background.geometry = new PlaneGeometry(width, height);

        this.sizes.normal.width = width;
        this.sizes.normal.height = height;
    }

    setGui(_gui: gui) {
        let g = _gui.addFolder(this.name).close();

        let gConainer = g.addFolder("container").close();
        let gCamera = g.addFolder("cammera").close();
        let gViewPort = g.addFolder("viewport").close();

        gViewPort.add(this.viewPort, "x", -2000, 2000, 1);
        gViewPort.add(this.viewPort, "y", -2000, 2000, 1);
        gViewPort.add(this.viewPort, "width", 0, 2000, 1);
        gViewPort.add(this.viewPort, "height", 0, 2000, 1);

        gui_vec3(gConainer, this.group.position, {
            folder: "position",
            range: 5000,
            threshold: 0.1,
        }).close();

        gui_vec3(gConainer, this.group.rotation, {
            folder: "rotation",
            range: Math.PI * 2,
            threshold: Math.PI / 180,
        }).close();

        gui_vec3(gCamera, this.camera.position, {
            folder: "position",
            range: 5000,
            threshold: 0.1,
        }).close();

        gui_vec3(gCamera, this.camera.rotation, {
            folder: "rotation",
            range: Math.PI * 2,
            threshold: Math.PI / 180,
        }).close();

        // g.add(this, "lookAt");

        // gui_vec3(gConainer, this.lookAtPosition, {
        //     folder: "lookAt Position",
        //     range: 5000,
        //     threshold: 0.1,
        // }).close();

        let gFlower = g.addFolder("flower");
        gFlower.add(this, "lineVisible").onChange((value) => {
            this.mainPathLine.mesh.visible = value;
        });

        gFlower.add(this, "isTransform").onChange((value) => {
            this.leaves.forEach(({ mesh }, i) => {
                (mesh.material as ShaderMaterial).uniforms.uTransform.value = value ? 1 : 0;
            });
            // this.mainPathLine.mesh.visible = value;
        });

        gFlower.add(this, "loop").onChange(() => {
            if (this.timeline) this.timeline.kill();
        });

        gFlower.add(this, "duration", 1, 10, 0.5).onChange(() => {
            this.drawIn();
        });
        gFlower.add(this.params, "timeSpeed", -1, 1, 0.01);
        gFlower.add(this.params, "f_wave", -100, 100, 0.01);
        gFlower.add(this.params, "f_freq", -100, 100, 0.001);
        gFlower.add(this.params, "leadDepth", -1000, 1000, 0.001);
        gFlower.add(this.params, "cameraMove", -1000, 1000, 0.1);
        gFlower.add(this.params, "extendProgress", 0, 1, 0.1);

        if (this.mainPathLine) {
            this.mainPathLine.setGui(g);
            gFlower.add(this, "drawIn");
            gFlower.add(this, "drawOut");
            gFlower.add({ ball: this.ball.visible }, "ball").onChange((value) => {
                this.ball.visible = value;
            });
            gFlower
                .add(this, "progress", 0, 2, 0.001)
                .onChange((value) => {
                    this.updateLine(value);
                })
                .listen();

            gFlower.add({ id: 0 }, "id", 0, 11, 1).onChange((value) => {
                this.intersectedPosition.forEach((data, i) => {
                    // data.mesh.visible = i == value;
                    this.leaves[i].opacity = i == value ? 1 : 0;
                });
            });

            gFlower.add({ dist: 0 }, "dist", 0, 1000).onChange((value) => {
                this.leaves.forEach(({ mesh }, i) => {
                    mesh.position.z = (i - this.leaves.length * 0.5) * value;
                    // mesh.position.z = i * value;
                });
            });
        }
    }

    checkPosition(value = this.progress) {
        let progress = Math.min(1, value);
        let progressR = Math.max(0, value - 1);

        let totalLineLength = this.mainPathLinePositions.length - 1;
        let currentLineId = Math.floor(totalLineLength * progress);
        let currentLineIdReverse = totalLineLength - currentLineId;

        let currentLineIdOver = totalLineLength - Math.floor(totalLineLength * progressR);

        if (value > 1) {
            // let currentLineId = Math.floor((totalLineLength - 1) * progress);
            // let currentLineIdReverse = totalLineLength - currentLineId;
        }

        let currentLinePosition = this.mainPathLinePositions[currentLineId];
        // this.ball.position.copy(currentLinePosition);

        let stem = this.leaves[this.leaves.length - 1];

        if (currentLineIdReverse == 0) {
            stem.opacity = 1;
        } else {
            stem.opacity = 0;
        }

        this.intersectedPosition.forEach((data, i) => {
            let leafId = this.intersectedPosition.length - i - 1;
            if (data._from > currentLineIdReverse) {
                // data.mesh.visible = true;
                this.leaves[leafId].opacity = 1;
            } else {
                // data.mesh.visible = false;
                this.leaves[leafId].opacity = 0;
            }

            if (value > 1) {
                // if (data._from > currentLineIdOver) {
                //     this.leaves[leafId].opacity = 0;
                // } else {
                //     this.leaves[leafId].opacity = 1;
                // }
            }
        });
    }

    updateLine(progress = this.progress) {
        this.mainPathLine.update(progress);
        this.checkPosition(progress);
    }

    changeExtend(value = 0, _duration = 1.2) {
        if (this.gsap) this.gsap.kill();
        this.gsap = gsap.to(this.params, {
            extendProgress: value,
            duration: _duration,
            ease: "Power4.easeInOut",
        });
    }

    update(
        mouse = { _rx: 0, _ry: 0 },
        rotationPower = 1,
        flowerContainer: Object3D,
        flowerCardPosition: Vector3,
        flowerCardRotation: Euler
    ) {
        this.time += this.params.timeSpeed;

        this.leaves.forEach((leaf: leafType, i) => {
            let mat: ShaderMaterial = leaf.mesh.material as ShaderMaterial;
            mat.uniforms.uTime.value = -this.time;
            mat.uniforms.uStrength.value = this.params.f_wave;
            mat.uniforms.uFreq.value = this.params.f_freq;
            mat.uniforms.opacity.value += Math.min(
                1,
                (leaf.opacity - mat.uniforms.opacity.value) * 0.2
            );
            leaf.mesh.position.z = i * this.params.leadDepth;
        });

        let addMoveOffset = flowerCardPosition.z + 300;

        this.camera.position.x = flowerContainer.rotation.y * addMoveOffset * rotationPower;
        this.camera.position.y = -flowerContainer.rotation.x * addMoveOffset * rotationPower;
        this.group.position.z = -800 + this.params.extendProgress * 1200;
    }
}
